.activity_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 149px;
    height: 116px;
    margin-bottom: 1rem !important;
  }

  .desc {
    text-align: center;
    color: #757575;
    font-size: 16px !important;
    line-height: 24px;
  }

  .talk_to_container {
    margin-top: 1rem;
  }
}

.processing {
  padding: 80px 0;
}

.activity_by_kind {
  width: 60%;
}

.contacts_btn {
  border-color: #007b94;
  color: #007b94;
  font-size: 16px;
}
