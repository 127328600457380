@import 'tokens.scss';

.CampaignCreationWrapper {
  display: grid;
  grid-template-columns: 1fr 550px;
  gap: 1rem;
  padding-block: 1rem;
  height: 100%;
  border-radius: 18px;

  @media (min-width: 3200px) {
    grid-template-columns: 1fr 850px;
  }

  // (min-width: 1600px) and
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 400px;
  }
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 350px;
  }
  // (min-width: 1600px) and
  @media (max-width: 1300px) {
    grid-template-columns: 1fr 350px;
  }
  // (min-width: 700px) and
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
