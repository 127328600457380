.bottomAges {
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: transparent;
  border-top-color: #ffab03;
  border-radius: 0;
  bottom: -10px;
  left: 139px;
  z-index: 1;
}

.TableSelectionWrapper {
  &__selected-row {
    text-transform: capitalize;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
    color: #ffffff !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
  &__selected-row-delete {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
    color: #ffffff !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
}
