.FindYourProspectFilters {
  &__homeType {
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 3200px) {
      width: 100%;
    }
  }
  &__modalOption {
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: fit-content;
    text-align: center;
    font-weight: 600;

    @media screen and (min-width: 3200px) {
      height: 100px;
      gap: 15px;
      font-size: 24px !important;
    }
  }
  &__buttonActive {
    background-color: #007b94;
    color: white;
  }
  &__modalSaveCancel {
    border: 2px solid #ffb708;
    color: #007b94;
    float: right;
    margin: 9px 0;
    // padding: 20px 0;
  }
  &__modalSaveCancel:hover {
    background: inherit;
    color: #ffb708;
  }
  &__modalOption:hover {
    background-color: inherit;
  }
  &__modalOption:active,
  &__modalOption:focus {
    background-color: #ffab03;
    color: white;
  }
  &__priceFlterContainer {
    width: 300px;
  }
  &__priceFilter {
    display: flex;
    justify-content: space-between;
    width: 320px;
    text-align: center;

    @media screen and (min-width: 3200px) {
      width: 620px;
    }

    .Select {
      width: 100%;
      > div {
        @media screen and (min-width: 3200px) {
          width: 100% !important;
          height: 60px !important;
        }

        .Select__label {
          @media screen and (min-width: 3200px) {
            font-size: 28px !important;
          }
        }

        .Select__toggle-mark {
          @media screen and (min-width: 3200px) {
            font-size: 28px !important;
          }
        }
      }
    }
  }
  &__priceFilterBox {
    height: 44px;
    width: 400px;
    font-size: 21px;
    text-align: center;
  }
}
.Select__options {
  .Select__option {
    display: flex;
    align-items: center;
    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
}
.FindYourProspectFiltersActive {
  background-color: #ffab03;
  color: white;
}
// .Price_range {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   /* identical to box height */

//   display: flex;
//   align-items: center;

//   color: #aab2b5;
// }
