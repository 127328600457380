@import 'src/tokens';
@import '../../@eltoro-ui/components/input-variables.scss';

.setPasswordError {
  color: red;
}

.resetPassword {
  margin-bottom: 45px;
  &__forgot_password_btn {
    margin-top: 18px;
    font-weight: 700;
  }
  &__bottomNav {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__button_disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .loginFooter {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
  }
}
