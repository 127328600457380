@import 'src/tokens';

.ProgressIndicator {
  &__daysOfTotal {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;
    margin: 3px 0 20px 0 !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  &__daysOfTotalBold {
    font-weight: 700 !important;
  }
  width: 100%;
  @media (max-width: 1400px) {
    width: 86%;
  }
  @media (max-width: 1350px) {
    width: 100%;
  }
  &__meter {
    background: #eeeff0;
    border-radius: 100px;
    height: 1rem;
    width: 200px;

    @media screen and (min-width: 3200px) {
      height: 2rem;
    }

    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__progress {
    background: linear-gradient(87.64deg, #ffab03 0%, #fcd917 72.9%);
    height: 1rem;
    border-radius: 100px;

    @media screen and (min-width: 3200px) {
      height: 2rem;
    }
  }
}
