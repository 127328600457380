.cancel-subscription-modal,
.keep-current-subscription {
  position: relative;
  display: flex;
  max-width: 618px;
  padding: 32px 60px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .warn-icon-container {
      width: 59px;
      height: 59px;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(248, 113, 113, 0.1);
    }

    .icon-container {
      height: 59px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    &-title {
      color: #0e0d1d;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &-description {
      max-width: 455px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      span {
        color: #9a9a9a;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.toast_success {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    color: #5c5c5c;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }

  .content {
    color: #a7a7a7;

    /* Body/S/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
