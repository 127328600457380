.main_voting1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 1172.95px;
  width: calc(100vw - 50vw);
  padding: 4rem 3rem;
  @media screen and (max-width: 1750px) {
    padding: 4rem 2.5rem;
  }
  @media screen and (max-width: 1600px) {
    padding: 4rem 2rem;
  }
  @media screen and (max-width: 1350px) {
    padding: 3rem 1.5rem;
  }
  &__icon {
    width: 140px;
    @media screen and (max-width: 1750px) {
      width: 125px;
    }
    @media screen and (max-width: 1600px) {
      width: 105px;
    }
    @media screen and (max-width: 1350px) {
      width: 92px;
    }
  }
}
.leftvoting_icon1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40%;
}
.rightvoting_icon1 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;

  z-index: -1;
}
.comming_soon_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 54px;
  /* or 89% */

  text-align: center;
  color: #2b1f0a;
  margin-bottom: 2rem;
  font-family: sans-serif, 'Helvetica Neue', 'Lucida Grande', Arial;
  font-stretch: expanded;
  @media screen and (max-width: 1700px) {
    font-size: 48px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 1500px) {
    font-size: 40px;
    margin: 5px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 32px;
  }
}
.voting_sub_heading1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32.68px;
  text-align: center;
  // color: #2b1f0a;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 1700px) {
    font-size: 28px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 22px;
  }
}
.voting_discription {
  font-family: 'Open Sans';
  font-style: normal;
  width: 70%;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  margin: 0px auto;
  opacity: 0.8;
  color: #000000;
  @media screen and (max-width: 1700px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
}
.vote_feature {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #6d6d6d;
  margin-top: 2.5rem;
  opacity: 0.8;
  @media screen and (max-width: 1700px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 17px;
    margin-top: 1rem;
  }
  @media screen and (max-width: 1300px) {
    font-size: 17px;
  }
}
//------votiing poll bar--------
.main_vote_section {
  display: flex;
  align-items: center;
  width: 60%;
  margin-bottom: 1rem;
  justify-content: center;
  // >input{
  //   cursor: pointer;
  // }
  //     input[type=radio] {
  //       accent-color: var(--color-primary);
  //       border: none !important;

  //   }
}

.radioClass:hover {
  cursor: pointer;
}
// .vote {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 58px;
//   width: 80%;
//   border: 1px solid #dadada;
//   border-radius: 10px;
//   background: url('../../assets/Images/maskcolor.png') no-repeat;
//   mask-image: linear-gradient (black, transparent);
//   background-color: #f9f9f9;
//   margin: 0.3rem 0 0 0px;
//   @media screen and (max-width: 1700px) {
//     height: 55px;
//     width: 340px;
//   }
//   @media screen and (max-width: 1500px) {
//     height: 50px;
//     width: 300px;
//   }
//   @media screen and (max-width: 1300px) {
//     height: 45px;
//     width: 270px;
//   }
// }
.yes_vote {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-left: 1rem;
  margin: 13px 0;
  /* Light 400 */

  color: #6d6d6d;
}
.count_vote {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-right: 1rem;
  margin: 13px 0;
  color: #ffab03;
}
.Voting_submit {
  border-radius: 10px;
  padding: 26px 40px;
  margin: 0px auto;
  width: 200px;
  padding: 1.5rem 0px;
  margin-top: 5%;
  font-size: 15px;
}
.radioClass {
  appearance: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 5px 4% 0px 0px;
  box-shadow: 0 0 0 4px #f3f3f3;
  background-color: #dbdbdb;
}
input[type='radio']:checked {
  background-color: #ffab03;
}
