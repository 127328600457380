.realtor {
  width: 330px;
  border-radius: 14px;
  padding: 1rem 0.5rem;
  margin: 10px 0;
  background: white;

  @media screen and (min-width: 3200px) {
    width: 730px;
  }

  .not-found-realtor {
    @media screen and (min-width: 3200px) {
      margin-top: 15px;
    }
    .Text {
      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
    }
  }

  &__header_section {
    padding-left: 7px;
  }

  &__title {
    display: block;
    font-size: 14px !important;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }

  &__sub_title {
    font-size: 12px !important;
    color: #aab2b5 !important;

    @media screen and (min-width: 3200px) {
      font-size: 22px !important;
    }
  }

  .TableWrapper {
    overflow: hidden !important;
    padding: 0 !important;
  }

  .TableHeader__cell {
    padding: 5px;
  }

  .TableHeader__label-container {
    font-size: 11px;
    letter-spacing: 0.5px;
    padding: 5px;

    @media screen and (min-width: 3200px) {
      font-size: 21px !important;
    }

    img {
      margin-left: 5px;
      width: 10px;
    }
  }

  .TableRow {
    border: 0 !important;
  }

  .TableCell {
    font-size: 11px !important;
    letter-spacing: 0.5px;
    padding: 5px;

    @media screen and (min-width: 3200px) {
      font-size: 22px !important;
    }

    &:nth-child(2) {
      b {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 135px;
      }
    }
  }

  .svg-inline--fa {
    margin-left: 5px !important;
  }

  .table_cell_content {
    font-size: 11px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffab03;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 3200px) {
      width: 32px;
      height: 32px;
      font-size: 22px;
    }
  }
}
