@import 'src/tokens';
@import 'src/mixins';

.Campaigns {
  &__PageHeader {
    height: 84px;
    background-color: var(--color-white);

    @media screen and (min-width: 3200px) {
      height: 120px;
    }
  }

  .empty_status_container {
    padding-block: 90px;
  }

  &__select-action {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;

    @include focus-ring;
  }
  &__select-action_disabled{
    cursor: not-allowed;
  }
  &__delete_campaign_list {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #ffffff !important;
  }

  &__search {
    border: 2px solid gray;
    border-radius: 5px;
    background: white;
    padding: 5px;
  }

  .campaign-status {
    color: #ffab03;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .campaign-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}
