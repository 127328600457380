@import 'tokens';

.SelectBundle {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__star {
    color: $color-tertiary-400;
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  &__bottomCancel {
    border-bottom: 1px solid gray;
    color: gray;
  }
  &__bottomCancel:hover {
    background-color: inherit;
    border: inherit;
    color: inherit;
  }
  &__top {
    display: flex;
  }
  &__topItem {
    border: 1px solid #6d6d6d;
    margin: 1rem;
    padding: 5px;
    width: 170px;
    text-align: center;
  }
  &__bundle-list {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 560px) {
      grid-template-columns: 1fr;
    }
  }
}
