@import 'src/tokens';

.MoreInfoContainer {
  &__body {
    border: $border-width-default solid $color-grey-500;
    padding: 1rem;
    border-radius: $border-radius-s;
    background: $color-grey-100;
  }
}
