@import 'src/tokens.scss';
@import 'src/mixins.scss';

.PhotoButton {
  height: 8rem;
  position: relative;
  &__button {
    @include focus-ring;
    overflow: hidden;
    border-radius: $border-radius-m;
    outline: none;
    border: none;
    padding: 0;
    background: white;
    cursor: pointer;
    height: 100%;
    &:hover {
      box-shadow: $box-shadow-300-focus;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__photographer-info {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color-white;
    padding: 0.25rem 0.5rem;
    border-top-left-radius: $border-radius-m;
    border-bottom-right-radius: $border-radius-m;
    display: none;
    font-size: $text-size-s;
    color: $text-on-white;
  }
  &__unsplash-link {
    color: $text-on-white;
    &:hover {
      color: $text-on-white--primary;
    }
  }
  &:hover {
    .PhotoButton__photographer-info {
      display: block;
    }
  }
}
