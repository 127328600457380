.setPasswordError {
  position: relative;
  display: flex;
  margin-top: 5px;
  align-items: center;
}
.setPasswordErrorMsg {
  font-weight: 400;
  font: 'Helvetica Neue';
}
