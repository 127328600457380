.choose-from-creatives-library {
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;

  padding-inline: 32px;
  padding-block: 30px;

  width: 90%;
  max-width: 1340px;

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    gap: 32px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &-title {
      color: #7e7e7e;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      white-space: nowrap;
    }

    &-selected_count {
      color: #f6b433;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .creatives-content {
      padding: 12px;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden auto;
      align-items: center;
      gap: 24px;

      .creatives-card-container {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        gap: 23px 24px;
      }

      .Loading {
        margin: 0;
        min-height: 32px;

        .Loading__dot {
          width: 10px;
          height: 10px;
          margin: 0 6px;
        }
      }

      .load-more-creatives {
        cursor: pointer;
        padding: 0;
        outline: none;
        border: none;
        color: #ffab03;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: transparent;

        &:hover {
          color: #ffd154;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}
