@import 'src/tokens';

.Transactions__container {
  margin-top: 35px;

  .heading {
    display: inline-block;
    margin-bottom: 20px;

    @media (max-width: 699px) {
      margin-top: 20px;
    }
  }

  .transaction_name {
    @media screen and (min-width: 3200px) {
      font-size: 1.75rem !important;
    }
  }

  .transaction_date {
    @media screen and (min-width: 3200px) {
      font-size: 1.5rem !important;
    }
  }

  .transaction_cost {
    @media screen and (min-width: 3200px) {
      font-size: 1.5rem !important;
    }
  }

  .TableSelectionWrapper__table-wrapper {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);

    .heading.Text {
      width: 100%;
      font-size: 16px !important;
      font-style: normal;
      line-height: 1.75;
      margin: 0;
      padding-block-end: 12px;
      margin-block-end: 12px;
      border-bottom: 1px solid #f0f0f0;

      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
    }

    .TableWrapper {
      padding: 0;

      table.transactions-table {
        .Table__body {
          border-radius: initial;

          .TableRow {
            outline: none;
          }
        }
      }
    }
  }

  .TableHeader {
    display: none;
  }

  .Table__body {
    box-sizing: border-box;
    background: #ffffff;
  }

  .TableRow {
    border: none;
  }
}

.Transactions {
  &__table-wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
}
