@import 'tokens';
@import 'mixins';
.Notes {
  margin: $pixels-3;
  &__textarea {
    width: calc(100% - 0.5rem);
    border: none;
    padding: 1rem;
    resize: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    border-radius: 10px;
    line-height: 25px;
    color: #6d6d6d;
    background: #ffffff;
    border: 1px solid #f0f0f0 !important;
    :focus-visible {
      border: 1px solid #ffab03 !important;
    }
    // border-radius: 8px;
    // @include focus-ring;
  }
  &__edit-btn {
    font-weight: bold;
    font-size: medium;
    min-width: 5.5rem;
    width: 187px;
    height: 53px;
  }
  &__edit-btn-icon {
    min-width: 3rem;
    width: 252px;
    margin-top: 10px;
    height: 54px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px !important;
    color: #2b1f0a !important;
  }
  &__heading-color {
    color: #2b1f0a;
  }
}
