@import 'tokens.scss';

.ViewMarketStats {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
  border-radius: 27px;
  min-height: 90px;

  &__columns_per_line {
    display: flex;
    align-items: center;
    height: 100%;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
  }

  &__number_counter {
    flex: 2;
  }

  &__button {
    width: 186px !important;
    height: 44px !important;
    top: 655px;
    font-size: 15px;
    font-family: 'Open Sans';

    color: white;
    background: #44c2e6 !important;
    border-radius: 13px !important;
    border: none !important;
  }
  &__text-content {
    &__title.Text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 20px !important;
      line-height: 1.1;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #989898 !important;
      @media screen and (min-width: 3200px) {
        font-size: 25px !important;
      }
      @media screen and (max-width: 1750px) {
        font-size: 18px !important;
      }
      @media screen and (max-width: 1550px) {
        font-size: 16px !important;
      }
      @media screen and (max-width: 1350px) {
        font-size: 14px !important;
      }
    }

    &__sub_title.Text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 1.1;
      color: #d1d1d1 !important;

      @media screen and (min-width: 3200px) {
        font-size: 23px !important;
      }
      @media screen and (max-width: 1750px) {
        font-size: 16px !important;
      }
      @media screen and (max-width: 1550px) {
        font-size: 14px !important;
      }
      @media screen and (max-width: 1350px) {
        font-size: 12px !important;
      }
    }
  }
  &__percentage {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  &__text.Text {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    font-size: 30px !important;
    line-height: 1.1 !important;
    color: #d1d1d1 !important;
    @media screen and (min-width: 3200px) {
      font-size: 35px !important;
      margin-left: 15px;
    }
    @media screen and (max-width: 1750px) {
      font-size: 25px !important;
    }
    @media screen and (max-width: 1550px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1350px) {
      font-size: 18px !important;
    }
  }
  &__img_wrapper {
    display: flex;
    min-width: 45px;
    justify-content: center;

    img {
      width: 60px;
      @media screen and (min-width: 3200px) {
        width: 70px;
      }
      @media screen and (max-width: 1750px) {
        width: 50px;
      }
      @media screen and (max-width: 1550px) {
        width: 40px;
      }
      @media screen and (max-width: 1350px) {
        width: 36px;
      }
    }
  }
}
