.ImpressionValidator {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  height: 63px;
  border-radius: 5px;
  max-width: 1062px;
  margin: 1rem auto;
}
