.Popover {
  position: fixed;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  filter: drop-shadow(0 0.25rem 1rem rgba(0, 0, 0, 0.125))
    drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.125));
  &__body {
    position: relative;
  }
}
