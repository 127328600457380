.pagination_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: right;
  padding: 30px 0 0 0;
  margin: 1rem;

  @media screen and (min-width: 3200px) {
    padding: 60px 0 0 0;
    margin: 2rem;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 11px;
    margin: 0;

    @media screen and (min-width: 3200px) {
      font-size: 22px;
    }
  }

  span {
    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }

  .button {
    margin: 0 5px;
    background: #fff;
    border: 1px solid #c6cbcd;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 0.1rem 0.6rem;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
      margin: 0 1rem;
      padding: 0.25rem 1rem;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }
}
