.social-media-list-wrapper {
  .social_media-post-count {
    background-color: #fff;
    padding: 1rem;
    border-radius: 20px 20px 0 0;
    margin-top: 24px;
    border-bottom: 1px solid #eaecf0;
  }
  .table-loading {
    .table-spin-container {
      .table_wrapper {
        margin-block: unset;
        table.mainTable.social-media-posts {
          .mainTableTh {
            color: #111827;
            &:first-child {
              border-start-start-radius: 0;
            }
            &:last-child {
              border-start-end-radius: 0;
            }
          }
          .mainTableTd {
            .channel {
              img {
                width: 20px;
                height: 20px;
              }
            }
            .post-actions {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 30px;

              .go-to-post {
                display: none;
                color: #757575;
              }
            }
            .truncate {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }
          .post-actions-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .p-button.p-component.post-action-btn {
              background: transparent;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 7px 18px;
              width: fit-content;
              box-shadow: unset;
              position: relative;
            }
          }
          .mainTableTr {
            &:hover {
              .post-actions {
                .go-to-post {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}
#popup_menu_right.post-actions-menu.p-menu {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);
  .p-menuitem {
    &:not(.p-highlight):not(.p-disabled) {
      > .p-menuitem-content:hover {
        background: #fff9ed;
      }

      &.p-focus {
        > .p-menuitem-content {
          background: #ffffff;

          &:hover {
            background: #fff9ed;
          }
        }
      }
    }
    .p-menuitem-content {
      border-radius: 5px;

      .p-menuitem-link {
        border-radius: 5px;
        padding-inline: 8px;
        padding-block: 7px;
        gap: 10px;

        .p-menuitem-text {
          color: #222124;
          font-variant-numeric: lining-nums tabular-nums;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: 'Open Sans', serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.07px;
        }
      }
    }
  }
}
