@font-face {
  font-family: Helvetica;
  src: url('../../assets/fonts/HelveticaNeue.ttf');
}
@mixin pointer(
  $direction: 'top',
  $background: white,
  $size: 10px,
  $border-color: black,
  $border-size: 1px,
  $simple: false
) {
  &:before,
  &:after {
    @if $direction == 'top' {
      bottom: 100%;
      left: 50%;
    } @else if $direction == 'right' {
      top: 50%;
      left: 100%;
    } @else if $direction == 'bottom' {
      top: 100%;
      left: 50%;
    } @else if $direction == 'left' {
      right: 100%;
      top: 50%;
    }

    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    @content;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-width: $size;

    @if $direction == 'top' {
      border-bottom-color: $background;
      margin-left: -$size;
    } @else if $direction == 'right' {
      border-left-color: $background;
      margin-top: -$size;
    } @else if $direction == 'bottom' {
      border-top-color: $background;
      margin-left: -$size;
    } @else if $direction == 'left' {
      border-right-color: $background;
      margin-top: -$size;
    }
  }
  @if $simple != true {
    &:before {
      border-color: rgba(0, 0, 0, 0);
      border-width: $size + $border-size;
      @if $direction == 'top' {
        border-bottom-color: $border-color;
        margin-left: ($size + $border-size) * -1;
      } @else if $direction == 'right' {
        border-left-color: $border-color;
        margin-top: ($size + $border-size) * -1;
      } @else if $direction == 'bottom' {
        border-top-color: #bd0000;
        margin-left: ($size + $border-size) * -1;
      } @else if $direction == 'left' {
        border-right-color: $border-color;
        margin-top: ($size + $border-size) * -1;
      }
    }
  }
}

// --------------------------------
// Pointer Box
// --------------------------------

.box {
  z-index: 1;
  position: absolute;
  background: white;
  border: 1px solid #bd0000;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Error 400 */

  color: #bd0000;
  border-radius: 6px;

  $list: top right bottom left;
  @media screen and (max-width: 500px) {
    font-size: 12px;
    padding: 5px !important;
    right: -60px !important;
    top: -78px !important;
  }
  @each $direction in $list {
    &.#{$direction} {
      @include pointer($direction: $direction, $border-size: 2px);
    }
  }
}

// Page Styling
body {
  // background: lightpink;
  font-size: 17px;
  font: 'Helvetica Neue';
}

header {
  width: 400px;
  margin: 50px auto;
  text-align: center;
}

h1 {
  color: red;
  width: 100%;
}
