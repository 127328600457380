@import 'tokens.scss';
@import 'mixins.scss';

.ListingLabel {
  background-color: transparent;
  border: none;
  padding: $pixels-2;
  margin: 0;
  display: block;
  text-align: start;
  outline: none;
  @include focus-ring;
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__icon-circle {
    $size: 1.25rem;
    flex: 0 0 $size;
    max-width: $size;
    min-width: $size;
    height: $size;
    width: $size;
    color: $text-on-grey-100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $text-size-xs;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #dddddd;

    @media screen and (min-width: 3200px) {
      height: 2rem;
      width: 2rem;
    }
  }
  &__buzz-marker {
    font-size: $text-size-xl;
    margin-right: 0.25rem;

    @media screen and (min-width: 3200px) {
      font-size: $text-size-xxxl;
    }
  }

  &__status {
    width: fit-content;
    font-size: $text-size-xl;
    //margin-right: 0.25rem;
  }

  &__labels {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &__info {
      display: grid;
      grid-template-columns: 200px repeat(auto-fill, 100px);
    }

    @media screen and (min-width: 3200px) {
      width: 550px;
    }
  }
  &__label {
    display: inline-block;
    color: $text-on-white;
    font-weight: $text-weight-bold;
    font-size: $text-size-xs;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;

    @media screen and (min-width: 3200px) {
      font-size: $text-size-l;
    }
  }
  &__sub-label {
    display: inline-block;
    color: $text-on-white--subdued;
    font-size: $text-size-xs;
    font-weight: $text-weight-normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:focus-visible {
    border-color: transparent;
  }
  &:hover {
    .ListingLabel {
      &__icon-circle {
        border-color: #ffab03;
      }
    }
  }
  &--selected {
    .ListingLabel {
      &__icon-circle {
        border-color: #ffab03;
        color: #ffab03;
        font-weight: bold;
      }
    }
    &:hover {
      .ListingLabel {
        &__icon-circle {
          border-color: #f5c537;
          color: #f5c537;
        }
      }
    }
  }

  &[disabled] {
    * {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
