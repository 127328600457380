@import 'tokens.scss';

.CampaignCreationFooter {
  background-color: $color-white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;

  @media screen and (min-width: 3200px) {
    padding: 1.5rem 2rem;
  }
}
