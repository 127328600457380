.notificationSetting {
  .notification-header-title {
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &__container {
    border-radius: 20px;
    background: white;
    padding: 30px;
    margin-top: 0.8rem;
  }
  &__containerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #f0f0f0;

    &:first-child {
      padding-block-start: 0;
    }
  }

  &__containerItemIconContainer {
    padding-inline-end: 120px;
  }

  &__containerItemContent {
    color: #2b1f0a;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    @media (max-width: 699px) {
      font-size: 12px;
    }
  }
  &__containerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  &__containerItemIcon {
    font-size: 32px;
    cursor: pointer;
  }
  &__button {
    border: none;
    background: none;
    cursor: pointer;

    color: #ffab03;
    text-align: right;

    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  @media screen and (max-width: 1050px) {
    h3 {
      margin-top: 1rem;
    }
  }
  @media (max-width: 699px) {
    h3 {
      margin-top: 0;
    }
  }
}
