.side_nav_bar_container_for_desktop {
  .shadow_container.sidebar-collapsed {
    z-index: 1 !important;
  }

  @media screen and (max-width: 601px) {
    display: none;
  }

  .HeaderItem__icon {
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 20px;
    margin-right: 1rem;
    color: #bab9b9;

    @media screen and (min-width: 3200px) {
      padding: 0.75rem;
      font-size: 54px;
      margin-right: 3rem;
    }

    &:has(> .HeaderItem:not(.disabled)):hover {
      box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
      color: #f4ad3d;
    }
  }

  .active_sidebar_icon {
    box-shadow: 0px 3px 6px rgba(0, 0, 41, 0.25);
    color: #f4ad3d;
  }

  .active_sidebar_icon_color {
    color: #f4ad3d;
  }

  .box_shadow_none {
    &:hover {
      box-shadow: none !important;
    }
  }

  .active_sidebar_img {
    padding-bottom: 0.5rem;
  }

  .icon_container {
    // padding: 1rem;
    padding: 0.8rem;
    padding-left: 0.2rem;

    @media screen and (max-width: 699px) {
      border-bottom: 1px solid #eee;
    }
  }

  // .cross_icon {
  //   transform: rotate(45deg);
  //   color: #000;
  //   font-size: 22px;
  //   cursor: pointer;
  // }

  .side_nav_bar_nested_container {
    position: fixed;
    background: #fff;
    // right: 0;
    left: 0;
    top: 0;
    // min-width: 33%;
    max-width: 270px;
    z-index: 2;
    bottom: 0;
    overflow: auto;
    width: 65px;
    transition: all 0.5s;
    border-right: 5px solid #f4ad3d;
    overflow-x: hidden;

    @media screen and (min-width: 3200px) {
      max-width: 600px;
      width: 158px;
    }

    .notification_list_item {
      position: relative;

      .dot {
        right: 50px;
        top: 20px;
      }
    }

    &__item_className {
      display: flex !important;
      align-items: center !important;
    }

    .drop_down_list {
      padding: 20px 16px;
      border: 1px solid #eee;
      width: 100%;
      font-size: 16px;
    }

    .Collapsible {
      &.collapsed {
        margin: 0 9px;
        border-radius: 5px;

        @media screen and (min-width: 3200px) {
          margin: 0 25px;
        }

        .HeaderItem {
          padding-inline-start: calc(0.8rem - 9px);

          @media screen and (min-width: 3200px) {
            padding-inline-start: calc(2.5rem - 25px);
          }
        }

        .sub_item {
          .HeaderItem {
            padding-inline: 0;
          }
        }

        &.active-dropdown {
          box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
        }
      }
    }

    .Collapsible_title {
      padding: 20px 16px;
      display: flex;
    }

    .unorder_list_withOutPadding {
      list-style: none;
      margin: 0;
      padding-left: 1.5rem;
    }

    .sub_item {
      .HeaderItem__icon {
        width: 42px;
        font-size: 18px;
        text-align: center;
        margin-right: 0.5rem;

        &:has(div.Avatar) {
          display: flex;
          justify-content: center;

          .Avatar {
            @media screen and (min-width: 3200px) {
              width: 50px;
              height: 50px;
            }

            img {
              @media screen and (min-width: 3200px) {
                width: 50px;
              }
            }
          }
        }

        @media screen and (min-width: 3200px) {
          width: 105px;
          font-size: 50px;
        }
      }
    }

    .unorder_list {
      list-style: none;
      margin: 0;
      padding: 0;

      // button {
      //   margin: 20px 0;
      // }

      .HeaderItem {
        width: 100%;
        min-width: 270px;
        padding-left: 0.8rem;
        display: flex;
        align-items: center;
        padding-block: 5px;

        @media screen and (min-width: 3200px) {
          min-width: 600px;
          padding-left: 2.5rem;
          padding-block: 1rem;
        }

        &:not(.disabled):hover {
          background-color: transparent !important;

          .bar_text {
            border-bottom: 2px solid #ffab03;
          }

          .HeaderItem__icon {
            color: #f4ad3d;
          }
        }
      }

      .list {
        // padding: 20px 0;
        // border: 1px solid #eee;
        // width: 100%;
        position: relative;

        &:hover {
          // background-color: var(--color-primary-lighter);
          // cursor: pointer;
          background-color: initial !important;
          cursor: default !important;
        }

        abbr {
          text-decoration: none;
        }

        .icon_group {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.5rem 0.4rem 0;

          &:hover {
            box-shadow: none;
          }
        }
      }

      .hide_on_mobile {
        @media screen and (max-width: 699px) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 699px) {
      // min-width: 60%;
      max-width: 60%;
    }
  }

  .set_width {
    width: 270px;

    @media screen and (min-width: 3200px) {
      width: 600px;
    }
  }
}

.hide {
  display: none;
}

.height_zIndex {
  z-index: 998 !important;
  display: block;
}

.logout_main {
  position: relative;
  width: 762px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;

  &__heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 30px !important;
    line-height: 41px !important;
    color: #000000 !important;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 63%;
  }

  &__yes_button_modal {
    padding: 25px 20px;
    font-family: 'Open Sans';
    width: 50% !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */

    text-align: center;

    color: #000000;
  }

  &__no_button_modal {
    padding: 25px 20px;
    font-family: 'Open Sans';
    font-style: normal;
    width: 50% !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    /* or 179% */
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 10px;
    text-align: center;

    color: #000000;
  }
}

.logoutrightcomb {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.logoutleftcomb {
  position: absolute;
  top: 0px;
  left: 0px;
}
