@import 'tokens.scss';

.ListingsList {
  &.virtual-just-listed-just-sold,
  &.grow-your-sphere {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .Checkbox {
      .Checkbox__input-wrapper {
        @media screen and (min-width: 3200px) {
          min-width: 2rem;
          height: 2rem;
        }
      }
      .Checkbox__label {
        @media screen and (min-width: 3200px) {
          font-size: 26px;
        }
      }
    }

    .Select {
      .Select__select {
        @media screen and (min-width: 3200px) {
          width: 250px !important;
          height: 50px !important;
          font-size: 22px !important;
        }
      }
      .fontSize_sort_by {
        margin: 0;
        @media screen and (min-width: 3200px) {
          font-size: 24px !important;
        }
      }
      .Select__label {
        margin: 0 auto;
        @media screen and (min-width: 3200px) {
          font-size: 25px !important;
        }
      }
    }

    .ListingsList__count {
      display: flex;
      align-items: center;
      gap: 8px;

      .count {
        color: #2b1f0a;
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 600;
      }

      span:not(.count) {
        display: flex;
      }
    }

    .ListingsList__pagination {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      > div {
        display: flex;
        align-items: center;
        gap: 10px;

        ._text {
          @media screen and (min-width: 3200px) {
            font-size: 20px !important;
          }
        }

        ._button {
          width: fit-content;
          height: fit-content;
          border: none;
          display: flex;
          justify-content: center;

          .Button__icon-wrapper {
            width: fit-content;
            height: fit-content;
          }
        }
      }
    }

    .view_all_listings.Button {
      .Button__text {
        padding: 0;
        color: #ffab03;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &:not(:disabled):hover {
        border-color: transparent;
        background: transparent;

        .Button__text {
          color: #ffd154;
        }
      }
    }
  }
  &__controls {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
  &__Select {
    border-radius: 12px;
  }
  &__search {
    margin-left: auto;
    flex-basis: 33%;
    background: transparent;
    margin-bottom: 1rem;
  }
  &__listings {
    display: grid;
    gap: $pixels-4;
  }

  &__listingsCenter {
    display: grid;
    gap: $pixels-4;
    overflow: auto;
    max-height: calc(100vh - 503px);
    @media screen and (min-height: 820px) {
      max-height: calc(100vh - 540px);
    }
  }

  &__listingsDetail {
    display: flex;
    flex-direction: column;

    .pagination_arrow {
      @media screen and (min-width: 3200px) {
        font-size: 22px;
      }
    }
    .pagination_list {
      @media screen and (min-width: 3200px) {
        font-size: 26px;
      }
    }
    .pagination_count {
      font-size: 11px !important;
      margin: 0;
      text-align: center;
      @media screen and (min-width: 3200px) {
        font-size: 21px !important;
      }
    }
  }
  &__listingsDetailBtn {
    margin: 0 auto;
    border: none;
    color: #007b94;
    border-bottom: 1px solid #007b94;
  }
  &__listingsDetailBtn:hover {
    background-color: transparent;
    color: #007b94;
  }
}

.VirtualJustListedJustSold,
.GrowYourSphere {
  .ListingsList__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .title {
        color: #0e0d1d;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.33;
      }

      .description {
        color: #9a9a9a;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
  &__listings_content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    gap: 20px;
  }

  .ListingsList__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected_listings_count {
      color: #ffab03;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__listings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden auto;
    height: 100%;
  }

  &__listingsCenter {
    max-height: calc(100vh - 530px);
    @media (min-height: 820px) {
      max-height: calc(100vh - 538px);
    }
  }
}

.GrowYourSphere {
  &__listings {
    .ListingLabel {
      padding-inline-start: 0;
      padding-inline-end: 8px;

      &__labels {
        width: 100%;

        &__info {
          width: inherit;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
        }
      }

      &__label,
      &__sub-label {
        margin: 0;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

.all-listings-modal.Modal__content {
  padding: 1.875rem 2rem;
  .Modal__scrollable-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .all-listings-modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow: hidden;

    .count {
      color: #7e7e7e;
      font-family: 'Open Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.3;
    }

    .all-listings-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
      overflow: hidden;

      .not-matching-found {
        color: #9a9a9a;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .all-listings-selection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Checkbox__wrapper-label {
          padding: $pixels-2;

          .Checkbox__label.fontOverwrite {
            color: #9e9e9e;
            font-family: 'Open Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .selected_listings_count {
          color: #ffab03;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .all-listings {
      max-height: 460px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .all-listings-modal-footer {
    margin-block-start: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.listing-search {
  padding: 0.875rem 0.75rem;
  border-radius: 10px;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  gap: 17px;
  width: 388px;

  transition: all 0.3s;

  .clear-btn {
    display: flex;
    height: fit-content;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }

  &-input {
    border: none;
    outline: none;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    transition: all 0.3s;

    &::placeholder {
      font-size: 16px;
      color: #b9b9b9;
    }
  }

  &:hover {
    border-color: #ffab03;
  }

  &:has(.listing-search-input:focus) {
    border-color: #ffab03;
    box-shadow: 0 0 0 2px rgba(255, 235, 5, 0.1);
  }
}

.fontSize_sort_by {
  font-weight: normal;
  font-size: 14px;
}
