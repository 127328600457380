.modal-container {
  max-width: 610px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 60px;
  position: relative;

  .cross {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
  }

  .emoji {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(250, 173, 20, 0.1);
    border-radius: 14px;
    padding: 15px 20px;
    margin-bottom: 35px;
  }

  h4 {
    margin: 0 0 5px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    text-align: center;
    font-size: 14px;
    color: #9a9a9a;
    line-height: 20px;
    margin: 0 0 20px 0;

    &:last-of-type {
      margin: 0;
    }
    a{
      color: #9a9a9a;
      text-decoration: underline;
    }
  }

  .footer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancel-button {
      margin-right: 30px;
      border-color: #dcdcdc;
      font-size: 16px;
    }

    .reactivate_button {
      font-size: 16px;
    }
  }
}
