@import 'src/tokens';

.layout_container {
  height: 100%;
  border-radius: 18px;

  .layout {
    max-width: 3260px;
    margin: 0 auto;
  }
}
