.creative-card {
  width: 230px;
  height: 215px;
  position: relative;

  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  outline: none;
  border-radius: 10px;
  border: 1px solid #aab2b5;

  &:not(.selected):not(.used):hover {
    cursor: pointer;
    border-color: #f6b433;
    box-shadow: 0 0 0 3px rgba(255, 235, 5, 0.1);
  }

  &.selected:not(.used) {
    cursor: pointer;
    border: 2px solid #f6b433;

    &:hover {
      box-shadow: 0 0 0 3px rgba(246, 180, 51, 0.1);
    }

    .selected-icon {
      color: #f6b433;
    }
  }

  &.used {
    cursor: not-allowed;
    opacity: 0.7;
    border: 2px solid #a9a9a9;

    .selected-icon {
      color: #a9a9a9;
    }

    .creative-card-info {
      &-name {
        color: #aaaaaa;
      }

      &-type_specs {
        color: #c0c0c0;
      }
    }
  }

  .selected-icon {
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -12.5px;
    right: -12.5px;
    z-index: 2;
  }

  &-thumb-container {
    width: 100%;
    height: 153px;
    padding-inline: 10px;
    padding-block: 10px;
    background-color: #eeeeee;

    border-radius: 10px 10px 0 0;

    position: relative;

    .creative-thumb {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    .preview-btn-video {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.09);

      width: 42px;
      height: 42px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-info {
    width: 100%;
    padding-inline: 8px;
    padding-block: 7px;

    display: flex;
    flex-direction: column;
    gap: 5px;

    &-name,
    &-type_specs {
      color: #000;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
    }

    &-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      white-space: nowrap;
    }

    &-type_specs {
      font-size: 14px;
      font-weight: 700;
      line-height: normal;

      text-transform: capitalize;
    }
  }
}
