.SelectCard {
  min-width: 30vw;
  padding: 2rem 2rem 1rem 2rem;
  &__options {
    display: flex;
    > .Select {
      flex-grow: 1;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row-reverse;
  }
}
