.contactDetail {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contactDetail__headIcon {
      gap: 12px;
    }

    .back_icon {
      font-size: 19px;
      width: fit-content;
      height: fit-content;
      background: none;
      border: none;
      cursor: pointer;

      &:hover,
      &:active {
        background: none;
        border: none;
      }

      .Button__icon-wrapper {
        width: fit-content;
        height: fit-content;
      }

      @media screen and (min-width: 3200px) {
        font-size: 35px;
      }
    }

    h3 {
      color: black;
      letter-spacing: 0.5px;
    }
  }

  &__info {
    margin-block-start: 30px;
    &__container {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &_title,
    &_value {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }

    &_title {
      color: #888;
      font-weight: 400;
    }

    &_value {
      color: #040404;
      font-weight: 700;
    }
  }

  &__headIcon {
    display: flex;
    align-items: center;

    .Button {
      background: transparent;
      &:hover {
        box-shadow: none;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 1rem;
    background-color: white;
    padding: 12px 42px 42px;
    gap: 42px;

    &__row {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__title {
        color: #6d6d6d;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.35;
        margin: 0;

        @media screen and (min-width: 3200px) {
          font-size: 24px;
        }
      }

      &__inputs {
        display: flex;
        flex-wrap: wrap;
        row-gap: 50px;
        column-gap: 15px;
      }
    }
  }

  &__contentBox {
    display: flex;
    align-items: center;
    gap: 15px;
    height: auto;

    &__icon {
      width: 35px;

      @media screen and (min-width: 3200px) {
        width: 55px;
      }
    }
  }

  &__button {
    padding: 0.5rem;
    background-color: rgba(252, 217, 23, 1);
    color: black;
    border: none;
  }
}
.edit_information_title {
  color: #2b1f0a;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (min-width: 3200px) {
    font-size: 28px;
  }
}
