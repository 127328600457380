.ProgressBar {
  width: 100%;
  &__steps {
    display: flex;
    align-items: center;
    @media (max-width: 699px) {
      justify-content: center;
    }
  }
  &__line {
    height: 0.3rem;
    background-color: #c6cbcd;
    width: 1rem;
    margin: 0 -0.25rem;
    z-index: 1;
  }
  &__step {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background-color: #c6cbcd;
    z-index: 2;
  }
}

.ProgressBar__step--is-filled {
  background-color: #fcd917;
}

.ProgressBar__line--is-filled {
  background-color: #fcd917;
}
