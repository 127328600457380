.bread_crumb_container {
  display: flex;
  place-content: center space-between;
  align-items: center;
  height: 64px;
  background-color: #ffffff;
  padding: 0 15px 0 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
  border-radius: 20px;
  width: 100%;

  .Breadcrumbs {
    text-transform: capitalize;
  }

  @media screen and (min-width: 3200px) {
    height: 140px;
    padding: 0 30px 0 50px;
  }

  @media screen and (max-width: 601px) {
    display: none;
  }

  .bread_crumb_route_text {
    color: #7e7e7e !important;
    font-size: 13px !important;
    font-weight: 700;
  }

  .bread_crumb_username {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 700;
    margin: 0 18px;

    @media screen and (min-width: 3200px) {
      font-size: 30px !important;
    }
  }

  .sidebar_icon {
    font-size: 24px;
    @media screen and (min-width: 3200px) {
      font-size: 60px;
    }
  }

  .right_corner {
    min-width: 150px;
    display: flex;
    align-items: center;
  }

  .Breadcrumbs {
    display: flex;
    align-items: center;
  }

  .Breadcrumbs__separator {
    margin: 0 0.5rem;
    color: #7e7e7e;
    font-size: 12px;
    font-weight: bold;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }

  .Breadcrumbs__section {
    color: #7e7e7e;
    font-size: 12px;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.notification_button {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
&:disabled{
  cursor: not-allowed;
}
  .notification-count {
    position: absolute;
    min-width: 20px;
    min-height: 20px;
    padding: 0 2px;
    border-radius: 50%;
    background: #ffab03;

    color: #1f1f1f;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    top: -10px;
    left: 15px;
  }
}

svg.trial-pie {
  width: 34px;
  height: 34px;
}

.free-trial-text {
  text-transform: initial;
  color: #3f3f42;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
