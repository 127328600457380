.creative-filter-item-tag {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 10px;
  background: #fff9ed;

  &-content {
    color: #757575;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.07px;
    text-transform: capitalize;
  }

  &-delete-btn {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
