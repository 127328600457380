.card_content {
  width: 100%;
  background: white;
  border-radius: 10px;
  margin-bottom: 1.5rem;

  .heading {
    padding: 12px 60px 12px 24px;

    .title {
      font-size: 16px !important;
      margin: 0 0 1rem 0 !important;
    }

    .desc {
      color: #757575;
      font-size: 16px !important;
    }
  }

  .library_card_content {
    width: 100%;
    padding: 24px 60px 24px 60px;
    border-top: 1px solid #e7e7e7;
  }

  .coming_soon {
    border-radius: 50px;
    font-size: 14px;
    white-space: nowrap;
    padding: 4px 10px 4px 10px;
    color: #f6b433;
    border: 1px solid #f6b433;
    background: #fff9ed;
    margin-right: 24px;
  }
}
