.notification_container {
  height: 100%;

  .notification_wrapper {
    padding: 0;
    border-radius: 20px;
  }

  .PageHeader__actions {
    @media (max-width: 699px) {
      display: none;
    }
  }

  .TableSelectionWrapper,
  .TableSelectionWrapper__table-wrapper {
    height: inherit;
  }

  .nested_container {
    background: white;
    position: relative;
    min-height: 80%;
    height: 100%;

    ul {
      list-style: none;
      padding: 0;
    }

    .header_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #e4e4e4;
      padding: 0 20px;

      .notification-tab {
        display: flex;
        align-items: center;

        padding: 16px 0;
        cursor: pointer;
        position: relative;

        &.notification-tab-active {
          &::after {
            content: '';
            position: absolute;
            background: #000000;
            width: 100%;
            height: 3px;
            bottom: -1px;
          }
        }
      }
      .header_icons {
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
      .header_titles {
        text-transform: capitalize;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          margin-left: 15px;
        }

        @media (max-width: 699px) {
          flex-wrap: nowrap;
          overflow-x: scroll;

          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
        }
      }

      .count {
        min-width: 24px;
        min-height: 24px;
        padding: 3.5px;
        border-radius: 25%;
        margin-left: 0.5rem;
        background: #c6cbcd;

        color: #fff;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .mark_all_as_read {
        cursor: pointer;
        color: #007b94;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-decoration-line: underline;

        &:not(.have-notifications) {
          cursor: not-allowed;
          color: #d1d1d1;
        }
      }
    }

    .body_container {
      overflow: auto;

      .notification {
        display: flex;
        padding: 12px 16px 24px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        border-bottom: 1px solid #e4e4e4;
        background: #ffffff;

        &.notification-unread {
          background: #fffbf2;
        }
      }

      .notification_content {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
          color: #2b1f0a;

          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        &-description {
          color: #3f3f42;

          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .notification_created {
        color: #aeaeae;

        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        word-break: keep-all;
      }

      .pagination_container {
        margin: 0;
        padding-top: 20px;
      }
    }

    .black_bg_color {
      background-color: black !important;
    }

    .unread {
      background: rgba(255, 171, 3, 0.1) !important;
    }

    .empty_status_container__headings_container {
      .Text {
        font-family: sans-serif, 'Helvetica Neue', 'Lucida Grande', Arial;
        font-size: 32px !important;
      }
    }

    // .empty_status_container__nested_container {
    //   img {
    //     width: 20%;
    //   }
    // }
  }

  .pagiate_container {
    height: 95px;
    margin-right: 1.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
