@import 'src/tokens';

$sizes: (
  's': 1.5rem,
  'm': 3rem,
  'l': 5rem,
  'xl': 8rem,
  'xxl': 10rem,
);

.Avatar {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  &--rounded {
    border-radius: 50%;
    img {
      border-radius: 50%;
      height: inherit;
    }
  }

  @each $size, $rem in $sizes {
    &--#{$size}-size {
      width: $rem;
      height: $rem;
      font-size: $rem / 2;

      @if $rem == 1.5rem {
        font-weight: bold;
      }
      img {
        width: $rem;
      }

      @media screen and (min-width: 3200px) {
        width: $rem * 1.75;
        height: $rem * 1.75;
        font-size: $rem * 1.75 / 2;

        @if $rem * 1.75 == 1.5rem * 1.75 {
          font-weight: bold;
        }
        img {
          width: $rem * 1.75;
        }
      }
    }
  }
}
