@import 'src/tokens';
@import '../input-variables';

@font-face {
  font-family: Helvetica;
  src: url('../../../assets/fonts/HelveticaNeue.ttf');
}
.Checkbox {
  display: flex;

  &:hover {
    .Checkbox__input-wrapper:not(.Checkbox__input-wrapper__disabled) {
      border-color: #ffab03;
    }
  }

  &__wrapper-label {
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 100%;
    cursor: pointer;
    padding: 0.25rem;
    padding-left: 0.375rem;
    padding-right: 0.75rem;
    border-radius: $border-radius-s;
    &:hover {
      // background-color: $color-primary-050;
    }
  }
  &__input-wrapper {
    min-width: 1.3rem;
    height: 1.3rem;
    border: 1px solid #dddddd;
    margin-right: 0.5rem;
    border-radius: $border-radius-s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #ffffff;

    @media screen and (min-width: 3200px) {
      min-width: 3.3rem;
      height: 3.3rem;
    }
  }

  &__input-wrapper__disabled {
    background-color: #eeeff0;
    cursor: not-allowed;
  }
  &__input {
    width: 0;
    height: 0;
    display: none;
    position: absolute;
  }
  &__checkmark {
    height: 30%;
    width: 60%;
    border: $pixels-2 solid#ffab03;
    border-top: none;
    border-right: none;
    // background: #ffab03;
    transform: translateY(-20%) rotate(-45deg);
    display: none;
  }
  &__label {
    max-width: 100%;
    font-weight: 700;
    color: #6d6d6d;
    font-family: Helvetica;
  }
}

.Checkbox--s-size {
  font-size: $text-size-xs;
  font-weight: $input-font-weight-s;
  letter-spacing: 0.015rem;
  height: $input-height-s;
}

.Checkbox--s-size .Checkbox__input-wrapper {
  min-width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;

  @media screen and (min-width: 3200px) {
    min-width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.5rem;
  }
}

.Checkbox--s-size .Checkbox__wrapper-label {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}

.Checkbox--m-size {
  font-size: $text-size-m;
  font-weight: $input-font-weight-m;
  letter-spacing: 0.015rem;
}

.Checkbox--l-size {
  font-size: $text-size-l;
  font-weight: $input-font-weight-l;
  letter-spacing: 0.015rem;
}

.Checkbox--l-size .Checkbox__input-wrapper {
  min-width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.Checkbox--l-size .Checkbox__wrapper-label {
  padding-left: 0.5rem;
  padding-right: 1rem;
}

.Checkbox--xl-size {
  font-size: $text-size-xl;
  font-weight: $input-font-weight-xl;
  letter-spacing: 0.015rem;
}

.Checkbox--xl-size .Checkbox__input-wrapper {
  min-width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;

  @media screen and (min-width: 3200px) {
    min-width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.5rem;
  }
}

.Checkbox--xl-size .Checkbox__wrapper-label {
  padding-left: 0.625rem;
  padding-right: 1.25rem;
}

.Checkbox:hover .Checkbox {
  &__wrapper-label {
    // background-color: $color-primary-050;
    // box-shadow: $box-shadow-200;
  }
}

.Checkbox:focus-within .Checkbox,
.Checkbox:active .Checkbox {
  &__wrapper-label {
    // box-shadow: $box-shadow-300-focus;
  }
}

.Checkbox--checked .Checkbox {
  &__input-wrapper {
    // background-color: $text-on-white--primary;
    // background: #44c2e6;
    background: #ffffff;
    border: 1px solid #ffab03;
  }
  &__checkmark {
    display: block;
  }
}

.fontOverwrite {
  font-weight: normal;
  font-size: 16px;
}
