@import 'tokens.scss';

.AudienceResultsCount {
  font-size: $text-size-xxl;
  color: $text-on-white;
  font-weight: $text-weight-bold;
  padding: 0.5rem;
  &__icon {
    margin-right: 1rem;
  }
}
