@import 'tokens.scss';
.lens_heading {
  color: #a0a0a0 !important;
  font-family: 'Open Sans' !important;
}
.app_dashboard_container {
  height: 100%;
  border-radius: 18px;

  @media screen and (max-width: 699px) {
    .PageHeader {
      gap: 0;
      grid-gap: 0;
    }
  }

  .DashboardPage {
    padding-block: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__lenses {
      display: flex;
      justify-content: space-evenly;
      background-color: white;
      padding: 0.5rem;
      box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
      border-radius: 27px;

      @media screen and (min-width: 3200px) {
        padding: 2rem 0.5rem;
      }
    }

    &__topLenses {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
    }
    &__topLensesText {
      font-weight: 600;
      text-align: center;
      margin-top: 1rem;
      font-size: 24px !important;
      @media screen and (min-width: 3200px) {
        font-size: 35px !important;
      }
      @media screen and (max-width: 1750px) {
        font-size: 22px !important;
      }
      @media screen and (max-width: 1550px) {
        font-size: 20px !important;
      }
      @media screen and (max-width: 1350px) {
        font-size: 18px !important;
      }
    }

    &__topLensesLink {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      align-items: center;
      > img {
        height: 130px;
        @media screen and (min-width: 3200px) {
          height: 226px;
        }
        @media screen and (max-width: 1750px) {
          height: 120px;
        }
        @media screen and (max-width: 1550px) {
          height: 110px;
        }
        @media screen and (max-width: 1350px) {
          height: 95px;
        }
      }
    }
    &__top_three_cards_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;

      @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
      }
    }
    &__top_four_cards_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1rem;
      padding-top: 1rem;

      @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
      }
    }

    &__bottom_two_cards_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      padding-top: 1rem;

      @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr;
      }
    }

    &__activity_list {
      display: flex;
      flex-direction: column;
    }
    &__activity_components {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: space-between;
    }
  }
}

.dashboard-header .Text {
  font-size: 25px !important;
}

.LatestListings {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 1rem;
  &__map {
    background: $color-grey-100;
    border-radius: $border-radius-s;
    box-shadow: $box-shadow-100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.SphereOfInfluence {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $color-grey-050;
  flex-grow: 1;
}

.dahboard-charts .Section {
  background-color: #ffffff;
  height: 269px;
}

.dahboard-charts .finished_campaigns_container {
  width: 179px;
}
.dahboard-charts .save_audiences_container {
  width: 179px;
}
.Market_acceleration_status {
  height: 100%;
  text-decoration: none;
}
