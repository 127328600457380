.adv_options_heading {
  width: 100%;
  padding: 1.5rem;
  background: #f6f6f6;

  .adv_options_toggler {
    color: #007b94;
    font-weight: bold;
    font-size: 1rem;
    background: transparent;
    border: none;

    &:hover {
      color: #007b94;
    }
  }
}

.options_wrapper {
  background: #f6f6f6;

  .custom_date_input {
    width: 100%;
    height: 44px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    outline: none;
    padding: 0 0.75rem;
    font-size: 14px;
  }

  .custom_date_input.error {
    border: 1px solid #ff2d38;
  }

  .p-checkbox.p-highlight .p-checkbox-box {
    background-color: #007b94 !important;
    border-color: #007b94 !important;
  }

  .disabled_slider {
    .p-slider .p-slider-handle {
      border-color: #e7e7e7 !important;
      background-color: #e7e7e7 !important;
    }
  }

  .disabled_layer {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .aud_item_wrapper {
    &:first-child {
      border-radius: 0 0 12px 12px;
    }
  }
}

.info-note{
  border: 1px solid #007B94;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  color:#262626;
}