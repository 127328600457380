.cancel-subscription-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  //fff2f0
  .Button.Button--text.danger {
    color: rgba(255, 0, 0, 1);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 94.444% */
    border: none;
    * {
      padding: 0;
    }
    margin-block: 18px;

    &:hover {
      background: transparent;
      border: none;
      color: rgba(255, 0, 0, 0.8);
    }
  }

}
