.VirtualJustListedJustSold {
  position: relative;
  display: flex;
  flex-grow: 1;

  &_sidebar.ProspectLensGridBar {
    height: auto;
    overflow: hidden;

    .root_content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    .SaveAudienceWizard__btn {
      margin: 0;
      width: 100%;
    }

    .MaxHeightContainer.MaxHeightContainer--full-height {
      overflow: hidden;
      .MaxHeightContainer__content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
  }

  &__sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    padding-block: 22px;
    border-block-start: 0.5px solid rgba(88, 88, 88, 0.5);

    .Loading {
      min-height: initial;
    }
  }

  &__listing-search.VirtualJustListedJustSold__sidebar-content {
    padding-block: 0;
    border-block-start: none;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      color: #2b1f0a;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  &__listings {
    .empty-filtered-status {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #2b1f0a;
    }
  }

  &__listings-button-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
  &__listings-button {
    width: 100%;
    background: #047b94;
    letter-spacing: 0.7px;
    color: white !important;
    border: 2px solid rgb(0, 123, 148);
    font-weight: 600;
    font-family: 'helvetican';
  }
  &__listings-button:hover {
    background-color: rgb(0, 123, 148);
  }

  .MaxHeightContainer__footer {
    button {
      margin-top: 0.25rem;
    }
  }

  @media screen and (max-width: 1050px) {
    .MyListingsLens__Height {
      min-height: 200px;
    }

    .VirtualJustListedJustSold__listings {
      max-height: calc(100vh - 70vh) !important;

      @media (max-height: 700px) {
        max-height: calc(100vh - 85vh) !important;
      }
    }

    .MaxHeightContainer__footer {
      display: flex;
      justify-content: space-around;
      margin-bottom: 0.4rem;

      button {
        font-size: 14px;
        max-width: 100%;
        margin: 0;
      }
    }
  }
}
.individualRadius {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 6px;
  &__name {
    color: var(--text-on-white);
    font-weight: var(--text-weight-bold);
    font-size: var(--text-size-xs);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    width: 160px;
  }
}
.radiusFilter {
  display: flex;
  margin-bottom: 1rem;
  &__item {
    cursor: pointer;
    margin: 6px;
    font-size: 13px;
    border: none;
    background: none;
  }
  &__active {
    border-bottom: 2px solid skyblue;
    color: skyblue;
    font-weight: 700;
  }
}

.buzzScoreImg_container {
  position: absolute;
  right: 40px;
  z-index: 401;
  bottom: 0px;
}
.setMeter_container {
  // position: absolute;
  right: 75px;
  z-index: 401;
  bottom: 136px;
  background-color: #fff;
  box-shadow: var(--box-shadow-300-dark);
  border-radius: var(--border-radius-m);
  padding: 0.4rem;
}
@media screen and (max-height: 800px) {
  .setMeter_container {
    max-height: 320px;
    overflow-y: auto;
  }
}
@media screen and (max-height: 600px) {
  .setMeter_container {
    max-height: 250px;
    overflow-y: auto;
  }
}
.pd-3 {
  padding-inline: 12px;
  padding-block: 10px;
}
