@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.UploadAreaLayout {
  padding: 2rem 5rem;
  display: grid;
  justify-items: center;
  gap: 1rem;

  @media screen and (min-width: 3200px) {
    padding: 3rem 5rem;
    gap: 1rem;
  }

  @media (max-width: 699px) {
    padding: 1rem;
    gap: 0.5rem;
    text-align: center;
  }

  .upload-icon {
    height: 36px;
    @media screen and (min-width: 3200px) {
      height: 60px;
    }
  }

  .upload-restrictions {
    color: #676767;
    text-align: center;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }

  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    text-align: center;
    color: #f6b433 !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
    @media (max-width: 699px) {
      font-size: 12px !important;
    }
  }
}

.btn-access-creative {
  background-color: transparent;
  width: 100%;
  border: 1px solid #318ea3;
  padding-block: 15px;
  &:hover {
    border: 1px solid #318ea3;

    // cursor: pointer;
  }
}

.btn-span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffab03;
}
