.MaxHeightContainer {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    position: relative;
    z-index: 1;
  }
  &__content {
    position: relative;
    z-index: 0;
    border-radius: 20px;
  }

  &--full-height {
    height: 100%;
  }
}
