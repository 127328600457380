@import 'src/tokens';

.PersonalInfoSectionContainer {
  border-radius: 20px;
  background: #fff;
  padding: 20px 20px 30px 30px;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    padding-bottom: 8px;
  }

  &__heading {
    color: #2b1f0a;

    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }

  &__error_icon {
    color: $color-danger;
    margin-top: 1px;
    margin-left: 9px;
  }

  .Section--dark {
    position: relative;
    padding: 40px 15px;
    border-radius: 20px;

    @media (max-width: 699px) {
      padding: 15px 10px;
    }
  }

  .PersonalInfoSection {
    &__form_container {
      display: flex;
      flex-direction: column;
      width: 90%;

      .form-section {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-title {
          color: #111827;

          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .inputs-wrapper {
        display: flex;
        gap: 2rem;

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .input-wrapper {
          min-height: 90px;
        }

        .label-input-field-container {
          min-width: 336px;
        }
      }
    }
    width: 80%;

    @media (max-width: 1500px) {
      width: 75%;
    }
    @media (max-width: 699px) {
      width: 100%;
      border-right: 0;
      padding-right: 0;
    }

    &__avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 20%;
      padding-block-start: 40px;
      padding-inline-end: 40px;

      @media (max-width: 699px) {
        width: 100%;
      }
    }

    &__fields_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      gap: 70px;
      align-items: flex-end;

      @media (max-width: 699px) {
        flex-direction: column;
        margin-bottom: 0;
      }

      .fields_container_nested {
        width: 42%;
        // margin-right: 5px;

        @media (max-width: 699px) {
          width: 100%;
          margin: 0.5rem 0;

          .FormItem__label {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__divider {
    border: 0.5px solid #c6cbcd;
  }

  &__btn_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__reset_password_container {
    margin-block-start: 1.5rem;
  }

  &__reset_password {
    border: none;
    background: none;
    a {
      font-weight: 700;
      text-decoration: none;
      color: rgb(255, 171, 3);
      cursor: pointer;
      padding-bottom: 3px;
      font-size: 17px;

      @media screen and (min-width: 3200px) {
        font-size: 27px;
      }

      @media (max-width: 699px) {
        font-size: 12px;
      }
    }
    &:hover {
      background-color: unset;
      box-shadow: none;
    }
  }
}

.canva-container {
  width: 284px;
  padding: 32px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #e7e7e7;

  .canva-connect-button.Button--text {
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: all 0.3s;
    padding: 0;
    border: none;
    background: transparent;

    &:not(.connected) {
      color: #007b94;
      opacity: 1;

      &.Button:hover {
        border: none;
        background: transparent;
        transition: all 0.3s;
        color: #007b94;
        opacity: 0.8;

        .before-spinner.button_loading {
          color: #007b94;
          opacity: 0.8;
        }
      }

      .before-spinner.button_loading {
        color: #007b94;
      }
    }

    div:has(> .before-spinner.button_loading) {
      margin-inline-end: 0.5rem;
    }
    .before-spinner.button_loading {
      margin: 0;
    }

    .Button__text {
      padding: 0;
    }
  }
}

.imageModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 1172.95px;
  width: calc(100vw - 65vw);
  padding: 4rem 3rem;
  @media screen and (max-width: 1750px) {
    padding: 4rem 2.5rem;
  }
  @media screen and (max-width: 1600px) {
    padding: 2.5rem 1rem;
  }
  @media screen and (max-width: 1350px) {
    padding: 3rem 12rem;
  }
  &__icon {
    width: 140px;
    @media screen and (max-width: 1750px) {
      width: 125px;
    }
    @media screen and (max-width: 1600px) {
      width: 105px;
    }
    @media screen and (max-width: 1350px) {
      width: 92px;
    }
  }
}
.leftvoting_icon1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40%;
}
.rightvoting_icon1 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40%;

  z-index: -1;
}

.imageModal .profile-pic {
  width: 380px;
  z-index: 1;
  @media screen and (max-width: 1750px) {
    width: 480px;
  }
  @media screen and (max-width: 1600px) {
    width: 390px;
  }
  @media screen and (max-width: 1350px) {
    width: 350px;
  }
}
