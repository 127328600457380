@import 'src/tokens';

.CreativeBody {
  background: $color-grey-200;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &__header {
    z-index: 1;
    background: $color-white;
    padding: 0.25rem 0;
    border-bottom: $border-width-thin solid $color-grey-500;
    position: relative;
  }
  &__body {
    display: flex;
    height: 100%;
    position: relative;
  }
  &__stage {
    height: 100%;
    width: 100%;
  }
  &__center {
    width: 100%;
  }
  &__loading {
    width: 50vw;
    min-width: 250px;
  }
}

.CreativeGen {
  overflow: hidden;
  border-radius: $border-radius-m;
}
