.campaign-error-message {
  display: flex;
  width: 100%;
  padding: 12px 22px;
  margin-block-start: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
  border-radius: 10px;
  border: 1px solid #ef4444;
  background: #fff;

  &-info {
    display: flex;
    align-items: center;
    gap: 12px;

    &-details {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &-title,
      &-content {
        color: #ababab;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &-title {
        color: #766c5b;
        font-weight: 700;
      }
    }
  }
}
