@import 'src/tokens';

.TableRow {
  outline-offset: -3px;
  outline: 1px solid #f0f0f0;

  &__disabled {
    .TableCell {
      .Text {
        color: #d1d1d1 !important;
      }
    }
  }

  &__expand {
    width: 1.5rem;
  }
  &__expansion {
    background-color: $color-white;
    box-shadow: inset 0 0.6875rem 0.5rem -0.625rem $color-grey-200,
      inset 0 -0.6875rem 0.5rem -0.625rem $color-grey-200;
  }
  &__caret {
    margin-left: 0.5rem;
    font-size: 1.3rem;
    color: $color-primary;
    cursor: pointer;
    border: none;
    padding: 0;
    background: none;
  }
  &__spacer {
    // height: 0.5rem;
  }
  &--alternate {
    // background-color: $color-grey-050;
  }
  &--selected {
    // box-shadow: inset 2px 2px $color-tertiary-500,
    //   inset -2px -2px $color-tertiary-500;
    // border: 2px solid var(--color-secondary-300);
    // border-right: 3px solid var(--color-secondary-300);

    outline: 1px solid #ffab03;
    // border-radius: 10px !important;
  }
  // &__checkboxSelected {
  //   background-color: #44c2e6;
  //   border: 2px solid #44c2e6;
  // }
  &__checkbox {
    // width: 3.5rem;
    width: 5%;
    text-align: left;
    padding: 0.1rem 1rem;
    .Checkbox {
      &__wrapper-label {
        padding: 0.25rem;
      }
      &__input-wrapper {
        margin: 0;
        // background-color: #fff;
        // border: 1px solid #44c2e6;
      }
    }
  }
  &:not(&__disabled):hover {
    background-color: #fff7c9;
  }
}
