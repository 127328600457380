@import 'tokens.scss';
.campaign_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #dc9300;
  margin-bottom: -2px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.summary_card_done {
  height: 54px;
}

.checkout_and_submit {
  font-size: 16px;

  @media screen and (min-width: 3200px) {
    font-size: initial;
  }
}

.Summary_heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 1.25;
  color: #2b1f0a !important;

  @media screen and (min-width: 3200px) {
    font-size: 34px !important;
  }
}
.SummaryCart {
  height: min-content;
  padding: 1rem 0;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.12);
  border-radius: 20px;

  &__total_budget {
    padding: 0.75rem 1.5rem;
    background: #fff9ed;

    .Text {
      @media screen and (min-width: 3200px) {
        font-size: 30px !important;
      }
    }
  }
  &__header-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    font-size: 14px;
    font-weight: bold;
    background: #f6f6f6;

    @media screen and (min-width: 3200px) {
      padding: 1rem 2rem;
    }
  }
  &__body-item {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 3200px) {
      padding: 1rem 2rem;
    }

    .creative_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__overall_font {
      font-size: 16px !important;
      line-height: 1.25 !important;

      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
    }
  }
  .creative_tile {
    padding: 1rem 1.5rem;
  }

  &__lefticon {
    position: absolute;
    width: 250px;
    left: 0;
    top: 0;
  }
  &__rigticon {
    position: absolute;
    width: 250px;
    right: 0;
    bottom: 0;
  }
  &__body-item-subheading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #6d6d6d;
    @media screen and (min-width: 3200px) {
      font-size: 32px;
    }
  }
  &__prospects_heading {
    margin-left: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 1rem;
    line-height: 1.3;

    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }
  &__prospects_subheading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3;
    margin-left: 5px;

    color: #2b1f0a;
    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }
  &__content_heading {
    display: flex;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px !important;
    line-height: 1.25 !important;

    color: #2b1f0a;
    @media screen and (min-width: 3200px) {
      font-size: 32px !important;
    }
  }
  &__save_audience {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 1.15 !important;
    margin-left: 9px;
    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }

  &__noCredits {
    padding-top: 5px;
    > i > span {
      color: #1ba1c5;
      border-bottom: 1px solid #1ba1c5;
    }
    > i > span:hover {
      cursor: pointer;
    }
  }
  &__itemImage {
    width: 20px;
    margin-right: 10px;
    @media screen and (min-width: 3200px) {
      height: 40px;
      margin-right: 20px;
    }
  }
  &__Back_to_campaigns {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 1.4 !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 40px !important;
    }
  }
  &__submitModal {
    height: 567px;
    width: 942px;

    @media (max-width: 669px) {
      max-width: 310px;
      max-height: 220px;
      display: flex;
      justify-content: space-evenly;
      padding: 1rem;
    }
  }
  &__submitModalContent {
    padding-top: 3rem;

    @media (max-width: 669px) {
      padding-top: 0;
    }
  }
  &__title_container {
    padding: 0px 12rem;
    text-align: center;
    @media (max-width: 669px) {
      padding: 0;
    }
  }
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 53px !important;
    text-align: center;
    color: #2b1f0a !important;

    @media (max-width: 669px) {
      font-size: 22px !important;
      line-height: 30px;
    }
  }
}
.SummaryCart {
  &__AddCardModal {
    margin-top: 3rem;
    width: 1083px;
    height: 646px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 3200px) {
      width: 1200px;
      height: 900px;
    }
  }
  &__addCardHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 40px !important;
    line-height: 1.25 !important;
    text-align: center !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 56px !important;
    }
  }
  &__card_list {
    max-height: 320px;
    overflow-y: auto;
    width: 100%;
    padding-right: 5px;
  }
  &__allCardlist {
    flex-direction: column;
    width: 80%;
    padding: 0 5rem;
    display: flex;
  }
  &__allCardlist_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem 2rem;

    @media screen and (min-width: 3200px) {
      padding: 1.75rem 3.5rem;
    }
  }
  &__detailsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding: 0 1rem;
  }
  &__detailsCard_id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #ffab03;
    border-radius: 10px;
    padding: 0 1rem;

    @media screen and (min-width: 3200px) {
      padding: 0 3.5rem;
    }
  }
  &__billing_card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__billing_card_brand-icon {
    height: 25px;
    @media screen and (min-width: 3200px) {
      height: 50px;
    }
  }
  &__campaginCard {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    flex-direction: column !important;
    width: 100%;
    margin: 0;
  }
  &__billing_card_left {
    display: flex;
    flex-direction: column;
  }
  &__heading_text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    display: flex;
    font-size: 16px !important;
    color: #515a5c !important ;

    @media screen and (min-width: 3200px) {
      font-size: 32px !important;
    }
  }
  &__card_detail_text {
    padding-left: 1rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    display: flex;
    align-items: center;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 22px !important;
    }
  }
  &__heading_text_detail {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    color: #515a5c !important ;
    padding: 1.5rem 2rem;

    @media screen and (min-width: 3200px) {
      font-size: 20px !important;
    }
  }
  &__addCard {
    width: 99%;
    background: #fff9ed;
    border: 1px dashed #ffab03;
    border-radius: 9px;
    padding: 1rem 2rem;
    display: flex;
    margin-top: 10px;
    margin-bottom: 1rem;
    cursor: pointer !important;
    justify-content: center;
  }
  &__headingButton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #fff9ed;
    border: none;
    color: #ffab03;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }
  &__leftcardicon {
    position: absolute;
    top: -20px;
    left: 0px;
    width: 23%;
    z-index: -1;
  }
  &__righcardicon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 25%;
    z-index: -1;
  }

  .cost_edit {
    margin-top: 0.5rem;
    width: auto;
    border: none;
    color: #007b94;

    &:hover {
      color: #007b94;
    }

    span {
      padding: 0 !important;
    }
  }
}
