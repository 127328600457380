.ContactInformation {
  &__title {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      display: flex;
    }

    > label {
      width: fit-content;
      color: #888;
      margin: 0;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media screen and (min-width: 3200px) {
        font-size: 22px;
      }
    }

    .contact-input-error.p-error {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25;

      @media screen and (min-width: 3200px) {
        font-size: 24px;
      }
    }

    .contact-input {
      color: #040404;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      line-height: normal;

      @media screen and (min-width: 3200px) {
        font-size: 26px;
      }

      &.p-inputtext,
      &.p-inputnumber .p-inputtext {
        transition: all 0.3s;
        &[readonly] {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: keep-all;
          border: none;
          box-shadow: none;
          border-radius: initial;
          padding: 0;
          font-weight: 700;

          &.p-disabled,
          &.p-component:disabled {
            opacity: 1;
          }
        }

        &:not([readonly]):enabled {
          &:not(.p-invalid) {
            &:hover,
            &:active,
            &:focus {
              border-color: #ffab03;
            }
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba(255, 235, 5, 0.1);
          }
        }
      }
    }
  }

  &__icon {
    color: #ffab03;
  }
}
