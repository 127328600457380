@import 'src/tokens';

.ErrorBoundary {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 699px) {
    margin: 0 0.5rem;
  }

  &__graphic {
    margin-bottom: 2rem;

    img {
      @media (max-width: 699px) {
        width: 50%;
      }
    }
  }
}
