@import 'src/tokens';

.Breadcrumbs {
  display: flex;
  &__section {
    cursor: pointer;
    text-decoration: none;
    color: $color-primary-600;

    &--is-active {
      cursor: default;
      color: $color-primary-600;
      font-weight: $text-weight-bold;
    }
  }
  &__separator {
    margin: 0 1rem;
  }
}
