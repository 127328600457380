.label-rich-text-box-container {
  width: 100%;
}
.did-floating-label-content {
  position: relative;
}
.did-floating-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 1.1;
  color: #6d6d6d;
  position: absolute;
  pointer-events: none;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  white-space: nowrap;

  @media screen and (min-width: 3200px) {
    left: 60px;
    font-size: 30px !important;
  }
}
.did-floating-input1,
.did-floating-select1 {
  padding: 28px 40px 28px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6d6d6d;
  display: block;
  width: 100%;
  height: 300px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  box-sizing: border-box;
  &:focus {
    border: 1px solid #ffab03;
    outline: none;
    ~ .did-floating-label {
      top: 0;
      font-size: 13px !important;
      margin-right: 18px;
      padding-right: 5px !important;
      width: auto !important;
      left: 1rem;
      padding-left: 3px;

      @media screen and (min-width: 3200px) {
        font-size: 23px !important;
        padding-left: 10px;
        padding-right: 10px !important;
      }
    }
  }

  @media (max-width: 1400px) {
    // height: 35px;
  }
}

.did-floating-input1:hover {
  border: 1px solid #ffab03;
}

.did-floating-input1-error {
  padding: 0 0 0 36px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  width: 100%;
  border: 1px solid #bd0000 !important;
  border-radius: 3px;
  color: #bd0000;
  height: 45px;
  box-sizing: border-box;
}
select.did-floating-select1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.did-floating-select1::-ms-expand {
  display: none;
}

.did-floating-input1:not(:placeholder-shown) ~ .did-floating-label {
  top: 0;
  font-size: 13px !important;
  width: auto !important;
  padding-right: 5px !important;
  left: 1rem;
  padding-left: 3px;

  @media screen and (min-width: 3200px) {
    font-size: 23px !important;
    padding-left: 10px;
    padding-right: 10px !important;
  }
}
.did-floating-select1:not([value='']):valid ~ .did-floating-label {
  top: 0;
  font-size: 13px;
}
.did-floating-select1[value='']:focus ~ .did-floating-label {
  top: 0;
  font-size: 13px;
}
.did-floating-select1:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input {
  .input_icon_start,
  .input_icon_end {
    color: #9d3b3b;
  }
  .did-floating-input1,
  .did-floating-select1 {
    border: 1px solid #9d3b3b;
    color: #9d3b3b;
  }
  .did-floating-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    /* identical to box height, or 121% */

    color: #bd0000;
  }
  .did-floating-select1:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
  }
}

.input-group {
  display: flex;
  .did-floating-input1 {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
}
.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.input_icon_start {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  z-index: 999;
}
.input_icon_end {
  position: absolute;
  top: 0.7rem;
  right: 12px;
  z-index: 999;
}
.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}
.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group input:-webkit-autofill ~ label /* <<<< Add these */ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

input {
  -webkit-background-clip: content !important;
}
