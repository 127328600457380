@import 'src/tokens';

.CollapseSection {
  &__trigger {
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    font-weight: bold;
    background: $color-grey-100;
    cursor: pointer;
  }
  &__body {
    padding: 0.5rem;
  }
}
