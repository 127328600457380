@import 'tokens.scss';

.AudienceResultsCard {
  text-align: center;
  &__title {
    text-transform: uppercase;
    letter-spacing: $pixels-2;
    color: $color-grey-400;
    margin-bottom: 1rem;
  }
}
