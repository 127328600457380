.login_heading {
  font-family: 'Open Sans';
  margin: 0 0 10px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.75;
  text-align: center;
  color: #2b1f0a;

  @media screen and (min-width: 3200px) {
    font-size: 36px;
  }
}
.Login_all_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 2rem 6rem;
  background: #ffffff;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 2;

  @media screen and (min-width: 3200px) {
    padding: 5rem 22rem;
  }
}
.before-login-bg {
  position: absolute;
  top: 77px;
  right: 2px;
  // height: 70vh;
  height: 420px;

  // @media screen and (min-width: 1250px) {
  //   height: 500px;
  //   width: 500px;
  // }

  @media screen and (max-width: 1050px) and (min-width: 900px) {
    top: 0;
    height: 380px;
    width: 350px;
  }
  @media screen and (max-width: 900px) {
    top: 0;
    height: 320px;
    width: 290px;
  }
  // @media screen and (max-width: 600px) {
  //   top: 0;
  //   height: 200px;
  //   width: 200px;
  // }
}
.after-login-bg {
  position: absolute;
  bottom: 56px;
  left: 2px;
  // height: 70vh;
  height: 420px;
  z-index: 1;

  // @media screen and (min-width: 1250px) {
  //   height: 500px;
  //   width: 500px;
  // }
  @media screen and (max-width: 1050px) and (min-width: 900px) {
    bottom: 0;
    height: 380px;
    width: 320px;
  }
  @media screen and (max-width: 900px) and (min-width: 600px) {
    bottom: 0;
    height: 320px;
    width: 270px;
  }
  @media screen and (max-height: 800px) {
    bottom: -50px;
  }
}
.Login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 760px;
  min-width: 500px;
  padding: 2rem 0;


  @media screen and (min-width: 3200px) {
    width: 1300px;
  }

  @media screen and (max-height: 800px) {
    padding: 5rem 0;
  }

  @media screen and (max-width: 500px) {
    max-width: 85vw;
  }

  .login_submit {
    padding: 26px 0 !important;
  }

  &__formInputIcon {
    font-size: 23px;
    margin: 14px 5px 0 12px;
    top: 15px;

    @media screen and (max-width: 500px) {
      margin-top: 9px;
    }
  }
  &__formInput {
    height: 50px;
    width: 350px !important;
    @media screen and (max-width: 500px) {
      // max-width: 85vw !important;
      width: 100% !important;
      height: 40px !important;
    }
  }

  &__logo {
    margin-bottom: 1rem;
  }
  &__form {
    width: 100%;
    z-index: 1;
  }
  &__InputBox {
    height: 50px !important;
    width: 350px !important;
  }
  &__options {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  &__signupAcc {
    border: 0;
    background: inherit;
    color: #ffab03;
    font-size: 16px;
    margin-top: 1rem;
    font-weight: 600;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }
  &__signupAcc:hover {
    opacity: 0.8;
    cursor: pointer;
  }

}
.Login__form {
  max-width: 350px;
}
.forgotPassword {
  border: 0;
  background: inherit;
  color: #ffab03;
  font-size: 16px;

  @media screen and (min-width: 3200px) {
    font-size: 26px;
  }
}
.forgotPassword:hover {
  opacity: 0.8;
  cursor: pointer;
}
.loginBottom {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #6d6d6d;

  @media screen and (min-width: 3200px) {
    font-size: 26px;
  }
}
.loginBottom > p {
  margin: 5px;
  font-weight: 600;
  color: lightslategrey;
}
.loginBottomHelp {
  color: #44c2e6;
  font-family: Helvetica;
}
.suspendInfo{
  background-color: #FFF9EC;
  border-radius: 6px;
  padding: 16px 20px;
  text-align: center;
  margin-bottom: 16px;
  .suspendInfo_heading{
    font-size: 20px;
    font-weight:400;
    color:#000
  }
  .suspendInfo_par{
    color:#6D6D6D;
    a{
      text-decoration:underline;
      color:#6D6D6D;
    }
    &.suspendInfo_par_support{
      font-size: 14px;
    }
  }

}
@media screen and (max-width: 500px) {
  .Login {
    &__InputBox {
      max-width: 85vw !important;
    }
  }
  #logoBefore {
    content: url('../../assets/Images/MobHoneycomAfter.png') !important;
    height: 200px;
    width: 140px;
    margin-top: 30px;
    margin-right: -2px;
  }
  #logoAfter {
    content: url('../../assets/Images/mobileHoneycomb.png') !important;
    height: 200px;
    width: 128px;
    margin: -6rem 0 0 -2rem;
    margin: -15vh 0 0 -2.4rem;
    margin-top: calc(100vh - 120vh);
  }

  .after-login-bg {
    // position: fixed;
    // bottom: 0;
    position: relative;
  }
}
