@import 'tokens.scss';

.DateRangeSelector {
  background-color: $color-white;
  margin-bottom: 1rem;
  border-radius: 20px;
  padding: 15px;
  &__body {
    display: flex;
    @media (max-width: 699px) {
      flex-direction: column;
    }
  }
  &__start_end_date {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2b1f0a;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: 3200px) {
      font-size: 36px;
    }
  }
  &__pick_timeframe {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 34px !important;
    }
  }
  &__pick_timeframe_subheading {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 1.5 !important;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 27px !important;
    }
  }
  &__date-selection {
    flex-basis: max-content;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 699px) {
      padding: 0;
    }
  }
  &__date-display {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid lightgray;

    @media (max-width: 699px) {
      border-left: 0;
    }
  }
  &__count_days {
    margin: 0.75rem 0 1.25rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    color: #ffab03;

    & > span {
      font-weight: 600;
    }

    @media screen and (min-width: 3200px) {
      font-size: 46px;
    }
  }

  &__count_days.error {
    max-width: 15rem;
    color: #ff2d38 !important;
  }

  &__info_container {
    max-width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #d1d1d1;
    padding: 12px;
    gap: 8px;

    p {
      margin: 0;
      font-size: 12px;
      line-height: 17px;
      color: #757575;
    }
  }
  &__error_container {
    max-width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    p {
      margin: 0;
      font-size: 12px;
      line-height: 17px;
      color: #757575;
    }
  }
}
