@import 'tokens.scss';

// override default leaflet styles
.leaflet-container a.leaflet-popup-close-button {
  color: $text-on-primary;
  background: $color-white;
  height: 25px;
  width: 25px;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: $box-shadow-400;
  &:hover {
    box-shadow: $box-shadow-300-dark;
    color: $text-on-primary-darker;
  }
}

.leaflet-popup-tip-container {
  // display: none;
}

.MapPopup {
  padding: 1rem;
  &__header {
    padding-bottom: 1rem;
    white-space: nowrap;
    font-size: $text-size-m;

    span {
      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
    }

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  &__body {
    .Text {
      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
      svg {
        @media screen and (min-width: 3200px) {
          font-size: 26px !important;
        }
      }
    }
  }
  &__buzzStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f68533;
    text-align: center;
    color: white;
    width: 124px;
    height: 24px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;

    @media screen and (min-width: 3200px) {
      width: 224px;
      height: 56px;
      font-size: 26px;
    }
  }
}
.feature-buzz_history {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &-line {
    width: 8px;
    margin: 0 7px;
    bottom: 0;
    position: relative;
    z-index: 999;

    @media screen and (min-width: 3200px) {
      width: 13px;
      margin: 0 11px;
    }
  }

  &-text {
    margin: 10px 0 0 !important;
    font-size: 1rem !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
}
