.setPasswordInput {
  background: #ffffff;
  box-sizing: border-box;
  outline: none;
  font-family: 'Helvetica Neue';
  border-radius: 3px;
  padding-top: 4px;
  @media screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}
.setPasswordInput:focus {
  border: 1px solid #ffab03 !important;
}

.setPasswordInput::-ms-reveal {
  display: none;
}
.hidden {
  display: none;
}
.setPasswordInputError {
  background: #fcf2f2;
  box-sizing: border-box;
  outline: none;
  border-radius: 3px;
  font-family: 'Helvetica Neue';
  color: #bd0000;
  border: 1px solid #bd0000 !important;

  @media screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.setPasswordInput:focus {
  border: 1px solid #bd0000;
}
.inputLeftIcon {
  position: absolute;
  color: #aab2b5;
  //   top: 500px;
}
.inputLeftIconError {
  position: absolute;
  color: #bd0000;
}
.inputRightIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #bd0000;

  font-size: 21px;

  @media screen and (min-width: 3200px) {
    font-size: 32px;
  }
}
.inputBoxPrefix {
  margin: 0;
}
.campaignInputRightIcon {
  @media screen and (max-width: 699px) {
    left: auto !important;
    right: 6px !important;
  }
}
.inputPrefix {
  @media screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}
