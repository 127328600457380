.SaveTargetAudience {
  // display: block;
}
.SaveAudiencePosition {
  position: relative;
  overflow: hidden;

  .icon {
    position: absolute;
    left: 20px;
    top: 15px;

    svg {
      font-size: 18px;
      color: grey;
      cursor: pointer;

      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
    }
  }
}
.SaveAudienceWizard {
  &__btn {
    margin: 0 auto;
  }
}

.save-and-launch-audience-btn {
}

.save-audience-btn {
  margin-top: 5px;
}
.ListingAudienceModal {
  display: flex;
  flex-direction: column;
  width: 505px;
  margin: 0 2rem 2rem 2rem;

  @media screen and (min-width: 3200px) {
    width: 700px !important;
  }

  @media (min-width: 1920px) {
    margin: 0 auto;
  }
  @media (max-width: 699px) {
    width: calc(100vw - 10%) !important;
    margin: 1rem 5px;
  }
}
.ListingModalTagBg::before {
  content: '';
  background: url('../../../../assets/Images/honeycomb_left.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -150px;
  left: -180px;
  background-size: 330px;
  background-position: left top;
  z-index: -1;
}
.ListingModalTagBg::after {
  content: '';
  background: url('../../../../assets/Images/honeycomb.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 330px;
  top: 165px;
  right: -110px;
  background-position: right bottom;
  z-index: -1;
}
.ListingModalBg::before {
  content: '';
  background: url('../../../../assets/Images/honeycomb_left.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -240px;
  left: -100px;
  background-size: 330px;
  background-position: left top;
  z-index: -1;
}
.ListingModalBg::after {
  content: '';
  background: url('../../../../assets/Images/honeycomb.svg') no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 330px;
  top: 220px;
  left: 0;
  background-position: right bottom;
  z-index: -1;
}
