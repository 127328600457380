.overlay_container {
  width: 430px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  border-radius: 1rem;
  background: #fff9ed;
  padding: 16px 20px;
  box-shadow: 0 4px 84px 0 #51515140;

  h4,
  p {
    margin: 0;
  }

  h4 {
    font-size: 20px;
    color: #343434;
    margin-bottom: 0.75rem;
  }

  p {
    line-height: 24px;
    font-size: 16px;
  }

  .paligon {
    position: absolute;
    top: 40%;
    left: -10px;
  }

  .link {
    font-family: 'Open Sans';
    margin-top: 0.75rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    display: inline-block;
    color: #ffab03;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }
}
