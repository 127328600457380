.RecommendationContainer {
  .title {
    display: flex;
    font-size: 18px;
    margin: 1rem 0 0.5rem 0;
  }

  .heading_desc {
    display: flex;
    font-size: 16px;
    margin: 0 0 2.5rem 0;
  }

  .p-tabmenu.p-component.nav-menu {
    width: 100%;

    .p-tabmenu-nav {
      gap: 30px;
      background: transparent;

      li.p-tabmenuitem {
        .p-menuitem-link {
          background: transparent;
          padding: 10px 20px 10px 10px;
          font-weight: 400;
          color: #000000;

          @media screen and (min-width: 3200px) {
            padding: 20px 30px 20px 20px;
            font-size: 30px;
          }

          &.disabled {
            color: lightgray;
            cursor: not-allowed;
            border: none;
          }
        }

        &:not(.p-highlight):has(.p-menuitem-link:not(.disabled)):hover {
          .p-menuitem-link {
            border-color: #f6b433;
          }
        }

        &.p-highlight {
          .p-menuitem-link {
            color: #f6b433;
            border-color: #f6b433;
            font-weight: 700;
          }
        }
      }
    }
  }

  &__nav {
    border-radius: 18px;

    @media (max-width: 1050px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      padding: 0 2rem;
    }

    @media (max-width: 699px) {
      padding: 0.5rem;
    }
  }

  &__main {
    flex-grow: 1;
    overflow-y: auto;
    padding-block: 1rem;
    border-radius: 20px;
    margin-top: 5px;

    @media (max-width: 1050px) {
      padding-top: 0.5rem;
    }
    @media (max-width: 699px) {
      padding: 0.5rem;
    }
  }
}
