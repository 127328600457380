@import 'src/tokens';

.HeaderDropdown {
  $this: &;
  cursor: pointer;
  position: relative;
  display: flex;
  &__button {
    border: none;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
    &:focus {
      box-shadow: $focus-ring-inset;
      outline: none;
    }
    &:focus:not(:focus-visible) {
      box-shadow: none;
      outline: none;
    }
    &:focus-visible {
      box-shadow: $focus-ring-inset;
    }
  }
  // &:hover {
  //   background-color: $color-primary-lighter;
  // }
  &__iconLeft {
    margin-right: 1.1rem;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    @media (max-width: 699px) {
      margin: 0 0.5rem;
    }
  }

  img {
    object-fit: cover;
    cursor: default;
  }

  &__icon {
    // margin-left: 0.5rem;
    vertical-align: middle;
    transition: transform $transition-150;
    margin-left: 9px;
    vertical-align: middle;
    transition: transform var(--transition-150);
    margin-top: -8px;
    cursor: pointer;

    #{$this}--is-open & {
      vertical-align: middle;
      // transform: rotate(-0.5turn);
      margin-top: 1.8px;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-400;
    background-color: $color-white;
    width: max-content;
    position: absolute;
    overflow: hidden;
    top: calc(100% - 0.05rem);
    border-radius: $border-radius-m;

    #{$this}--position-left & {
      right: 0.25rem;
    }

    #{$this}--position-right & {
    }
  }
  .burger_menu {
    margin: 0 0.6rem;
    font-size: 19px;

    @media (max-width: 699px) {
      margin-right: 1rem;
    }

    @media screen and (min-width: 1050px) {
      display: none;
    }
  }

  .buttons_container {
    display: flex;
    align-items: center;
    min-width: 160px;
    // margin: 0px 0.5rem;
    padding: 0px 1rem;
    cursor: default;
    justify-content: space-around;

    @media (max-width: 699px) {
      padding: 0;
      margin: 0;
      justify-content: flex-end;

      .iconClassName,
      .userNameClassName,
      .iconLeftClassName {
        display: none;
      }
    }
  }
}

.hoverEffect {
  &:hover {
    background-color: $color-primary-lighter;
  }
}
