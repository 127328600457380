.SaveAudienceModal {
  min-width: 30%;
  width: 50%;

  @media screen and (min-width: 3200px) {
    width: auto;
  }

  &__content {
    padding: 1rem;
    max-width: 50vw;
    margin: 0 auto;
  }
  &__Header {
    font-size: 40px;
    line-height: 48px;
  }
  &__cancel {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;

    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 650px) {
      right: -6rem;
    }
    @media screen and (max-width: 600px) {
      right: 1rem;
    }
  }
  @media screen and (max-width: 699px) {
    &__insideText {
      font-size: 17px !important;
    }
  }
}
.targetAudience {
  &__btn {
    border: 1px solid #dadada;
    border-radius: 12px;
    color: #6d6d6d;
    height: 2.5rem;
  }
  &__btn:hover {
    color: #6d6d6d;
    background-color: #fff;
  }
  &__tags_wrapper {
    position: relative;
  }
  &__tags {
    border-radius: 12px;
    border: 1px solid #dadada;
    color: #6d6d6d;
    height: 2.5rem;
    width: auto;
    margin: 0 0.5rem;
  }
  &__tags:not(:disabled):hover {
    .Button__icon-wrapper {
      .targetAudience__tagsCross {
        display: grid;
      }
    }
  }
  &__tagsCross {
    color: #6d6d6d;
    font-size: 15px;
    display: none;
  }
}

.audience-size-inform-text {
  color: #9a9a9a;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin-block-start: 18px;
  margin-block-end: 20px;

  &.note {
    font-size: 14px;
    line-height: 20px;
    margin-block-start: 18px;
    margin-block-end: 0;
  }
}
