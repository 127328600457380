@import 'src/tokens';
@import 'src/mixins';

.CampaignView {
  height: 100%;
  &__header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &__downloadPdf {
    border-radius: 10px;
    color: #2b1f0a !important;
    width: 231px;

    @media screen and (min-width: 3200px) {
      width: auto;
    }
  }
  .PageHeader__actions {
    @media (max-width: 699px) {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    flex: 1;

    @media screen and (max-width: 1050px) {
      flex-direction: column;
    }
  }
  &__left-column {
    // min-width: 35rem;
    width: 50%;
    padding: 1rem;
    background-color: #f8f8f8;

    @media (max-width: 699px) {
      padding: 0;
    }

    @media screen and (max-width: 1050px) {
      width: 100%;
    }

    .CampaignDetails {
      .flexbox_conainer {
        @media (max-width: 699px) {
          justify-content: center;
        }
      }
    }
  }
  &__right-column {
    width: 50%;
    // padding: 1rem;

    @media screen and (max-width: 1050px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
  &__reportHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 26px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 36px !important;
    }
  }

  .creative-title.Text {
    font-size: 26px !important;
    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 36px !important;
    }
  }
}

.text-campaign-details {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #2b1f0a !important;
}
.campaign-save-audience-heading {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 26px !important;
  line-height: 1.5 !important;
  color: #2b1f0a !important;

  @media screen and (min-width: 3200px) {
    font-size: 36px !important;
  }
}
.text-campaign-details-value {
  margin-top: 5px !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #2b1f0a !important;
}
.section-campaign-details {
  color: #2b1f0a !important;
  font-weight: 300 !important;
  font-size: 24px !important;
}

.date-campaign-view .DateRangePicker {
  width: 300px !important;

  @media screen and (min-width: 3200px) {
    width: 45rem !important;
  }

  @media (max-width: 699px) {
    width: 100% !important;
  }
}

.valueWidth {
  width: 268px;
}

.link-img {
  width: 30px;
  // height: 30px;
  margin-left: 8px;
  margin-bottom: 8px;
  height: 23px;
  // object-fit: contain;
}

.campaign-save_audience {
  width: 50%;
  padding-left: 1rem;
  @media (max-width: 1354px) {
    width: 100% !important;
  }
}
.campaign-created {
  width: 50%;
  padding-right: 1rem;
  border-right: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1354px) {
    width: 100% !important;
    border-right: none;
  }
}
.allCampaignCreatives_box {
  width: 91%;
  display: flex;
  justify-content: space-between;
}
.allCampaignCreatives {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #2b1f0a !important;

  @media screen and (min-width: 3200px) {
    font-size: 26px !important;
  }

  &__viewall {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;

    color: #f1a200 !important;
  }
}
.sub_title_heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  color: #2b1f0a !important;

  @media screen and (min-width: 3200px) {
    font-size: 28px !important;
  }
}
