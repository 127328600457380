@import 'tokens.scss';

.nodata-inprogress {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;
  align-items: center;
}
.CampaignDashboard {
  //height: 100px;
  //padding: 5px !important;
  //background: none !important;
  &__inProgressPieChart {
    font-weight: 400;
    font-size: 15px;
    width: 250px;
    line-height: 16px;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: 3200px) {
      width: 350px;
    }

    @media (max-width: 1600px) {
      width: 200px;
    }
    @media (max-width: 1400px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
    }
  }
  .CampaignDashboard__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000000 !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }

  &__in-progress-campaign-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    // flex-direction: column;
    padding-right: 1rem;
    margin: 1rem;
    @media screen and (max-width: 1500px) {
      flex-direction: column;
    }
    @media screen and (max-width: 699px) {
      display: block;
      padding-right: 0;
      margin: 0;
    }
  }

  &__in-progress-campaign-bar {
    align-self: center;
  }

  &__in-progress-campaign-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    // margin-left: -2rem;
    @media screen and (max-width: 1500px) {
      margin-left: 0;
      flex-direction: row;
      gap: 1rem;
    }
    @media screen and (max-width: 699px) {
      gap: 1rem;
      margin-left: 0;
    }
  }

  &__in-progress-campaign-circle {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 11px;

    @media screen and (min-width: 3200px) {
      height: 44px;
      width: 44px;
      margin-right: 20px;
    }
  }

  &__value {
    font-weight: 700;
  }
}
.campaignType__context_container {
  display: flex !important;
  align-items: center !important;
}
