.save_audiences_container {
  margin: auto;
  // width: 50%;
  // width: 225px;
  // height: 300px;
  padding-right: 1rem;

  &__pie_className {
    font-size: 18px;
    font-weight: 700;
    fill: #263238;
  }
}
.SavedAudiences__leftimage {
  height: 190px;

  @media screen and (min-width: 3200px) {
    height: 350px;
  }

  @media screen and (max-width: 1600px) {
    height: 180px;
  }
  @media screen and (max-width: 1400px) {
    height: 150px;
  }
}

.noDataText {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #aab2b5 !important;
}
.noData {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
}

.SavedAudiences__childContainer {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SavedAudiences__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #000000 !important;

  @media screen and (min-width: 3200px) {
    font-size: 28px !important;
  }
}
.SavedAudiences__container {
  background: none !important;
}
.saved_audience_subheading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.25;
  margin: 0;
  color: #000000;
  @media screen and (min-width: 3200px) {
    font-size: 38px;
  }
}
.saved_audience_heading {
  margin: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.25;

  color: #647dff;

  @media screen and (min-width: 3200px) {
    font-size: 150px;
  }
}
