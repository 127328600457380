.goBackModal {
  height: 265px !important;
  width: 600px !important;
  &__text {
    font-size: 30px !important;
    /* line-height: 54px; */
    text-align: center !important;
    color: #2b1f0a;
    font-weight: 600;
  }
  &__sub_text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 8px;
    color: #6d6d6d !important;
    display: flex;
    justify-content: center;
  }
}
