@import './helpers/class_map_mixin';

$properties: (
  'display': (
    'flex',
    'grid',
  ),
  'align-items': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
  ),
  'align-self': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
  ),
  'align-content': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
  ),
  'justify-items': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
  ),
  'justify-self': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
  ),
  'justify-content': (
    'flex-start',
    'flex-end',
    'center',
    'stretch',
    'space-between',
    'space-around',
    'space-evenly',
  ),
  'flex-direction': (
    'column',
    'row',
    'column-reverse',
    'row-reverse',
  ),
  'flex-wrap': (
    'wrap',
    'nowrap',
    'wrap-reverse',
  ),
  'text-align': (
    'left',
    'right',
    'center',
    'justify',
  ),
);

.Box {
  box-sizing: border-box;

  @include createPropertyClasses($properties);
}
