@import 'tokens.scss';

.search-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin-bottom: 5px;
}
.SellYourListingFaster {
  position: relative;
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    // padding: 1rem 2rem;
    // padding: 0.3rem 0 1rem !important;
    padding: 0 !important;
  }

  .prospects_wrapper {
    @media (max-height: 1000px) {
      // border: 1px solid;
      width: 100%;
      z-index: 999;
      // display: flex;
      // align-items: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      position: fixed;
      left: 30px;
      bottom: 40px;
      height: 125px;
    }
  }

  .LensFilterContainer {
    position: absolute;
    background: #ffffff;
    box-shadow: 12px 7px 27px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
  }

  .MyListingsLens__filter {
    top: 55px;
    right: 0;
    left: 505px;

    @media screen and (min-width: 3200px) {
      top: 90px;
      left: 774px;
    }
  }

  &__rightTop {
    position: absolute;
    z-index: 999;
    top: 16px;
    left: 495px;
    justify-content: space-between;

    @media screen and (min-width: 3200px) {
      left: 764px;
    }
  }

  &__search_location {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.375;
    margin-bottom: 6px;

    display: flex;
    align-items: flex-end;

    color: #ffab03;
    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }
  }

  .realtor_container {
    position: absolute;
    left: 127px;
    z-index: 401;
    margin-right: 0;
    margin-top: 0;
    max-height: 580px;
    overflow: auto;

    @media screen and (min-width: 3200px) {
      left: 269px;
    }

    .realtor {
      margin: 0;
    }
  }

  &__city_zipcode {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;

    display: flex;
    align-items: flex-end;

    color: #000000;
  }
  .filters_prospects {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.35;

    display: flex;
    align-items: flex-end;

    color: #000000;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }

  &__sidebarFiltersUnselectedButton {
    color: #b7b7b7;
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 10px;
    margin: 1rem 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;

    @media screen and (min-width: 3200px) {
      font-size: 32px;
      margin: 2rem 0;
    }
  }

  &__sidebarFiltersUnselectedButton:hover {
    background: #fff6e4;
    border: 1px solid #ffab03;
    border-radius: 10px;
    color: #ffab03;
  }
  &__sidebarFiltersButton {
    background: #fff6e4;
    border: 1px solid #ffab03;
    border-radius: 10px;
    margin: 1rem 0;
    color: #ffb522;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  &__sidebarFiltersButton:hover {
    border: 1px solid #ffab03;
    background: #fff6e4;
    color: #ffb522;
  }
  &__modal {
    margin-left: -220px !important;
    min-height: 300px;
    min-width: 400px;
    padding: 1rem;
  }
  &__filter-selection {
    position: fixed;
    width: max-content;
    background-color: $color-white;
    border: $border-width-thin solid $color-grey-200;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-400-dark;
    overflow: hidden;
    margin-top: 0.25rem;
    padding: 0.25rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }

  &__modalCancel {
    background-color: #ffffff;
    color: #ffab03;
    font-size: 17px;
    font-weight: 400;
    border: none;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }

  &__modalCancel:not(:disabled):hover {
    background: inherit;
    color: #ffab03;
  }

  &__modalSave {
    background: #fcd917;
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    border: none;

    color: #000000;
    float: right;
    margin: 9px 0;
    @media screen and (min-width: 3200px) {
      font-size: 28px;
      margin: 13px 0;
    }
  }
  &__modalSave:not(:disabled):hover {
    background: inherit;
    color: #fcd917;
  }
  &__modalOptions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__modalOption {
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: 60px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;

    display: flex;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 3200px) {
      height: 100px;
      width: 110px;
      gap: 15px;
      font-size: 24px !important;
    }
  }
  &__modalOption:hover {
    background-color: inherit;
  }
  &__modalOption:active,
  &__modalOption:focus {
    background-color: #ffab03;
    color: white;
  }
  &__modalOption01 {
    // margin: 0 3px;
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: 60px;
    text-align: center;
  }
  &__modalOption01:hover {
    background-color: inherit;
  }
  // &__modalOption:focus ,
  &__modalOption01:active {
    background-color: #007b94;
  }
  .LocationLabel {
    margin-bottom: 20px;
  }

  .first_filter_button {
    margin-top: 0.4rem;
  }

  &__sideFilter {
    position: absolute;
    left: 495px;
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 14px 3px #bbb6b6;
    z-index: 1000;

    &.bedrooms,
    &.price,
    &.sqft,
    &.home_type {
      top: 250px;
      @media (max-width: 768px) {
        top: 370px;
        left: 60px;
      }

      @media (max-height: 700px) {
        top: 200px;
      }
      @media screen and (min-width: 3200px) {
        top: 448px;
      }
    }

    &.bedrooms,
    &.price,
    &.sqft,
    &.home_type {
      .title-divider {
        width: 100%;
        margin-left: 5px;
        height: 1px;
        background-color: #9a9a9a;
      }
      .title.Text {
        color: #aab2b5 !important;
        font-size: 14px !important;
        font-family: 'Open Sans';
        line-height: 1.2;
        font-weight: 400;

        @media screen and (min-width: 3200px) {
          font-size: 24px !important;
        }
      }
    }

    &.price,
    &.sqft,
    &.home_type {
      .title.Text {
        width: 200px;
      }
    }

    @media screen and (min-width: 3200px) {
      left: 764px;
    }
  }
  //.price {
  //  top: 310px;
  //  @media (max-width: 768px) {
  //    top: 440px;
  //    left: 60px;
  //  }
  //
  //  @media (min-height: 700px) and(max-height: 820px) {
  //    top: 350px;
  //  }
  //
  //  @media (min-height: 581px) and (max-height: 700px) {
  //    top: 280px;
  //  }
  //
  //  @media (max-height: 580px) {
  //    top: 200px;
  //  }
  //}
  //.sqft {
  //  top: 470px;
  //  @media (max-width: 768px) {
  //    top: 440px;
  //    left: 60px;
  //  }
  //
  //  @media (min-height: 700px) and(max-height: 820px) {
  //    top: 350px;
  //  }
  //
  //  @media (min-height: 581px) and (max-height: 700px) {
  //    top: 280px;
  //  }
  //
  //  @media (max-height: 580px) {
  //    top: 200px;
  //  }
  //}
  //.home_type {
  //  top: 370px;
  //  @media (max-width: 768px) {
  //    top: 390px;
  //    left: 60px;
  //  }
  //
  //  @media (max-height: 700px) {
  //    top: 200px;
  //  }
  //}
}
