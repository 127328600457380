@import 'src/tokens';

.Subscription__container {
  &__header {
    display: flex;
    align-items: center;
  }

  &__heading {
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 13px;

    @media (min-width: 700px) and (max-width: 1050px) {
      margin: 1rem 0;
    }
  }

  .Section--dark {
    background: #ffffff;
    // margin-top: 35px;
    // border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 7px;
  }

  .Section__body {
    align-items: center;
  }
}

.SubscriptionSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__plans {
    display: flex;
    padding: 0.75rem;
  }
  &__arrow {
    color: $color-grey-200;
    display: flex;
    align-items: center;
    padding: 3rem 3rem 0 3rem;
  }
  &__button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
