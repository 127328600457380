@import 'tokens.scss';

.PageHeader {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  background: #ffffff;

  .Text {
    @media screen and (min-width: 3200px) {
      font-size: 30px !important;
    }
  }

  @media screen and (min-width: 3200px) {
    padding: 1rem 2.5rem;
  }

  @media screen and (max-width: 699px) {
    display: flex;
    flex-direction: column-reverse;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 699px) {
      justify-content: space-between !important;
    }

    & > * {
      margin: 0 0.5rem;

      @media screen and (min-width: 3200px) {
        margin: 0 1.25rem;
      }

      @media (max-width: 699px) {
        margin: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    ._button {
      padding: 16px 0;
    }
    @media screen and (max-width: 699px) {
      justify-content: center;
    }
  }
}

.icon-header {
  border: none;
  position: absolute;
  // border-right: 2px solid #aab2b5;
  padding: inherit;
  // height: 44px;
  height: 52px;
  padding-top: 10px;
  margin-top: -12px;
  margin-left: 6px;
  &:hover {
    background-color: transparent;
    color: black;
    box-shadow: none;
  }
}
.icon-headers {
  border: none;
  position: absolute;
  // border-right: 2px solid #aab2b5;
  padding: inherit;
  // height: 44px;
  height: 52px;
  padding-top: 10px;
  margin-top: 3px;
  margin-left: 6px;
  &:hover {
    background-color: transparent;
    color: black;
    box-shadow: none;
  }
}

.icon-header-text {
  padding: 0px;
  padding-right: 15px;
  margin-top: -6px;
  height: 41px;
  padding-top: 8px;
}
.creatives-icon {
  margin-left: 42px;
}
