.p-sidebar-right .p-sidebar.post-details-drawer {
  width: 27.25rem;
}

.p-sidebar.p-component.post-details-drawer {
  .p-sidebar-header.p-sidebar-custom-header {
    padding-inline: 20px;
    padding-block: 16px;
    border-bottom: 1px solid #e8e8ea;
    .go-to-post {
      color: #757575;
    }

    .post-details-drawer-header-text {
      color: #161616;
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.08px;
    }
  }
  .p-sidebar-content {
    .post-info {
      display: flex;
      flex-direction: column;
      gap: 14px;
      padding-block: 16px;
      &:not(:last-child) {
        border-bottom: 1px solid #e8e8ea;
      }
      .description {
        color: #183560;
        font-weight: 700;
      }
      .post-info-detail {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        color: #183560;
        .social-media-date {
          font-weight: 700;
        }
        .social-media-icon {
          img {
            width: 20px;
            height: 20px;
          }
        }
        .truncated-text-container {
          position: relative;
          max-width: 500px;
          color: #262626;

          .truncated-text {
            overflow: hidden;
            transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
            max-height: 3.6em;
            line-height: 1.8em;
            opacity: 0.8;
          }

          .truncated-text.expanded {
            max-height: 100vh;
            opacity: 1;
          }
        }
      }
      .see-more-button {
        background: transparent;
        border: none;
        color: #007b94;
        cursor: pointer;
        font-size: 16px;
        padding: 0;
        &:hover {
          text-decoration: underline;
        }
      }
      .media-container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .media-wrapper {
          width: 124px;
          height: 109px;
          .media-img {
            width: 100%;
            height: 81px;
            border-radius: 4px 4px 0 0;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            .media-actions {
              width: 100%;
              height: 100%;
              z-index: 100;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              font-size: 12px;
              transition: 0.5s background-color ease-in;
              button {
                background: transparent;
                border: none;
                display: none;
                padding: 0;
                cursor: pointer;

                svg {
                  color: #fff;
                  font-size: 18px;
                }
              }
              &:hover {
                background-color: #00000099;
                button {
                  display: block;
                }
              }
            }
            .media {
              width: 100%;
              object-fit: contain;
            }
          }
          .media-name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            background: #f1f6fc;
            padding: 4px;
            font-size: 12px;
            svg {
              font-size: 18px;
              color: #d85140;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
