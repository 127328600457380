@import 'src/tokens';

.Table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  contain: strict;
  &__body {
    color: $color-grey-700;
  }
}

.TableWrapper {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: white;
}
