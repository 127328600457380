@import 'tokens';

.ConfirmPurchase {
  &__card-selected {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $color-grey-100;
    color: $text-on-grey-100;
    padding: 0 1rem 1rem 1rem;
    width: 15rem;
    height: 11rem;
    box-shadow: $box-shadow-200;
  }
  &__cc-icon {
    margin-top: 3rem;
    color: $text-on-grey-100--subdued;
  }
  &__cc-title {
    text-align: center;
    text-transform: uppercase;
    padding: 0.5rem 0;
  }
}
