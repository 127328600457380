@import 'src/tokens';

@mixin modal-position {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Modal {
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include modal-position();
  &__shade {
    background-color: rgba(0, 0, 0, 0.25);

    @include modal-position();
  }
  &__header {
    padding: 1rem;
    font-weight: $text-weight-bold;
    font-size: 1.2rem;
  }
  &__content {
    background-color: white;
    border-radius: 20px;
    box-shadow: $box-shadow-300-dark;
    z-index: 1;
    &--has-no-background {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }
  &__scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__close {
    color: $color-grey-200;
    border: none;
    &:hover {
      color: $text-on-white--subdued;
    }
  }
}
