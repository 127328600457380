@font-face {
  font-family: Helvetica;
  src: url('../../assets/fonts/HelveticaNeue.ttf');
}
.OnboardingModal {
  &__welcome {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 699px) {
      max-width: 310px;
    }
  }

  &__welcomeHeader {
    margin-bottom: 1rem;
  }
}
.OnboardingModal-welcome {
  position: relative;
  padding: 2rem;
  display: flex;
  width: 980px;
  height: 650px;
  flex-direction: column;

  @media screen and (min-width: 3200px) {
    height: 750px;
  }

  @media (max-width: 1050px) {
    width: 600px;
  }

  @media (max-width: 699px) {
    width: 310px;
    height: auto;
    padding: 2rem 1rem;
  }

  .btn-container {
    display: flex;
    justify-content: center;

    @media (max-width: 699px) {
      margin-top: 1rem;
    }
  }
}
.description > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  color: #6d6d6d;

  @media screen and (min-width: 3200px) {
    font-size: 30px;
  }
}
.OnboardingModal-welcomeHeader {
  padding: 4rem;

  .description {
    padding: 0 5rem;
    text-align: center;

    @media (max-width: 699px) {
      padding: 0;
    }
  }

  @media (max-width: 699px) {
    padding: 0;
  }
}
.beewo-header-congrats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.beewo-header-congrats > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.75;
  margin: 0;
  text-align: center;
  color: #ffab03;

  @media screen and (min-width: 3200px) {
    font-size: 40px;
  }

  @media (max-width: 699px) {
    font-size: 22px;
    line-height: 35px;
  }
}
.beewo-header-welcome > p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
  color: #2b1f0a;
  margin: 0;

  @media (max-width: 699px) {
    font-size: 28px;
  }
}

.Leftgroup {
  position: absolute;
  width: 20%;
  top: -0px;
  left: -0px;
}
.Rightgroup {
  position: absolute;
  width: 27%;
  bottom: 0px;
  right: 0px;
}
