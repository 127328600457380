@import 'src/tokens';

.TableCell {
  text-align: left;
  padding: 0.8rem 1rem;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.25;
  color: #757575;

  * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }

  @media screen and (min-width: 3200px) {
    padding: 1.6rem 2rem;
    font-size: 28px;
  }

  &--right {
    text-align: right;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &__text-overflow {
    position: absolute;
    padding: calc(1rem - 1px);
    background: $color-white;
    border: $pixels-1 solid $color-grey-200;
    box-shadow: $box-shadow-200;
    border-radius: $border-radius-s;
    display: flex;
    align-items: center;
  }
}
