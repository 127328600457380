@import 'tokens.scss';

.UploadMailerModal {
  padding: 2rem;
  display: grid;
  gap: 1rem;

  &__grid_box {
    gap: 0;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 699px) {
      grid-template-columns: 1fr;
    }
  }
  &__download {
    color: rgb(27, 161, 197);
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    padding: 0 1 rem;
    margin-left: 1rem;
  }
  &__hl {
    border: 2px solid #e3e4e5;
    height: $pixels-2;
    width: 100%;
    background-color: $color-grey-300;
  }
  &__creative-placeholder {
    border: $border-width-default solid $color-grey-300;
    background-color: #eeeff0;
  }
  &__step-text {
    letter-spacing: 2px;
    font-size: 15px !important;
    color: var(--color-secondary-300) !important;
    line-height: 9px !important;
  }
  &__step-subheading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 26px !important;
    line-height: 35px !important;

    /* Black - 7 */

    color: #000000;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__cancel-button {
    margin-right: 1rem;
  }
}

.pd-0 {
  padding: 0px !important;
}
.border-b {
  border-bottom: 1px solid;
}

.recommended-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  width: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center !important;

  /* Light 400 */

  color: #6d6d6d !important;
}
