.CampaignCheckout {
  height: 100%;
  &__PageHeader {
    height: 84px;
    background-color: var(--color-white);

    @media screen and (min-width: 3200px) {
      height: 120px;
    }
  }
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: #2b1f0a !important;

    @media (max-width: 699px) {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }
  &__add_display {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    text-align: center;
    margin: 0;
    color: #6d6d6d;

    @media screen and (min-width: 3200px) {
      font-size: 30px;
    }
  }

  .CampaignCreationWrapper {
    flex-direction: column;
  }
}
.campaignBack {
  border: 1px solid #bd0000;
  justify-content: center;
  padding: 20px 40px;
  background: rgba(201, 34, 45, 0.08);
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bd0000;
  width: 212px;
  height: 54px;

  @media screen and (min-width: 3200px) {
    width: auto;
    height: auto;
  }
}
.campaignBack.Button:hover {
  background: none;
  color: #bd0000;
}
