@import 'tokens.scss';

.UploadVideoModal {
  padding: 1.6rem;
  display: grid;
  gap: 1.1rem;
  position: relative;

  .creative-recommendations {
    .label, .text {
      color: var(--Light-400, #6D6D6D);
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  &__cancel-button {
    margin-right: 1rem;
  }

  &__hl {
    border: 1px solid #e3e4e5;
    height: $pixels-2;
    width: 100%;
    background-color: $color-grey-300;
  }
}
.upload_your_video {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 1.5;
  color: #000000;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 3200px) {
    font-size: 35px;
  }
}
.upload_video_disc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #515a5c;

  @media screen and (min-width: 3200px) {
    font-size: 26px;
  }
}
