.cost_item_wrapper {
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 12px;

  .heading {
    width: 100%;
    background: #f6f6f6;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .actions-wrapper {
    width: 100%;

    .TextInputError {
      border-width: 1px !important;
    }

    .manual_input_wrapper {
      flex: 1;
      .manual_input {
        font-size: 14px;
        padding: 5px 10px;
        height: 44px;
        border-radius: 6px;
        min-width: 12rem;
        border: 1px solid #e7e7e7;
        outline: none;
        box-shadow: none;
      }

      .error_input {
        border: 1px solid #ff2d38;
      }
    }

    .slider_input_wrapper {
      flex: 2;
      padding-top: 50px;

      .cost_slider {
        width: 100%;
      }
    }
  }

  .p-slider .p-slider-range {
    background-color: #007b94 !important;
  }

  .p-slider .p-slider-handle {
    border-color: #007b94 !important;
    background-color: #007b94 !important;
  }

  .disabled_slider {
    .p-slider .p-slider-handle {
      border-color: #e7e7e7 !important;
      background-color: #e7e7e7 !important;
    }
  }
}
