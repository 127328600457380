@import 'tokens.scss';

.search-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin-bottom: 5px;
}
.DigitalFarming {
  display: flex;
  flex-grow: 1;
  position: relative;

  &_residential_status_container {
    position: absolute;
    top: 16px;
    right: 32px;
    background: var(--color-white);
    border-radius: 10px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 252px;
    z-index: 999;

    &_title {
      color: #000;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    &_status_content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &_status_item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_title_inner {
        display: flex;
        align-items: center;
        gap: 8px;

        .status_dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;

          &.Active {
            background: #52c41a;
          }
          &.Pending {
            background: #fcd917;
          }
          &.Sold {
            background: #ffab03;
          }
          &.Unlisted {
            background: #007b94;
          }
        }
      }

      &_count_percentage,
      &_title_inner .title {
        color: #000;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__sidebar-content {
    // padding: 1rem 2rem;
    // padding: 0.3rem 0 1rem !important;
    padding: 0 !important;

    &__observation-filters {
      &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__filter-inner {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .filter {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              color: #000;
              font-family: 'Open Sans';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
      .divider {
        margin-block: 26px;
        width: 100%;
        height: 0.5px;
        background: rgba(88, 88, 88, 0.5);
      }
    }
  }

  .prospects_wrapper {
    @media (max-height: 1000px) {
      // border: 1px solid;
      width: 100%;
      z-index: 999;
      // display: flex;
      // align-items: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      position: fixed;
      left: 30px;
      bottom: 40px;
      height: 125px;
    }
  }

  .LensFilterContainer {
    position: absolute;
    width: 120px;
    height: 168px;
    background: #ffffff;
    box-shadow: 12px 7px 27px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    // top: 53px;
    // left: 450px;
  }

  .MyListingsLens__filter {
    top: 55px;
    right: 0;
    left: 450px;

    @media screen and (min-width: 3200px) {
      left: 765px;
    }
  }

  &__rightTop {
    position: absolute;
    z-index: 999;
    top: 16px;
    left: 440px;
    justify-content: space-between;

    @media screen and (min-width: 3200px) {
      left: 764px;
    }
  }

  &__search_location {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.375;
    margin-bottom: 6px;

    display: flex;
    align-items: flex-end;

    color: #ffab03;
    @media screen and (min-width: 3250px) {
      font-size: 26px;
    }
  }

  .realtor_container {
    position: absolute;
    left: 105px;
    z-index: 401;
    margin-right: 0;
    margin-top: 0;
    max-height: 580px;
    overflow: auto;
    .realtor {
      margin: 0;
    }
  }

  &__city_zipcode {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;

    display: flex;
    align-items: flex-end;

    color: #000000;
  }

  // .LocationLabel {
  //   box-sizing: border-box;
  //   background: #ffffff;
  //   border: 1px solid #dadada;
  //   border-radius: 10px;

  //   svg {
  //     color: #bbbdbf;
  //   }

  //   .content {
  //     font-family: 'Open Sans';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 22px;
  //     /* identical to box height */

  //     display: flex;
  //     align-items: flex-end;

  //     color: #b9b9b9;
  //   }
  // }

  .filters_prospects {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.35;
    /* identical to box height */

    display: flex;
    align-items: flex-end;

    color: #000000;
    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }
  }

  &__sidebarFiltersUnselectedButton {
    color: #b7b7b7;
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 10px;
    margin: 1rem 0;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;

    @media screen and (min-width: 3200px) {
      font-size: 32px;
      margin: 2rem 0;
    }
  }

  &__sidebarFiltersUnselectedButton:hover {
    background: #fff6e4;
    border: 1px solid #ffab03;
    border-radius: 10px;
    color: #ffab03;
  }
  &__sidebarFiltersButton {
    background: #fff6e4;
    border: 1px solid #ffab03;
    border-radius: 10px;
    margin: 1rem 0;
    color: #ffb522;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  &__sidebarFiltersButton:hover {
    border: 1px solid #ffab03;
    background: #fff6e4;
    color: #ffb522;
  }
  &__modal {
    margin-left: -220px !important;
    min-height: 300px;
    min-width: 400px;
    padding: 1rem;
  }
  &__filter-selection {
    position: fixed;
    width: max-content;
    background-color: $color-white;
    border: $border-width-thin solid $color-grey-200;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-400-dark;
    overflow: hidden;
    margin-top: 0.25rem;
    padding: 0.25rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }

  &__modalSave {
    background: #fcd917;
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    border: none;

    color: #000000;
    float: right;
    margin: 9px 0;
    // padding: 20px 0;
  }
  &__modalSave:hover {
    background: inherit;
    color: #fcd917;
  }
  &__modalOptions {
    display: flex;
  }
  &__modalOption {
    // margin: 0 3px;
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: 60px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
  }
  &__modalOption:hover {
    background-color: inherit;
  }
  &__modalOption:active,
  &__modalOption:focus {
    background-color: #ffab03;
    color: white;
  }
  &__modalOption01 {
    // margin: 0 3px;
    border: 1px solid #e3e4e5;
    box-sizing: border-box;
    height: 50px;
    width: 60px;
    text-align: center;
  }
  &__modalOption01:hover {
    background-color: inherit;
  }
  // &__modalOption:focus ,
  &__modalOption01:active {
    background-color: #007b94;
  }
  .LocationLabel {
    margin-bottom: 20px;
  }

  .first_filter_button {
    margin-top: 0.4rem;
  }

  &__sideFilter {
    position: fixed;
    left: 520px;
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 4px 14px 3px #bbb6b6;
    z-index: 1;
  }
  .bedrooms {
    top: 360px;
    @media (max-width: 768px) {
      top: 370px;
      left: 60px;
    }

    @media (max-height: 700px) {
      top: 200px;
    }
  }
  .price {
    top: 470px;
    @media (max-width: 768px) {
      top: 440px;
      left: 60px;
    }

    @media (min-height: 700px) and(max-height: 820px) {
      top: 350px;
    }

    @media (min-height: 581px) and (max-height: 700px) {
      top: 280px;
    }

    @media (max-height: 580px) {
      top: 200px;
    }
  }
  // .sqft {
  //   top: 505px;
  //   @media (max-width: 768px) {
  //     top: 440px;
  //     left: 60px;
  //   }

  //   @media (max-height: 700px) {
  //     top: 200px;
  //   }
  // }
  .sqft {
    top: 470px;
    @media (max-width: 768px) {
      top: 440px;
      left: 60px;
    }

    @media (min-height: 700px) and(max-height: 820px) {
      top: 350px;
    }

    @media (min-height: 581px) and (max-height: 700px) {
      top: 280px;
    }

    @media (max-height: 580px) {
      top: 200px;
    }
  }
  .home_type {
    top: 370px;
    @media (max-width: 768px) {
      top: 390px;
      left: 60px;
    }

    @media (max-height: 700px) {
      top: 200px;
    }
  }
}
