.update-sub-modal {
  position: relative;
  display: flex;
  padding: 30px 42px;
  min-height: 496px;

  @media screen and (min-width: 3200px) {
    padding: 40px 62px;
  }

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .subscription_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  .subscription_container {
    display: flex;
    gap: 32px;

    &-info {
      display: flex;
      flex-direction: column;
      padding-block-start: 65px;

      img {
        margin-block-end: 6px;
        &.premium {
          width: 69px;

          @media screen and (min-width: 3200px) {
            width: 120px;
          }
        }
        &.basic {
          width: 46px;

          @media screen and (min-width: 3200px) {
            width: 100px;
          }
        }
      }

      &-name {
        color: #ffab03;
        font-family: Open Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-block-end: 8px;

        @media screen and (min-width: 3200px) {
          font-size: 38px;
        }
      }

      &-cost {
        color: #2b1f0a;

        /* H5/Bold */
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3; /* 130% */
        margin-block-end: 12px;

        @media screen and (min-width: 3200px) {
          font-size: 30px;
        }
      }

      &-description {
        max-width: 300px;
        color: var(--neutral-400, #9a9a9a);
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25; /* 125% */

        @media screen and (min-width: 3200px) {
          max-width: 450px;
          font-size: 26px;
        }
      }
    }

    &-divider {
      display: flex;
      width: 1px;
      min-height: 100%;
      background: #c6cbcd;
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: 26px;
      max-width: 309px;
      padding-block-start: 22px;
      padding-block-end: 12px;

      @media screen and (min-width: 3200px) {
        max-width: 460px;
      }

      &-title {
        color: #2b1f0a;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3; /* 130% */

        @media screen and (min-width: 3200px) {
          font-size: 30px;
        }
      }
    }
  }
}
