@mixin input_field_style() {
  height: 55px;
  border: 1px solid #6d6d6d;
  color: #6d6d6d;
  font-weight: 300;
  font-size: 18px;
  padding: 0 10px;
  font-family: 'Helvetica';
  border-radius: 0;
}

.feedback_section {
  // padding: 2rem 0;
  margin-top: 35px;

  .heading {
    display: inline-block;
    margin-bottom: 20px;

    @media (max-width: 699px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 699px) {
    padding: 1rem 0;
  }

  &__input_field {
    @include input_field_style();

    input {
      color: #6d6d6d;
    }
  }

  label {
    font-weight: 500;
    // line-height: 23px;
    color: #6d6d6d;
  }

  &__input_text_area {
    @include input_field_style();
    height: 253px;
    resize: none;
    padding-left: 18px;
    padding-top: 1rem;
    outline: none;
  }

  &__submit_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__submit {
    width: 228px;
    height: 64px;
    font-weight: bold;
    font-size: 16px;
    color: #2b1f0a;
  }

  &__container {
    padding: 1.5rem 25px 4rem;
    border-radius: 20px;

    @media (max-width: 699px) {
      padding: 15px 10px;
    }
  }

  &__modal {
    width: 680px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

    &__title {
      font-weight: 300 !important;
      font-size: 22px !important;
      line-height: 26px !important;
      color: #2b1f0a !important;
      max-width: 400px;
      display: inline-block;
      text-align: center;
    }

    &__done_btn {
      width: 168px;
      height: 42px;
      margin: 0 auto;
      margin-top: 1rem;
    }
    @media (max-width: 699px) {
      width: 310px;
      height: 180px;
      padding: 0 1rem;

      &__title {
        font-size: 16px !important;
      }
    }
  }
}
