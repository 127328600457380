@import 'tokens';

.Tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
  &__tag {
    // background: #f4d236;
    border: 1px solid #f6b433;
    border-radius: 5px;
    margin: 0.15rem;
    padding: 0.25rem 0.1rem;
    // width: auto;
    width: 72.6px;
    height: 29px;
    // min-width: 80px;
    padding: 5px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #515a5c;
    margin: 0 auto;
  }
  &__overflow {
    border: 1px solid #f6b433;
    background: #fff7c9;
    width: 17.73px;
    height: 19px;
    margin-left: 3px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 20px !important;
    color: #515a5c;
    margin: 0 auto;
  }
  &__tag {
    display: inline !important;
    width: 60px !important;
    align-items: center;
    justify-content: center;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &__tag,
  &__overflow {
    // border-radius: $border-radius-m;
    border-radius: 5px;
    font-size: $text-size-xs;
    text-align: center;
  }
  &__tooltip {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #f6b433;
    background: $color-white;
    padding: 0.25rem;
    max-height: 10rem;
    overflow-y: auto;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-300;
  }
}
