@import 'src/tokens';

.MuchWow {
  background-color: $color-success-050;
  color: $text-on-success-050--vivid;
  border: $border-width-default solid $color-success;
  border-radius: $border-radius-m;
  padding: 1rem;
  text-align: center;
}
