.confirm-modal {
  max-width: 455px;
  padding: 32px;
  border-radius: 20px;
  background: #fff;

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-title {
    color: #2b1f0a;
    text-align: center;
    font-family: Open Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-body {
    color: #9a9a9a;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
}
