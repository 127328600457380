@import 'tokens.scss';

.AttachedCreatives {
  padding: 1rem;
  // background-color: $color-white;
  &__icon {
    margin-right: 0.5rem;
  }
  &__hl {
    border: 1px solid #e3e4e5;
    height: $pixels-2;
    margin-block: 10px;
    width: 100%;
    background-color: $color-grey-300;
  }

  // .link_input_field:hover {
  //   border: 1px solid #ffab03 !important;
  // }
  // .link_input_field_err:hover {
  //   border: 1px solid #bd0000 !important;
  // }
  .link_input_field:not(:focus):hover {
    border: 1px solid #ffab03 !important;
  }
  .link_input_field:focus {
    border: 1px solid #ffab03;
  }
  .link_input_field {
    padding: 13px 0 13px 100px;
    border: 1px solid #aab2b5;
    border-radius: 10px !important;
    background: none !important;
    width: 450px !important;
    font-size: 17px;

    @media screen and (min-width: 3200px) {
      padding: 25px 0 25px 165px;
      width: 700px !important;
      font-size: 32px !important;
    }

    @media (max-width: 699px) {
      width: 100% !important;
    }
    @media screen and (max-width: 500px) {
      padding-left: 83px !important;
      padding-top: 5px !important;
    }
  }
}
.center-image-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__text {
    border: none;
    background: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 1.25;
    text-decoration-line: underline;
    color: #ffab03;
    font-family: 'Open Sans';
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 3200px) {
      font-size: 40px !important;
      margin-top: 1.75rem;
      margin-bottom: 1rem;
    }
  }
}
.image-box {
  position: relative;
  width: 143px;
  background: #eeeeee;
  border-radius: 6px;

  @media screen and (min-width: 3200px) {
    border-radius: 16px;
    width: 310px;
  }

  &.creative-error {
    border: 2px solid #f00000;
    backdrop-filter: blur(10px);

    .creative-error-filter-container {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.45);
    }
  }

  &__img-1 {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    z-index: 1002;
    height: 14px;

    @media screen and (min-width: 3200px) {
      height: 24px;
    }
  }
  &__img-2 {
    display: block;
    margin: 12px auto 9px;
    height: 132px;
    object-fit: contain;
    width: 86%;
    filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.15));

    @media screen and (min-width: 3200px) {
      height: 300px;
    }
  }
}
.image-box:not(.creative-error):hover {
  background: #eeeeee;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.22);
}

.link_input_field_prefix {
  padding-inline-start: 15px;
  font-size: 17px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 3200px) {
    font-size: 32px;
  }
}
.sizeInput {
  // width: 66% !important;
  height: 50px !important;
  margin-top: 4px;
  border-color: #aab2b5 !important;
}

.creative-error {
  // margin-top: 58px !important;
}
.ImageWithLink {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  gap: 1.4rem;
}
.your_creative_heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #2b1f0a !important;

  @media screen and (min-width: 3200px) {
    font-size: 34px !important;
  }
}
.your_creative_subheading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #6d6d6d !important;

  @media screen and (min-width: 3200px) {
    font-size: 28px !important;
  }
}
