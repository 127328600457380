.ProspectDensity_container {
  background-color: #fff;
  border-radius: 14px;
  padding: 8px 18px;
  width: 229px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;

  .title_container {
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
    }
  }

  .description {
    line-height: 10px;
  }

  .sub_title {
    font-style: italic;
    font-weight: 300;
    font-size: 8px !important;
    line-height: 10px !important;
  }

  .score_status_bar {
    display: flex;
    span {
      flex: 1;
      height: 8px;
    }
    .span_1 {
      background-color: rgba(246, 180, 51, 0.9);
    }
    .span_2 {
      background-color: rgba(246, 180, 51, 0.7);
    }
    .span_3 {
      background-color: rgba(246, 180, 51, 0.5);
    }
    .span_4 {
      background-color: rgba(246, 180, 51, 0.25);
    }
  }

  .score_status {
    display: flex;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 9px !important;
      line-height: 12px;
      flex: 1;
    }
  }
}
