@import 'src/tokens';
@import 'src/mixins';

.DateTargetToggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  &__button {
    padding: 0.25rem 0.5rem;
    background-color: $color-white;
    border: $border-width-default solid $text-on-white;
    border-radius: $border-radius-m;
    text-align: start;
    font-family: inherit;
    cursor: pointer;

    @include focus-ring;
    &--active {
      background-color: $color-primary;
      color: $text-on-primary;
      color: $color-white;
      background-color: #1ba1c5;
    }
  }
  &__label {
    font-size: $text-size-xs;
  }
  &__date {
    font-size: $text-size-m;
    font-weight: $text-weight-bold;
  }
}
