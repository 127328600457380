@import 'src/tokens';

.DateRangePicker {
  display: block;
  width: 30rem;

  @media screen and (min-width: 3200px) {
    width: 45rem;
  }

  @media (max-width: 1600px) {
    width: 28rem;
  }
  @media (max-width: 1500px) {
    width: 24rem;
  }
  @media (max-width: 1400px) {
    width: 20rem;
  }
}
