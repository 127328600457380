@import 'tokens.scss';

.FilterTab {
  padding: 1rem;
  &__filter-selection {
    background: $color-grey-100;
    color: $text-on-grey-100;
    position: relative;
    width: 100%;
    height: 2rem;
    border: 1px solid $color-grey-300;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    &--is-selected {
      box-shadow: $box-shadow-100-focus;
    }
  }
  &__filter-styles {
    height: 100%;
    width: 100%;
  }
  &__angle-input-wrapper {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-grey-100;
    border-radius: 2rem;
    padding: 0.25rem;
  }
  &__angle-button {
    border: 0.125rem solid $color-white;
    background: none;
    cursor: pointer;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
    &:hover {
      background: $color-grey-400;
    }
  }
  &__angle-input {
    font-size: 1rem;
    background: none;
    border: none;
    text-align: center;
    width: 50%;
    color: $text-on-grey-100;
  }
  &__gradient-display {
    border-radius: $border-radius-m;
    width: 100%;
    height: 0.5rem;
  }
  &__disable-container {
    transition: all ease-in 0.2s;
    &--is-disabled {
      pointer-events: none;
      opacity: 0.4;
      filter: saturate(0);
    }
  }
}
