.ownership_position {
  position: relative;
  overflow: hidden;

  .icon {
    position: absolute;
    left: 20px;
    top: 15px;

    svg {
      font-size: 18px;
      color: grey;
      cursor: pointer;

      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
    }
  }
}

.ownership_modal {
  display: flex;
  flex-direction: column;
  width: 505px;
  margin: 0 2rem 2rem 2rem;
  .is_your_listing_or_not {
    text-align: center;
    color: #818181;
    font-size: 14px;
    font-weight: 400;
    .listing_address {
      font-weight: 700;
    }
  }
  .is_your_listing_btns {
    padding-top: 20px;
    .cancel_ownership_btn {
      border: 1px solid #dcdcdc;
      &:hover {
        border: 1px solid #dcdcdc;
        color: #2b1f0a;
        background-color: #fdf6e2;
      }
    }
  }
  @media screen and (min-width: 3200px) {
    width: 700px !important;
  }

  @media (min-width: 1920px) {
    margin: 0 auto;
  }
  @media (max-width: 699px) {
    width: calc(100vw - 10%) !important;
    margin: 1rem 5px;
  }
}
