@import 'src/tokens';
@font-face {
  font-family: Helvetica;
  src: url('../../assets/fonts/HelveticaNeue.ttf');
}
.setPasswordError {
  position: relative;
  display: flex;
  margin-left: 0px;
}
.setPasswordErrorMsg {
  margin: 0;
  font-family: Helvetica;
  margin-left: 0.25rem;
  font-size: $text-size-xs;

  @media screen and (min-width: 3200px) {
    font-size: $text-size-xl;
  }
}

.setPasswordErrorIcon {
  font-size: $text-size-m;

  @media screen and (min-width: 3200px) {
    font-size: $text-size-xxl;
  }
}
