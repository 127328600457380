.search_input_field {
  position: relative;
  width: 255px;
  height: 48px;
  border-radius: 10px;
}
.search_input_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #aab2b5 !important;
}
.close_search_bar {
  width: 5.4%;
  transition: 0.5s;
  border: none;
  margin: 0;

  @media screen and (max-width: 1050px) {
    margin-right: 1rem;
  }
}
.open_search_bar {
  width: 35%;
  transition: 0.5s;
  margin: 0;
}

.hide_search_bar {
  padding: 0;
  margin: 0;
}

.button_before {
  height: inherit;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #aeb7bb;
  padding: 5px 12px;
}

.button_after {
  background-color: white;
  border: none;
}
