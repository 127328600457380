.p-sidebar-right .p-sidebar.creative-details-drawer {
  width: 27.25rem;
}

.p-sidebar.p-component.creative-details-drawer {
  .p-sidebar-header.p-sidebar-custom-header {
    padding-inline: 20px;
    padding-block: 16px;
    border-bottom: 1px solid #e8e8ea;

    .creative-details-drawer-header-text {
      color: #161616;
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.08px;
    }
  }
  .p-sidebar-content {
    padding: 0;

    .creative-info {
      padding-inline: 20px;
      padding-block: 16px;
      border-bottom: 1px solid #e8e8ea;

      .edit-name-action-btn {
        background: transparent;
        width: fit-content;
        height: fit-content;
        border: none;
        outline: none;
        cursor: pointer;
      }

      .creative-thumb-container {
        position: relative;
        width: 100%;
        height: 248px;
        border-radius: 6px;
        overflow: hidden;
        padding-inline: 18px;
        padding-block: 18px;
        background-color: #eeeeee;

        &:has(video) {
          padding: 0;

          .creative-thumb {
            object-fit: cover;
          }

          .creative-thumb-container-filter {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              left: 0;
              width: 100%;
              height: 100%;
              backdrop-filter: blur(8px);
            }
          }
        }

        .creative-thumb-container-filter {
          width: 100%;
          height: 100%;
        }

        .creative-thumb {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }

        .preview-btn {
          padding: 0;
          cursor: pointer;
          position: absolute;
          bottom: 9px;
          right: 11px;

          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.09);

          border: none;
          outline: none;

          &:not(.video) > svg {
            transform: rotate(135deg);
          }

          &.video {
            width: 42px;
            height: 42px;
            bottom: initial;
            right: initial;
            top: 50%;
            left: 50%;
            cursor: initial;
            transform: translate(-50%, -50%);
          }
        }
      }

      .creative-info-section {
        display: flex;
        flex-direction: column;
        gap: 1px;

        &-title,
        &-value {
          font-variant-numeric: lining-nums tabular-nums;
          font-feature-settings: 'clig' off, 'liga' off;

          font-family: Helvetica, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }

        &-title {
          color: #b4b3b3;
          letter-spacing: 0.07px;
        }
        &-value {
          color: #222124;
          letter-spacing: 0.08px;

          &.type {
            text-transform: capitalize;
          }
        }
      }
    }

    .download-creative-btn {
      width: 100%;
      background: #ffffff;
      padding-inline: 20px;
      padding-block: 16px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      border-bottom: 1px solid #e8e8ea;

      > span {
        color: #b4b3b3;
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.08px;
      }
    }
  }
}

.creative-name-input.p-inputtext {
  width: 100%;

  color: #222124;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.08px;

  padding-inline: 12px;
  padding-block: 5px;

  border-radius: 10px;

  @media screen and (min-width: 3200px) {
    font-size: 26px;
  }

  &.p-inputtext,
  &.p-inputnumber .p-inputtext {
    transition: all 0.3s;
    &[readonly] {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: keep-all;
      border: none;
      box-shadow: none;
      border-radius: initial;
      padding: 0;
      user-select: auto !important;
      cursor: default !important;

      &.p-disabled,
      &.p-component:disabled {
        opacity: 1;
      }
    }

    &:not([readonly]):enabled {
      &:not(.p-invalid) {
        &:hover,
        &:active,
        &:focus {
          border-color: #ffab03;
        }
      }

      &:focus {
        box-shadow: 0 0 0 2px rgba(255, 235, 5, 0.1);
      }
    }
  }
}

.p-input-icon-right {
  width: 100%;

  &:has(> .pi.pi-spin.pi-spinner) {
    .creative-name-input.p-inputtext {
      padding-inline-start: 12px;
      padding-inline-end: 40px;
    }
  }
}
