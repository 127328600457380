.ProspectActivitySplash {
  height: 100%;
  width: 100%;
  background: rgba(white, 0.75);
  display: grid;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  &__large-title {
    display: grid;
    grid-template-columns: 3rem 1fr;
    gap: 1rem;
    &:first-of-type {
      align-self: end;
    }
    &:last-of-type {
      align-self: start;
    }
  }
}
