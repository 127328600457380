@import 'src/tokens';
@import 'src/mixins';

.HeaderItem {
  color: $text-on-primary;
  text-decoration: none;
  text-align: start;
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  padding: 20px 16px;
  font-family: inherit;

  @media screen and (min-width: 3200px) {
    font-size: 35px;
  }

  &.disabled {
    color: lightgray;
    cursor: not-allowed;

    .HeaderItem__icon {
      color: inherit;
    }
  }

  @include focus-ring-inset;

  &:not(.disabled):hover {
    background-color: $color-primary-lighter;
    color: $text-on-primary-lighter;
  }

  &__icon {
    margin-right: 0.5rem;
  }

  .HeaderItem__icon {
    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .recommendation_indicator {
      color: #ffab03;
      font-size: 1.75rem;
      position: absolute;
      right: 0;
      top: -0.35rem;
    }
  }
}

.HeaderItem--is-active {
  // background-color: $color-primary-lighter;
  // margin-bottom: 4px black;
  border-bottom: 2px solid #ffab03 !important;
  // margin-bottom: 10px;
  font-weight: 700;
  padding-bottom: 4px;
}
