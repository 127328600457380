.addModalFields {
  margin-bottom: 18px;
}
.mylisting_tableContent {
  padding: 0 1rem;
  margin: 1rem;
  background: white;
  border-radius: 20px;
}
.MyListings_counts {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #6d6d6d;
}
.listView {
  display: flex;
  &__notFound {
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #bd0000;
    &__icon {
      margin-right: 10px;
    }
  }
  &__foundIcon {
    margin-right: 10px;
    // margin-bottom: -2px;
    margin-top: 4px;
  }
}
.MyContacts {
  .empty_status_container {
    padding-block: 40px;
    img {
      height: 90px;

      @media screen and (min-width: 3200px) {
        height: 350px;
      }
    }
  }

  &__PageHeader {
    height: 84px;
    background-color: var(--color-white);

    @media screen and (min-width: 3200px) {
      height: 120px;
    }

    &.upgrade {
      border: 1px solid #fcd917;

      .enriched-badge {
        font-size: 30px;

        @media screen and (min-width: 3200px) {
          font-size: 60px;
        }
      }
    }
    &.pending_contacts {
      height: 80px;
      background-color: var(--color-white);
      padding: 0 1rem;

      @media screen and (min-width: 3200px) {
        height: 100px;
      }
      @media screen and (max-width: 1750px) {
        height: 60px;
      }

      .contact-spinner {
        animation: spinner-border 0.75s linear infinite;
        font-size: 24px;

        @media screen and (min-width: 3200px) {
          font-size: 45px;
        }
      }
    }
  }
  margin-block-end: 2.5rem;
  button {
    @media screen and (max-width: 699px) {
      max-width: 130px !important;
      font-size: 13px !important;
      padding-left: 6px;
    }
  }

  .Button {
    &.sync-email-accounts {
      .Button__icon-wrapper {
        svg {
          font-size: 20px;
          @media screen and (min-width: 3200px) {
            font-size: 28px;
          }
        }
      }
    }
    &.upload-contacts {
      .Button__icon-wrapper {
        svg {
          font-size: 20px;
          @media screen and (min-width: 3200px) {
            font-size: 28px;
          }
        }
      }
    }
  }
}

.enrich-text {
  color: #6f6f6f;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (min-width: 3200px) {
    font-size: 28px;
  }

  b {
    color: #4e4e4e;
    font-weight: 800;
  }
}

.upgrade-button {
  border-radius: 10px;
  border: 1px solid #007b94;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007b94;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s;

  &:not(:disabled):hover {
    transition: all 0.3s;
    color: #fff;
    border-color: #007b94;
    background: #007b94;
  }

  @media screen and (min-width: 3200px) {
    font-size: 28px;
  }
}

.processing-title.Text {
  color: #3a4861 !important;
  font-style: normal;
  font-weight: 400;
}

.processing-description.Text {
  color: #c1c1c1 !important;
  font-style: normal;
  font-weight: 400;
}

.enrich-tag {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #fcd917;
  color: var(--Light-400, #6d6d6d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.my-contacts-header-title {
  margin-bottom: 4px !important;
  // padding-top: 6px;
}
.My-Contacts__header {
  padding: 0.9rem !important;
}
.my-contacts-header-subtitle {
  color: #bd0000 !important;
}
.my-contacts-header-subtitle-op {
  padding-top: 3px;
  margin-left: 51px;
}

.sync-contacts-button {
  margin-left: 20px !important;
}
.contact_delete {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #ffffff !important;
}

.icon-header-contacts-sync {
  margin-top: 0px !important;
}

.contacts-btn {
  background-color: transparent !important;
}
.contacts-btn:hover {
  background-color: transparent !important;
}

.sync-table {
  height: calc(100vh - 139px) !important;
}

@media (max-width: 768px) {
  .sync-table {
    height: calc(100vh - 130px) !important;
  }
}
.syncontact_button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #2b1f0a;
  background: #d1d1d1;
  border: 1px solid #c7c9cb;
  border-radius: 10px;
  padding: 10px 20px;
}
