@import 'src/tokens';

.Header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  color: $text-on-primary;
  position: relative;
  z-index: 1;
  box-shadow: $box-shadow-200;
  min-height: 55px;
  // padding-block: 4px;
  // border-bottom: 4px solid #ffab03;
  @media screen and (min-width: 601px) {
    display: none;
  }
}
.HeaderContainer {
  filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.1));
  &__BottomBorder {
    background: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
    height: 4px;

    @media screen and (min-width: 601px) {
      display: none;
    }
    // position: absolute;
    // // background: linear-gradient(blue 50%, red 0);
    // border-radius: 50%;
    // width: 70%;
    // overflow: hidden;
    // animation: cowmove 2s linear infinite;
  }
}
.HeaderContainer > hr {
  color: linear-gradient(90deg, #fcd917 23.4%, #ffab03 75.9%);
}

// div {
//   width: 80px;
//   height: 80px;
//   background: linear-gradient(blue 50%, red 0);
//   border-radius: 50%;
//   position: absolute;
//   top: 50px;
//   left: 0px;
//   animation: cowmove 2s linear infinite;
// }

// @keyframes cowmove {
//   0% {
//     transform: translateX(-8px) rotate(0);
//   }
//   30% {
//     transform: translateX(300px) rotate(0);
//   }
//   50% {
//     transform: translateX(400px) rotate(0);
//   }
//   80% {
//     transform: translateX(100px);
//   }
//   100% {
//     transform: translateX(-8px) rotate(0);
//   }
// }
