.before-spinner {
  color: #fcd937;
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;

  &:not(.button_loading) {
    @media screen and (min-width: 3200px) {
      width: 10rem;
      height: 10rem;
      border-width: 0.5em;
    }
  }

  &.button_loading {
    position: unset;
    color: black;
    width: 25px;
    height: 25px;
    margin-inline-start: 1rem;
    opacity: 1;
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
