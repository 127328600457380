@import 'src/tokens';
@import 'src/mixins';

.CalendarInputMonth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__icon {
    display: block;
  }
  &__year_heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }
  }
  &__button {
    background-color: $color-white;
    color: $text-on-white;
    border: none;
    font-size: 20px;
    font-weight: 800;
    padding: 0;
    margin: 0 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    outline: none;
    cursor: pointer;

    @include focus-ring;
    &:hover {
      background-color: $color-primary;
      color: $text-on-primary;
    }
  }
}
