.uploadCSVModal {
  height: auto;
  max-height: 872px;
  gap: 1.5rem;

  .p-divider.p-component.p-divider-horizontal.p-divider-solid.p-divider-left {
    margin: 0;
  }

  .UploadBannerModal__footer {
    padding: 0;
  }

  .p-panel.p-component.p-panel-toggleable {
    width: 100%;
    .p-panel-header {
      border: none;
      background: transparent;
      border-radius: 0;
      padding: 0;
      .p-panel-icons {
        .p-panel-header-icon.p-panel-toggler.p-link {
          width: auto;
          height: auto;
        }
        button {
          border-radius: 0;
        }
        button:hover {
          background: transparent;
        }
      }
    }

    .p-toggleable-content {
      .p-panel-content {
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0;
      }
    }
  }
  ol.csv-format-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-inline-start: 20px;

    li {
      color: #6d6d6d;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 158.7%;
    }

    ol.csv-format-details-subs {
      padding-inline-start: 20px;

      li {
        color: #c7c7c7;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 158.7%;
      }
    }
  }
  &__points {
    font-size: 14px;
    font-weight: 400;
    max-width: 600px;
    border-left: 4px solid #fcd917;
    line-height: 25px;
    color: #2b1f0a;
    font-family: sans-serif;
  }
  &__points > ol {
    @media (max-width: 699px) {
      padding-left: 1.5rem;
    }
  }

  &__points > ol > li {
    padding: 5px 0;
  }
  &__points > ol > li > i {
    color: #aab2b5;
  }
  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
  }
  &__example {
    border: 1px solid #1ba1c5;
    color: #1ba1c5;

    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__example:hover {
    background: none;
    border: 1px solid #1ba1c5;
    color: #1ba1c5;
    // width: 20%;
  }
  .text_area_container {
    padding: 11px 15px;
    border: 1px solid #c6cbcd;
  }
  .text_area {
    height: 136px;
    width: 100%;
    resize: none;
    border-color: #c6cbcd;
    outline: none;
  }

  .example_img_container {
    @media (max-width: 699px) {
      img {
        width: 100%;
        height: 140px !important;
      }
    }
  }
}
