@import 'tokens.scss';

.LensSidebar {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-radius: 18px;

  div.opened-side-bar {
    display: none;
  }
}

.ProspectLensGridBar {
  overflow: auto;
  max-height: calc(100% - (125px + 16px + 50px));
  position: absolute;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  height: auto;
  box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 999;
  margin: 1rem 2rem;
  border-radius: 12px;
  width: 435px;
  padding: 1.25rem 2rem;

  @media screen and (min-width: 3200px) {
    max-height: calc(100% - (230px + 16px + 50px));
    border-radius: 12px;
    width: 700px;
    padding: 36px 40px;
  }
}
.ProspectLensGridBarCenter {
  position: absolute;
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 999;
  border-radius: 12px;
  min-width: 400px;
  max-width: 600px;
  margin-left: 35%;
}
.run {
  color: green;
}
.close {
  color: red;
}
