@import 'tokens.scss';
.LensSidebarHeader__back-button {
  color: #ffab03;
  font-weight: bold !important;
}
.LensSidebarHeader {
  margin-block-end: 22px;
  &__back-button {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    border-radius: $border-radius-m;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 16px;

    &:hover {
      color: #ffd154;
    }

    @media screen and (min-width: 3200px) {
      font-size: 30px;
    }
  }
  &__collapse {
    float: right;
    margin-top: 1rem;
    margin-right: 1.5rem;
  }
  &__divider {
    background-color: $color-grey-200;
    height: $pixels-2;
    margin-bottom: 0.5rem;
  }
  &__heading {
    margin: 0 0 9px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.125;
    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 36px;
    }

    @media (max-width: 1050px) {
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    &__sub {
      font-size: $text-size-xl;
      margin-block: 4px !important;
    }
  }
  &__Inactive {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: #000;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__sub-heading {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }

    @media (max-width: 1050px) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 0;
    }

    &__sub {
      margin-block: 8px;
    }
  }
}
