.Splash {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__logo {
    height: 40vh;
    width: 40vw;
    min-height: 300px;
    min-width: 300px;
    max-width: 500px;
    max-height: 500px;
    padding: 1rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
