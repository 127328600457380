@import 'tokens';

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  width: 100%;
  margin-top: 7%;
  &__dot {
    display: inline-block;
    background-color: $color-primary-400;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    transform: translate3d(0);
    animation: bounce 0.6s infinite alternate;

    @media screen and (min-width: 3200px) {
      height: 3rem;
      width: 3rem;
      margin: 0 1rem;
    }
  }
  &__dot:nth-child(2) {
    background-color: $color-primary-300;
    animation-delay: 0.2s;
  }
  &__dot:nth-child(3) {
    background-color: $color-primary-200;
    animation-delay: 0.4s;
  }
}

@keyframes bounce {
  to {
    transform: translate3d(0, -16px, 0);
  }
}

// .before-spinner {
//   color: #fcd937;
//   position: fixed;
//   z-index: 999;
//   margin: auto;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   display: inline-block;
//   width: 2rem;
//   height: 2rem;
//   vertical-align: text-bottom;
//   border: 0.25em solid currentColor;
//   border-right-color: transparent;
//   border-radius: 50%;
//   animation: spinner-border 0.75s linear infinite;
// }
// @keyframes spinner-border {
//   to {
//     transform: rotate(360deg);
//   }
// }
