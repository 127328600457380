@import 'tokens.scss';

.GrowYourSphere {
  display: flex;
  flex-grow: 1;

  .grow-your-sphere-map-rendering-info {
    position: absolute;
    right: 0;
    margin: 1rem 2rem;
    padding: 10px 12px;
    z-index: 999;
    border-radius: 10px;

    display: flex;
    align-items: center;
    gap: 11px;
    max-width: 227px;
    background: #ffffff;

    > .description {
      color: #000;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &_sidebar.ProspectLensGridBar {
    height: auto;
    overflow: hidden;
    max-height: calc(100% - 32px);

    .root_content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    .SaveAudienceWizard__btn {
      margin: 0;
      width: 100%;
    }

    .MaxHeightContainer.MaxHeightContainer--full-height {
      overflow: hidden;
      .MaxHeightContainer__content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
  }

  &__sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    padding-block: 22px;
    border-block-start: 0.5px solid rgba(88, 88, 88, 0.5);

    .Loading {
      min-height: initial;
    }

    .select-all-contacts-zips-checkbox {
      background: transparent;
      padding: 0;
      outline: none;
      cursor: pointer;

      $size: 1.25rem;
      flex: 0 0 $size;
      max-width: $size;
      min-width: $size;
      height: $size;
      width: $size;
      color: $text-on-grey-100;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $text-size-xs;
      border-radius: 3px;
      border: 1px solid #dddddd;

      @media screen and (min-width: 3200px) {
        height: 2rem;
        width: 2rem;
      }

      &.selected {
        border-color: #ffab03;
        color: #ffab03;
        font-weight: bold;
      }

      &:hover {
        border-color: #f5c537;
        color: #f5c537;
      }

      &[disabled] {
        border-color: #d9d9d9 !important;
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }

    .select-title {
      color: #9e9e9e;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
  &__rightTop {
    position: absolute;
    z-index: 999;
    top: 16px;
    right: 30px;
    width: 182px;
    border-radius: 12px;
    height: 45px;
    text-align: center;
  }
  &__rightTopButton {
    border: 2px solid #ffb708;
    height: 30px;
    margin: 0 10px;
    border-radius: 10px;
    font-size: 13px;
    color: black;
    font-weight: 600;
    letter-spacing: 0.8px;

    .Button__text {
      color: black;
    }

    &:not(:disabled):hover {
      border: 2px solid #ffb708;

      .Button__text {
        color: black;
      }
    }

    @media screen and (min-width: 3200px) {
      height: 55px;
      font-size: 29px;
      .Button__text {
        padding: 0 3rem;
      }
    }

    @media screen and (max-width: 1050px) {
      margin: 0 2px;
    }
  }
}
