@import 'src/tokens';
@import 'src/mixins';

.BuzzScoreFilters {
  // display: flex;
  // padding-top: 2rem;
  // position: relative;
  &__Header {
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 1.25;
      margin-bottom: 12px;

      display: flex;
      align-items: center;

      color: #000000;

      @media screen and (min-width: 3200px) {
        font-size: 36px !important;
        margin-bottom: 25px;
      }
    }
  }

  &__filters {
    justify-content: space-between;

    .Checkbox {
      margin-block-end: 5px;

      @media screen and (min-width: 3200px) {
        margin-block-end: 15px;
      }
    }

    .Checkbox__wrapper-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 1.25;
      display: flex;
      align-items: center;
      padding: 0;
      color: #000000;

      @media screen and (min-width: 3200px) {
        font-size: 35px;
      }

      .Checkbox__input-wrapper {
        margin-right: 14px;

        @media screen and (min-width: 3200px) {
          margin-right: 24px;
        }
      }
    }
  }
  &__labels {
    justify-content: space-between;
    margin-top: -0.7rem;
  }
  &__labels > p {
    font-size: 16px;
  }
  &__filter {
    margin-top: 0.5rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__button {
    @include focus-ring;
    outline: none;
    height: 1.5rem;
    width: 4.5rem;
    border-radius: 10px;
    cursor: pointer;
    border-width: $pixels-4;
    border-style: solid;
    text-decoration: none;
    background-color: $color-white;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__check {
    opacity: 0;
  }
}

.BuzzScoreFilters__filter--is-active {
  .BuzzScoreFilters__check {
    opacity: 1;
  }
}
