.MyListingsLens {
  display: grid;
  flex-grow: 1;
  &__FooterBtn {
    background-color: #fcd917;
    &:hover {
      background-color: #fcd917;
    }
  }
  &__sidebar-content {
    padding: 0.4rem 0;
  }
  &__Height {
    min-height: 250px;
  }
  &__MyListingsLens__NoList {
    max-height: fit-content;
  }
  &__button-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  &__rightTop {
    position: absolute;
    z-index: 999;
    top: 30px;
    right: 30px;
    width: 250px;
    background-color: #fff;
    border-radius: 12px;
    // width: 300px;
    height: 45px;
    // align-items: center;
    text-align: center;
    padding-top: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  &__filter {
    position: absolute;
    z-index: 999;
    top: 55px;
    right: 40px;

    @media screen and (min-width: 3200px) {
      top: 90px;
    }
  }
  &__audience {
    background-color: #fff;
    border: 2px solid #fcd917;
    background: linear-gradient(180deg, #fcd917 0%, #ffab03 100%);
    border-radius: 1px;
    margin: 1rem;
    // border-left: 39px solid #FFAB03;
    padding-left: 40px;
    width: 400px;
    border-radius: 12px;
    position: absolute;
    z-index: 999;
    bottom: calc(100vh - 98vh);
    left: 10px;
    // padding-left: 30px;
  }
  &__audienceCenter {
    background-color: #fff;
    border: 2px solid #fcd917;
    background: linear-gradient(180deg, #fcd917 0%, #ffab03 100%);
    border-radius: 1px;
    // border-left: 39px solid #FFAB03;
    padding-left: 40px;
    width: 400px;
    border-radius: 12px;
    position: absolute;
    z-index: 999;
    bottom: calc(100vh - 97vh);
    left: 30px;
    margin-left: 40%;
    // padding-left: 30px;

    @media (max-width: 1050px) {
      margin-left: 0;
      // left: 15px;
      padding-left: 25px;
      // width: 400px;
    }
  }

  &__border_radius {
    background-color: #fff;
    border-radius: 0 10px 10px 0;
  }
  &__score {
    display: flex;
    align-items: center;
    margin-left: 2rem;
  }
  &__scoreLeft {
    // width: 40px;
    margin: 0 0.5rem;
  }
  &__scoreLeft > p {
    font-size: 30px;
    margin: 0;

    @media (max-width: 1050px) {
      font-size: 26px;
    }
  }
  &__scoreRight {
    text-align: left;

    @media (max-width: 1050px) {
      font-size: 14px;
    }

    .around-listing-score {
      @media screen and (min-width: 3200px) {
        font-size: 24px;
      }
    }
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
      width: fit-content;
    }
  }

  &__hide {
    display: none;
  }
}

.buzzScoreImg {
  &__container {
    position: absolute;
    z-index: 401;
    // bottom: 20vh;
    // top: 2vh;
    margin-top: 3vh;
    right: -3px;
    // width: 220px;

    @media screen and (min-height: 750px) {
      margin-top: calc(100vh - 543px);
      margin-right: 2rem;
    }

    @media (min-width: 1650px) {
      // right: 30px;
    }
  }
  &__containerInside {
    @media (max-width: 1600px) {
      width: 220px;
    }
    @media (min-width: 1650px) {
      width: 280px;
    }
  }
}
