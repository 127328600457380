.ImageWithLink {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  gap: 1.4rem;
}

.creative-content {
  position: relative;

  .creative-video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}

.digital_banner_title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 1.21 !important;

  color: #6d6d6d !important;

  @media screen and (min-width: 3200px) {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 24px !important;
  }
}
