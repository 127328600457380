.BuzzScore_container {
  background-color: #fff;
  border-radius: 14px;
  padding: 8px 18px;
  width: 296px;
  height: 89px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;

  @media screen and (max-width: 1050px) {
    padding: 8px 10px;
    width: 280px;
  }

  .title_container {
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
    }
  }

  .description {
    line-height: 10px;
  }

  .sub_title {
    font-style: italic;
    font-weight: 300;
    font-size: 8px !important;
    line-height: 10px !important;
  }
  .nested_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 10px;
    .star_container {
      .star_icon {
        font-size: 12px;
      }
      span {
        font-size: 9px !important;
        line-height: 10px;
      }
    }
  }
  .status_bar_container {
    width: 200px;
  }
  .score_status_bar {
    display: flex;
    span {
      flex: 1;
      height: 8px;
    }
    .span_1 {
      background-color: #ff0000;
    }
    .span_2 {
      background-color: #f66233;
    }
    .span_3 {
      background-color: #f6b433;
    }
    .span_4 {
      background-color: #fcea44;
    }
  }

  .score_status {
    display: flex;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 9px !important;
      line-height: 12px;
      flex: 1;
    }
  }
}
