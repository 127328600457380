.zips-loading-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  > div > .zip-loading-indicator {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    max-height: 400px;
    backdrop-filter: blur(4px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .zip-loading-indicator-text {
      color: #8b8b8b;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .zips-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: opacity 0.3s;

    &.zips-content-blur {
      clear: both;
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
    }

    .zips-content-titles {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #2b1f0a;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.not-found-zips {
  color: #9a9a9a;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
