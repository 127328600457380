.side_nav_bar_container {
  .shadow_container {
    border: 5px solid;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: black;
    opacity: 0.2;
    max-width: 67%;
  }

  .icon_container {
    padding: 1rem;

    @media screen and (max-width: 699px) {
      border-bottom: 1px solid #eee;
    }
  }

  .cross_icon {
    transform: rotate(45deg);
    color: #000;
    font-size: 22px;
    cursor: pointer;
  }

  .side_nav_bar_nested_container {
    position: fixed;
    background: #fff;
    right: 0;
    top: 0;
    min-width: 33%;
    max-width: 33%;
    z-index: 2;
    bottom: 0;
    overflow: auto;

    .notification_list_item {
      position: relative;

      .dot {
        right: 50px;
        top: 20px;
      }
    }

    &__item_className {
      display: flex !important;
      align-items: center !important;
    }

    .drop_down_list {
      padding: 20px 16px;
      border: 1px solid #eee;
      width: 100%;
      font-size: 16px;
    }

    .Collapsible_title {
      padding: 20px 16px;
      display: inline-block;
      display: flex;
    }

    .unorder_list {
      list-style: none;
      margin: 0;
      padding: 0;

      button {
        margin: 20px 0;
      }

      .HeaderItem {
        width: 100%;
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .list {
        // padding: 20px 0;
        border: 1px solid #eee;
        width: 100%;

        &:hover {
          background-color: var(--color-primary-lighter);
          cursor: pointer;
        }
      }

      .hide_on_mobile {
        @media screen and (max-width: 699px) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 699px) {
      min-width: 60%;
      max-width: 60%;
    }
  }
}
.hide {
  display: none;
}
