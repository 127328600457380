.PersonalInfoForm {
  display: flex;
  padding: 2rem 1rem;
  background-image: url('../../assets/Images/Maskgroup.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 50%;
  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    width: 20px;
    cursor: pointer;

    @media screen and (min-width: 3200px) {
      width: 40px;
    }
  }
  @media (max-width: 1400px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-top: 3rem;
  }

  @media (max-width: 699px) {
    padding-top: 2rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 310px;
  }

  .inputs-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 2rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .input-wrapper {
      min-height: 90px;
    }

    .label-input-field-container {
      min-width: 336px;
    }
  }

  div:has(> .PersonalInfoForm__submit) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__header {
    max-width: 350px;
    padding: 3rem 0 22rem;

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 40px !important;
      line-height: 54px !important;
      display: flex !important;
      align-items: center !important;
      color: #ffab03 !important;
      margin-bottom: 1rem;

      @media screen and (min-width: 3200px) {
        font-size: 50px !important;
      }

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }
    .sub_heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 1.35 !important;
      color: #818181 !important;

      @media screen and (min-width: 3200px) {
        font-size: 32px !important;
      }
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 2rem;
    border-right: 1px solid #c6cbcd;

    @media (max-width: 1400px) {
      padding: 0 2rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }
  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }
  &__progress {
    margin: 1rem 0;
  }
  &__inputs {
    display: flex;
    padding: 0 6rem 0 3rem;

    @media (max-width: 1050px) {
      width: 100%;
      border-left: none;
      border-top: none;
    }
  }
  &__submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .required-text {
      color: #2b1f0a;
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px !important;

      @media screen and (min-width: 3200px) {
        font-size: 24px !important;
      }
    }
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-title {
      color: #111827;

      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
