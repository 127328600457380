.creatives-confirmation-modal {
  position: relative;
  padding: 32px 80px;
  width: 836px;

  &-title {
    color: var(--Neutrals-950---main-text-color, #262626);
    text-align: center;

    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    max-width: 440px;
  }

  &-description {
    color: var(--Neutrals-500---secondary-text-color, #757575);
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .requirement-wrapper {
    width: 174px;
    img {
      width: 174px;
      height: 104px;
    }
  }

  .requirements-title {
    color: var(--Neutrals-500---secondary-text-color, #757575);

    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
}