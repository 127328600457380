.Modal__content.campaign-example-modal {
  position: relative;
  width: 999px;
  height: 840px;
  max-height: calc(-5rem + 100vh);
  border-radius: 12px;

  .Modal__scrollable-content {
    display: flex;
    height: 100%;

    .campaign-modal-sider, .campaign-modal-content {
      height: 100%;
    }
    .campaign-modal-sider {
      flex: 0 0 239px;
      max-width: 239px;
      min-width: 239px;
      width: 239px;
      padding: 32px 28px 0 32px;
      border-radius: 12px 0 0 12px;

      background: var(--Neutrals-50, #F6F6F6);

      .lens-item {
        border: none;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        width: fit-content;
        padding: 12px 0;
        background: transparent;
        transition: all .2s;

        &-title {
          color: var(--Neutrals-950---main-text-color, #262626);
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          transition: all .2s;
        }

        &:hover {
          border-bottom: 2px solid #FFAB03;
        }

        &.lens-item-selected {
          border-bottom: 2px solid #FFAB03;

          .lens-item-title {
            font-weight: 700;
          }
        }
      }
    }

    .campaign-modal-content {
      flex: auto;
      padding: 24px 32px;
      overflow: hidden;

      > .Box.Box--display-flex {
        height: 100%;

        .campaign-modal-content-inner {
          height: 100%;
          overflow: auto;
          margin-block-end: 1rem;
        }

        .lens-img {
          width: 24px;
          height: 24px;
        }

        .lens-title {
          color: var(--Neutrals-950---main-text-color, #262626);
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5;
        }

        .lens-description {
          color: #2B1F0A;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .creatives-container {
          display: grid;
          grid-template-columns: repeat(3, 0fr);
          grid-template-rows: auto;
          gap: 1.5rem;
          background-color: white;
        }

        .grid-column {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
        }

        .grid-vertical {
          display: flex;
          gap: 1rem;
          justify-content: flex-end;
        }

        .creative-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #F6F6F6;
          color: #333;
          font-size: 16px;
          height: fit-content;

          .creative-container {
            img {
              width: 100%;
              height: 100%;
            }
          }

          .creative-dimensions {
            display: flex;
            align-items: center;
            width: 100%;
            height: 20px;
            padding: 0 6px;
            color: #000;
            font-family: "Open Sans", sans-serif;
            font-size: 8.898px;
            font-style: normal;
            font-weight: 400;
            line-height: 12.605px;
          }
        }

        .item-728-90 {
          grid-column: span 3;
          width: calc(728px / 1.45);
          .creative-container {
            width: calc(728px / 1.45);
            height: calc(90px / 1.45);
          }
        }

        .item-300-250 {
          width: calc(300px / 1.45);
          .creative-container {
            width: calc(300px / 1.45);
            height: calc(250px / 1.45);
          }
        }
        .item-320-100 {
          width: calc(320px / 1.45);
          .creative-container {
            width: calc(320px / 1.45);
            height: calc(100px / 1.45);
          }
        }

        .item-160-600 {
          width: calc(160px / 1.45);
          .creative-container {
            width: calc(160px / 1.45);
            height: calc(600px / 1.45);
          }
        }

        .item-300-600 {
          width: calc(300px / 1.45);
          .creative-container {
            width: calc(300px / 1.45);
            height: calc(600px / 1.45);
          }
        }

        .item-320-50 {
          width: calc(320px / 1.45);
          .creative-container {
            width: calc(320px / 1.45);
            height: calc(50px / 1.45);
          }
        }

        > .Button {
          align-self: center;
        }
      }
    }
  }
}