.privacy_policy_container {
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 0 3rem;

  @media (max-width: 699px) {
    max-width: 90%;
    padding: 1rem 0 2rem;
  }

  .bold {
    font-weight: bold;
  }

  .divider {
    border: 0.5px solid #c6cbcd;
    width: 100%;
  }

  .button_group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 699px) {
      justify-content: center;
    }
  }

  ul {
    list-style: none;

    li {
      margin-top: 1rem;
    }

    li::before {
      content: '\2022';
      color: #fcd917;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font-size: 25px;
    }

    span {
      font-weight: bold;
    }
  }

  .heading {
    font-size: 26px !important;

    @media (max-width: 699px) {
      font-size: 22px !important;
    }
  }

  .Text {
    display: block !important;
  }

  .title {
    margin: 2.5rem 0 1.2rem;
    font-size: 20px !important;

    @media (max-width: 699px) {
      margin: 1.5rem 0 0.5rem;
      font-size: 16px !important;
    }
  }

  .description {
    line-height: 1.8rem;
    font-size: 20px !important;

    @media (max-width: 699px) {
      line-height: 1.2rem;
      font-size: 14px !important;
    }
  }

  .img_top {
    top: 0px;
    right: 2px;
    margin-top: 0px !important;
  }
  .img_bottom {
    bottom: 0px;
    left: 2px;
  }

  img {
    position: fixed;
    height: 420px;
    z-index: -1;

    // @media (max-width: 699px) {
    //   display: none;
    // }
  }
}
