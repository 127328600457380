.grid_box {
  margin-right: 1rem;
  width: 100%;
  @media (max-width: 699px) {
    margin-right: 0;
  }
}

.CampaignView {
  .divider {
    border: 1px solid #dedede !important;
    width: 100%;
  }
  .campaign_details_progressbar {
    .ProgressIndicator__meter {
      width: 100%;
    }
  }
  .CompactSavedAudiences {
    max-height: 100%;
    padding: 5px;
    &__audience-container {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 41, 0.2);
      border-radius: 6px;
      border: 0px solid transparent;
    }
    &__border {
      border: 0px solid transparent;
    }
  }
  &__top_section {
    border-radius: 20px;
    padding: 31px 33px;
  }

  &__report {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    .report_card {
      width: 30%;
      padding-block: 20px;
      box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
      border-radius: 27px;
      display: flex;
      align-items: center;

      @media screen and (min-width: 3200px) {
        padding-block: 40px;
      }
    }
    .img_div {
      flex-grow: 1;
      text-align: center;

      img {
        height: 97px;
        @media screen and (min-width: 3200px) {
          height: 170px;
        }
      }
    }
    .title_div {
      display: flex;
      flex-direction: column;
      flex: 4;
    }

    .title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 50px !important;
      line-height: 1.3 !important;
      color: #2b1f0a !important;

      @media screen and (min-width: 3200px) {
        font-size: 80px !important;
      }
    }
    .sub_title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300 !important;
      font-size: 24px !important;
      line-height: 1.5 !important;
      color: #2b1f0a !important;

      @media screen and (min-width: 3200px) {
        font-size: 44px !important;
      }
    }

    .report_larg_card {
      width: 47%;
      box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
      border-radius: 27px;
      display: flex;
      align-items: center;
      padding-block: 2rem;
      padding-inline: 2rem;

      @media screen and (min-width: 3200px) {
        padding-inline: 3rem;
        padding-block: 4rem;
      }

      .nested_div {
        min-height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;
        flex-grow: 2;
      }

      .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 30px !important;
        line-height: 1.3 !important;
        color: #2b1f0a !important;

        @media screen and (min-width: 3200px) {
          font-size: 40px !important;
        }
      }
      .sub_title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300 !important;
        font-size: 18px !important;
        line-height: 1.3 !important;
        color: #2b1f0a !important;

        @media screen and (min-width: 3200px) {
          font-size: 28px !important;
        }
      }

      .detail1,
      .detail2 {
        font-weight: 300 !important;
        font-size: 12px !important;
        color: #2b1f0a !important;
        line-height: 1.5 !important;

        @media screen and (min-width: 3200px) {
          font-size: 24px !important;
        }
      }

      .detail_div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > span {
          display: flex;
          align-items: center;
        }
      }
    }
    .card_dot {
      background-color: #fcd917;
      height: 14px;
      width: 14px;
      margin-right: 8px;
      border-radius: 50%;

      @media screen and (min-width: 3200px) {
        margin-right: 16px;
        height: 34px;
        width: 34px;
      }
    }

    .report_pie_chart {
      width: 155px;

      @media screen and (min-width: 3200px) {
        width: 300px;
      }
    }
  }

  &__creatives_container {
    display: flex;
    box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
    border-radius: 27px;
    width: 100%;
    padding: 26px;

    .creative_img {
      margin-right: 20px;
      border-radius: 6px;
      height: 156px;
      width: 178px;

      @media screen and (min-width: 3200px) {
        height: 300px;
        width: 330px;
      }
    }

    img.creative_img {
      object-fit: contain;
    }

    .right_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
    }

    .card_title {
      font-weight: 700 !important;
      font-size: 30px !important;
      line-height: 1.2 !important;
      color: #2b1f0a !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 3200px) {
        font-size: 40px !important;
      }
    }

    .sub_title {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
      color: #2b1f0a !important;

      @media screen and (min-width: 3200px) {
        font-size: 26px !important;
      }
    }

    .small_imgs {
      margin-right: 12px;
      width: 35px;

      @media screen and (min-width: 3200px) {
        width: 75px;
        margin-right: 25px;
      }
    }

    .des {
      font-weight: 400 !important;
      font-size: 18px !important;
      color: #5d5444 !important;

      @media screen and (min-width: 3200px) {
        font-size: 28px !important;
      }
    }
  }

  .CampaignView__all_campaign_creatives {
    border-radius: 10px;
    padding: 10px;
    width: 45%;
    margin: 10px;

    .creative_img {
      width: 115px;
      height: 118px;
      border-radius: 5px;
      object-fit: contain;

      @media screen and (min-width: 3200px) {
        width: 230px;
        height: 200px;
      }
    }

    .card_title {
      font-size: 18px !important;
      line-height: 1.5 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 3200px) {
        font-size: 30px !important;
      }
    }

    .sub_title {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    .des {
      font-size: 10px !important;

      @media screen and (min-width: 3200px) {
        font-size: 24px !important;
      }
    }
  }

  .text-campaign-details {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    text-transform: uppercase;
    color: #6d797c !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  .text-campaign-details-value {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }

  .flight_date {
    font-weight: 600 !important;
    font-size: 16px !important;
    // margin-left: -69px!important;
    line-height: 22px !important;
    text-transform: uppercase !important;
    color: #313131 !important;
  }
  .flight-shedule {
    @media (max-width: 1400px) {
      width: 85% !important;
    }
    @media (max-width: 1350px) {
      width: 100% !important;
    }
  }
  .campaign-title-audience-heading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 26px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 36px !important;
    }
  }
  .creative_value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
  .flight-date-time {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 2 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 22px !important;
    }
  }
  .DateRangePicker {
    // width: 280px !important;
    margin-top: 20px;
  }
  .date-campaign-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // margin-left: -34px!important;
  }
}
.creative_right_column {
  min-height: 313px;
  width: 46%;
  @media (max-width: 1400px) {
    margin-left: 20px;
    width: auto;
  }
}
