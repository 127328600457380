@import 'src/tokens';
.largicon_paragraph {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 1.75 !important;

  color: #7e7e7e !important;

  @media screen and (min-width: 3200px) {
    font-size: 26px !important;
  }
}
.LargeIconButton {
  position: relative;
  width: 352px;
  height: 240px;
  cursor: pointer;
  text-decoration: none;
  background: white;
  color: #2b1f0a;
  text-align: left;
  padding: 1.2rem 1rem;
  display: flex;
  border: 0;
  font-family: 'Open Sans';
  transition: $transition-150;
  border-radius: $border-radius-xl;
  font-size: $text-size-m;

  @media screen and (min-width: 3200px) {
    width: 750px;
    height: auto;
    padding: 2.2rem 2rem;
  }

  @media screen and (max-width: 1050px) {
    width: 290px;
    height: 200px;
  }

  &__bottom-text {
    color: #007b94;
    font-weight: 700;
    width: 120px;
  }
  &__title-text {
    margin-top: 0.6rem;

    @media (max-width: 1050px) {
      margin-top: 0.7rem;
      width: calc(100% - 5%);
    }
  }
  &__title-text > p {
    font-size: 16px;
    color: #2b1f0a;
    font-weight: 300;
    line-height: 24px;
  }
  &__bottom-text-Parent {
    position: absolute;
    bottom: 1.2rem;

    @media (max-width: 1050px) {
      bottom: 1.5rem;
    }
  }

  &__icon {
    padding-right: 1rem;
    @media screen and (min-width: 3200px) {
      padding-right: 2rem;
    }
  }
  &__title {
    margin: 1rem 0;
    font-size: $text-size-l;
    font-weight: 600;
    color: black;

    @media screen and (min-width: 3200px) {
      font-size: 2rem;
    }
  }
  &__right-text {
    margin-left: auto;
  }
  &__link {
    text-decoration: none;
  }
  &:disabled {
    background: $color-grey-050;
    color: $text-on-grey-050--subdued;
    border-color: $text-on-grey-050--subdued;
    cursor: not-allowed;
    &:hover {
      background: $color-grey-050;
      color: $text-on-grey-050--subdued;
      border-color: $text-on-grey-050--subdued;
      box-shadow: none;
    }
  }
  &:hover {
    background: #eff7f9;
    color: $text-on-primary;
    border-color: $text-on-primary;
    box-shadow: rgba(0, 0, 0, 0.31) 6px 14px 33px;
  }
  &:active {
    outline: none;
    box-shadow: $box-shadow-400-focus;
  }
  &:focus {
    box-shadow: $focus-ring;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring;
  }
}
