@import 'src/tokens';
@import 'src/mixins';

.MoverLifecycleFilters {
  padding: 0.25rem;
  display: flex;
  align-items: center;
  width: 100%;

  @include focus-ring;
  background: $color-white;
  border: $pixels-2 solid $color-grey-200;
  text-align: left;
  border-radius: $border-radius-s;
  cursor: pointer;
  margin: 0.5rem 0;
  &:first-of-type,
  &:last-of-type {
    margin: 0;
  }
  &:hover {
    box-shadow: $box-shadow-300;
    border-color: $color-grey-300;
    .MoverLifecycleFilters__icon {
      color: $color-grey-300;
      border-color: $color-grey-300;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: $color-grey-200;
    border-color: $color-grey-200;
    height: 3rem;
    width: 3rem;
    outline: none;
    border-radius: 100rem;
    border-width: $pixels-4;
    border-style: solid;
    text-decoration: none;
    background-color: $color-white;
  }
  &__svg {
    opacity: 0;
  }
  &__label {
    display: flex;
    flex-direction: column;
    opacity: 0.4;
  }
}

.MoverLifecycleFilters--is-active {
  border-color: $color-secondary-400;
  .MoverLifecycleFilters__label {
    opacity: 1;
  }
  .MoverLifecycleFilters__icon {
    border-color: $color-secondary;
    background: $color-secondary;
    color: $color-white;
  }
  .MoverLifecycleFilters__svg {
    opacity: 1;
  }
  &:hover {
    border-color: $color-secondary-400;
    .MoverLifecycleFilters__icon {
      color: $color-white;
      background: $color-secondary-400;
      border-color: $color-secondary-400;
    }
  }
}
