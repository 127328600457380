.campaigns_card_container {
  border-radius: 10px;
  padding: 24px 20px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 3px 26px 0 #c0c0c040;

  .creation_date {
    font-size: 14px !important;
  }

  .campaign_name,
  .campaign_status {
    font-size: 16px !important;
  }
  .campaign_name {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #262626;
    font-weight: bold;
  }

  .campaign_action_btn {
    border-color: #007b94;
    color: #007b94;
    padding: 20px 0;
    font-size: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
