.support-error-page {
  width: 100vw;
  height: 100vh;
  background: #f8f8f8;
  padding: 110px 136px;

  a {
    text-decoration: none;
  }

  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }

  .title {
    color: #0e0d1d;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  .message {
    color: #9a9a9a;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    a.support-mail {
      color: #9a9a9a;
      font-weight: 800;
      text-decoration: none;
    }
  }
}
