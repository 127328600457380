.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 5px;
  // background-color: #fcd917;
  background-color: #fedf21;
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 999;
  width: 40%;
  // border-radius: 22px 22px 0 0;
  border-radius: 100px;
  margin: 0 auto;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #fdc40e;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #fdca11;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}
