@import 'tokens.scss';
@import 'mixins.scss';

.ConfirmPayment {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem 3rem;
  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    background-color: transparent;
    border:none;
  }
  @media (max-width: 1400px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-top: 3rem;
  }

  @media (max-width: 699px) {
    padding-top: 2rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 310px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 40px !important;
      line-height: 54px !important;
      display: flex !important;
      align-items: center !important;

      /* Primary 500 */

      color: #ffab03 !important;
      margin-bottom: 1rem;

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }
    .sub_heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 26px !important;
      /* or 118% */

      color: #818181 !important;
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &__back {
    position: absolute;
    cursor: pointer;
    left: 0;
  }

  &__description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 1400px) {
      padding: 0 2rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px 80px;
    gap: 88px;

    .subscription_card {
      min-height: initial;
      padding-block-end: 1.5rem;
      border: none;
      box-shadow: 0 4px 17px rgba(40, 40, 40, 0.16);
      cursor: initial;
      &:hover {
        box-shadow: 0 4px 17px rgba(40, 40, 40, 0.16);
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 700px;

    > .Button {
      align-self: flex-end;
    }
  }
}
