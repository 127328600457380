.canva-designs-modal {
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;

  padding-inline: 32px;
  padding-block: 30px;

  width: 1085px;
  max-width: 1340px;

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    gap: 32px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &-title {
      color: #7e7e7e;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .canva-designs-card-content {
      padding: 12px;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden auto;
      align-items: center;
      gap: 24px;

      .canva-designs-card-container {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        gap: 23px 24px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}
