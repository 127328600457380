.LensSidebarFooter {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 383px;
  height: auto;
  max-height: 145px;
  padding: 0.7rem;
  margin: 0 2rem;
  box-sizing: border-box;
  position: absolute;
  background: #ffffff;
  border: 1px solid #ffab03;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 999;
  bottom: 10px;

  @media screen and (min-width: 3200px) {
    width: 700px;
    height: 210px;
  }

  .AudienceResultsCard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .MyListingsLens__score {
    margin-left: 0;
    justify-content: center;
    margin-block-end: 10px;

    &.virtual-just-listed-just-sold {
      gap: 9px;
    }
  }

  .neighbors-found {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .prospects_count_text {
      color: #2b1f0a;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .around-listing-score {
      color: #2b1f0a;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .prospects_count {
    color: #ffab03;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (min-width: 3200px) {
      font-size: 38px;
    }
  }
  .prospects_count_text {
    color: #2b1f0a;
  }
  .no_footfall {
    margin: 0 0 10px;
    font-size: 14px;
  }
  .prospects_observed {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.25;
    display: flex;
    align-items: center;

    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 34px;
    }
  }

  //.SaveAudienceWizard {
  //  button.Button {
  //    margin: 0 auto;
  //  }
  //}
}
