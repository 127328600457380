.campaign-examples-widget {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-200, #D1D1D1);
  background: var(--Primary-Yellow-50, #FFFDEA);

  &-description {
    color: var(--Neutrals-950---main-text-color, #262626);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }
}