@import 'tokens.scss';

.CampaignCreatives {
  height: 100%;
  &__PageHeader {
    height: 84px;
    background-color: var(--color-white);

    @media screen and (min-width: 3200px) {
      height: 120px;
    }
  }

  &__title {
    @media (max-width: 699px) {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px !important;
    }
  }
  &__choose_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 1.5 !important;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 34px !important;
    }
  }
  &__including_video {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 1.5;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
  &__choose-creatives {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    // height: auto;
    height: min-content;
    border-radius: 22px;
    @media (max-width: 669px) {
      // width: 300px;
      width: 100%;
    }
  }
  &__types {
    margin-top: 9px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (max-width: 699px) {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  &__if_banner {
    grid-template-columns: repeat(3, 2fr);

    @media (min-width: 1366px) {
      grid-template-columns: repeat(2, 2fr);
    }

    @media (min-width: 700px) and (max-width: 1050px) {
      grid-template-columns: repeat(1, 2fr);
    }
  }

  &__upload {
    grid-column: 2 / 4;
    // display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 699px) {
      width: 100%;
    }
  }

  .video_file_name {
    width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    color: #515a5c !important;

    @media (max-width: 366px) {
      width: 220px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }
}

.mt-17 {
  margin-top: -17px !important;
}
.creatives_main_div {
  background: #ffffff !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
}
