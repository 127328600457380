@import 'tokens.scss';

.budget_adv_control_modal {
  .Modal__scrollable-content {
    max-height: 48rem !important;
    overflow: hidden;
  }
}

.budget_wrapper {
  position: relative;

  .budget_cross {
    border: none;
    padding: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    z-index: 99;
  }

  .footer {
    width: 100%;
    background: #f6f6f6;
    padding: 1.5rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
