@import 'src/tokens';

.DirectMailSizeSelector {
  position: absolute;
  top: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
  background: $color-white;
  padding: 1rem;
  border-radius: $border-radius-m;
  color: $text-on-white;
  transition: all 0.2s ease;
  &--is-selected {
    left: auto;
    right: 1rem;
    transform: translateX(0);
  }
}
