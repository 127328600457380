.pagination_for_realtor {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px auto 0;

  svg {
    color: #f68533;
    cursor: pointer;
  }

  ._text {
    font-size: 10px !important;

    @media screen and (min-width: 3200px) {
      font-size: 20px !important;
    }
  }

  ._button {
    width: fit-content;
    height: fit-content;
    border: none;
    display: flex;
    justify-content: center;

    .Button__icon-wrapper {
      width: fit-content;
      height: fit-content;
    }
  }
}
