.creative-image-preview.Modal__content {
  width: 890px;
  height: 804px;
  position: relative;

  .Modal__scrollable-content {
    padding-inline: 25px;
    padding-block: 32px;

    display: flex;
    flex-direction: column;
    gap: 21px;

    height: 100%;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-inline-end: 30px;

      .name,
      .size {
        color: #3f3f42;
        font-family: Inter, sans-serif;
        font-style: normal;
        white-space: nowrap;
      }

      .name {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;

        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
      }

      .circle {
        width: 4px;
        height: 4px;
        background-color: #c5c5c5;
        border-radius: 50%;
      }

      .size {
        color: #3f3f42;
        font-family: Inter, sans-serif;
        font-style: normal;
      }
    }

    .preview-modal-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;

      padding-inline: 18px;
      padding-block: 18px;
      background-color: #eeeeee;

      border-radius: 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
