.SignatureModal {
  position: relative;

  padding: 16px 28px 30px;
  display: flex;
  flex-direction: column;
  width: 587px;

  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
  }

  .close-btn {
    padding: 0;
    border: none;
    background: transparent;
  }

  &__title,
  &__title a {
    color: #2b1f0a;
    text-align: right;

    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }
  &__title a {
    font-weight: 700;
    text-decoration: underline;
  }
  &__description {
    display: flex;
    flex-direction: column;
  }

  &__description {
    color: #818181;
    text-align: center;

    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .signature-container {
    width: 100%;
    height: 251px;
    border: 1px solid #e1e1e1;
  }

  .clear-signature-btn.p-button-text.p-button-danger {
    padding: 4px 15px;
    box-shadow: none;
    gap: 4px;

    .p-button-label {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
