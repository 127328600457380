.upgrade-modal {
  padding-inline: 60px;
  padding-block: 24px;
  gap: 32px;

  &-title.Text {
    color: #252525 !important;
    text-align: center;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  &-description.Text {
    max-width: 500px;
    color: #a5a5a5 !important;
    text-align: center;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  &-action {
    .Button__text {
      color: #2b1f0a;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
