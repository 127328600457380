.ChooseSubscription {
  display: flex;
  padding: 1rem;

  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
  }

  @media (max-width: 1400px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-top: 3rem;
  }

  @media (max-width: 699px) {
    padding-top: 2rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 310px;
  }

  &__header {
    max-width: 350px;

    @media screen and (min-width: 3200px) {
      max-width: 650px;
    }

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 25px !important;
      line-height: 1.5 !important;
      display: flex !important;
      align-items: center !important;
      color: #ffab03 !important;
      margin-bottom: 1rem;

      @media screen and (min-width: 3200px) {
        font-size: 50px !important;
      }

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }

    .sub_heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 1.3 !important;
      color: #818181 !important;

      @media screen and (min-width: 3200px) {
        font-size: 32px !important;
      }
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    padding: 2rem;
    border-right: 1px solid #c6cbcd;

    @media (max-width: 1400px) {
      padding: 0 1rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }

  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }

  &__progress {
    margin: 1rem 0;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 65%;
    padding: 1rem 3rem;

    &-form {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      min-width: 705px;
      min-height: 640px;
    }

    .subscription-form-submit {
      width: fit-content;
      align-self: flex-end;
      font-weight: 700;
    }

    @media (max-width: 1050px) {
      width: 100%;
      border-left: none;
      border-top: none;
    }
  }

  &__submit {
    position: absolute;
    top: 30px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}
