@import 'src/tokens';

.ProfileDropdown {
  cursor: initial;
  max-width: 20rem;
  &__body {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-grey-050;
  }
  &__footer {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }
  &__avatar {
    margin-bottom: 1rem;
    img {
      object-fit: cover;
    }
  }
  &__name {
    width: 100%;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  &__email {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
