.deactivated-info-bar{
  padding: 16px 22px;
  margin-block: 8px;
  border-radius: 10px;
  border: 1px solid #EF4444;
  background: #FFF;
  font-size: 14px;
  .info-icon{
    color: #FF0000;
    width:32px;
    height:32px
  }
  .info-content{
    display:flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap:100px;
    justify-content: space-between;
  .heading{
    color:#766C5B;
    font-weight: 700;
  }
  .description{
    color: #6D6D6D;
    font-weight: 400
  }
  .cancel-request-button {
    border-radius: 10px;
    border: 1px solid #007b94;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007b94;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: all 0.3s;
    &:not(:disabled):hover {
      transition: all 0.3s;
      color: #fff;
      border-color: #007b94;
      background: #007b94;
    }
  }}
  }