table.mainTable.creatives-library-table {
  .mainTableTh {
    color: #111827;
  }

  .mainTableTdSelected,
  .mainTableTd {
    .creative-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      width: 100%;
      border-radius: 10px;

      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.07px;
      text-transform: capitalize;

      &.uploading {
        color: #5a9ebb;
        background: #e6f5f7;
      }
      &.active {
        color: #5abb82;
        background: #e6f7ec;
      }
      &.error {
        color: #bb5a5a;
        background: #f7e6e6;
      }
    }

    .creative-preview {
      position: relative;
      display: flex;
      align-items: center;
      width: 36px;
      height: 36px;

      img,
      video {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }

      .creative-video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .creative-actions-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .p-button.p-component.creative-action-btn {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 18px;
      width: fit-content;
      box-shadow: unset;
    }
  }
}

.creative-filter-select.p-multiselect {
  background: transparent;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  position: relative;

  .p-multiselect-label-container {
    .p-multiselect-label {
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.07px;

      &.p-multiselect-items-label {
        color: #222124;
      }
    }
  }
}

.clear-filters {
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;

  color: #757575;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.creative-filter-panel.p-multiselect-panel {
  top: 57px !important;
  width: 180px;
  cursor: initial;
  border-radius: 12px;
  box-shadow: 0 4px 25px 0 rgba(156, 156, 156, 0.15);

  .p-multiselect-items {
    padding: 12px;

    .creative-filter-item.p-multiselect-item {
      padding-inline: 8px;
      padding-block: 9px;

      color: #222124;
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.07px;

      .p-checkbox.p-component {
        width: 24px;
        height: 24px;
        margin-inline-end: 8px;
        background: #ffffff;

        &.p-highlight {
          .p-checkbox-box {
            background: #ffffff;
            border: 1px solid #ffab03;
          }
        }

        .p-checkbox-box {
          width: inherit;
          height: inherit;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddddd;

          transition: all 0.3s;

          &.p-highlight {
            background: transparent;
            border: 1px solid #ffab03;
          }
        }
      }

      > span {
        text-transform: capitalize;
      }

      &.p-highlight {
        background: transparent;
        color: #222124;
      }

      &:not(.p-highlight):not(.p-disabled).p-focus {
        background: transparent;
      }
    }
  }

  .p-multiselect-footer {
    .creative-filter-panel-footer {
      border-block-start: 1px solid #f0f0f0;
      padding-inline: 16px;
      padding-block: 11px;

      &-apply-btn {
        cursor: pointer;
        padding: 0;
        outline: none;
        border: none;

        color: #ffab03;

        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: transparent;

        &:hover:not(:disabled) {
          color: #ffd154;
        }

        &:disabled {
          color: #d1d1d1;
        }
      }
    }
  }
}
