.talkToSphere {
  width: 100%;
  border-radius: 18px;
  padding: 0.2rem 0;

  .empty_status_container {
    padding-block: 40px;
    img {
      height: 90px;

      @media screen and (min-width: 3200px) {
        height: 350px;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__head > h3 {
    font-size: 15px;
    font-weight: 800;
    color: #d89613;
  }
  &__headButton {
    border: none;
    border-radius: 18px;
    background-color: white;
    color: #d89613;
    height: 35px;
    padding: 0.2rem 1rem;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    :hover {
      color: #d89613;
      background-color: white;
    }
  }
}

.address-tooltip-content {
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  line-height: 17px;
}
