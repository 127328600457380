.ProgressStepperBar {
  width: 100%;
  &__stepbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    border: none;
    width: 100%;
    background-color: white;
  }
  &__stepbutton_text {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    border: none;
    width: 100%;
    background-color: white;
  }
  &__steps {
    display: flex;
    align-items: center;

    @media (max-width: 699px) {
      justify-content: center;
    }
  }
  &__line {
    height: 0.3px;
    background-color: #c6cbcd;
    width: 55px;
    margin: 0px;
    z-index: 1;
  }
  &__step {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #eeeff0;
    color: #6d797c;
    border: 1px solid #6d797c;
    z-index: 2;
  }
}

.ProgressStepperBar__step--is-filled {
  border: 2px solid #33a0c4;
  background-color: transparent;
}

// .ProgressStepperBar__line--is-filled {
//   background-color: #fcd917;
// }

.progressStepper {
  border-radius: 50%;
  border: 2px solid;
  width: 25px;
  height: 25px;
  text-align: center;
}

.progressTag {
  position: absolute;
  margin-left: -10px;
  font-size: 15px;
  color: #6d797c;

  @media (max-width: 699px) {
    font-size: 12px;
    margin-left: -11px;
  }
}

.p-steps.p-component.campaign-creation-progress {
  width: 333px;
  @media screen and (min-width: 3200px) {
    width: 700px;
  }
  .p-steps-item {
    .p-menuitem-link {
      .p-steps-number {
        width: 30px;
        height: 30px;
        border: 1px solid #6d797c;
        color: #6d797c;
        background-color: #eeeff0;

        @media screen and (min-width: 3200px) {
          font-size: 2rem;
          width: 65px;
          height: 65px;
        }
      }

      .p-steps-title {
        color: #6d797c;
      }

      @media screen and (min-width: 3200px) {
        font-size: 2rem;
      }
    }

    &.p-highlight {
      .p-menuitem-link {
        .p-steps-number {
          border: 3px solid #f7a500;
          color: #f7a500;
          background-color: #ffffff;
        }
        .p-steps-title {
          font-weight: 600;
          color: #f7a500;
        }
      }
    }
  }
}
