.notification_container_tooltip {
  text-transform: initial;

  .empty_status_container {
    padding-block-start: 60px;
    .Text {
      font-size: 18px !important;
    }

    .empty_status_container__subHeading {
      font-size: 16px !important;
    }
  }

  .nested_container {
    background: white;
    min-height: 446px;

    ul {
      list-style: none;
      padding: 0;
      cursor: pointer;
    }

    .header_container {
      border-bottom: 1px solid #c6cbcd;
      padding: 0 16px;

      .header_titles {
        text-transform: capitalize;
        margin: 0;
        display: flex;
        align-items: center;

        .Text {
          font-size: 11px !important;

          @media screen and (min-width: 3200px) {
            font-size: 21px !important;
          }
        }

        .notification-tab {
          display: flex;
          align-items: center;
          margin: 0 7px;
          font-size: 11px;

          padding: 8px 0;
          cursor: pointer;
          position: relative;

          @media screen and (min-width: 3200px) {
            margin: 0 25px;
            font-size: 21px;
          }

          .notification-tab-source {
            color: #000;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          &.notification-tab-active {
            &::after {
              content: '';
              position: absolute;
              background: #ffab03;
              width: 100%;
              height: 3px;
              bottom: -1px;
            }

            .notification-tab-source {
              font-weight: 700;
            }
          }
        }
      }

      .notification-tab-source-count {
        padding: 0 2px;
        text-align: center;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        margin-left: 0.3rem;
        background: #fff5e1;

        color: #ffab03;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;

        @media screen and (min-width: 3200px) {
          margin-left: 0.75rem;
          font-size: 21px;
        }
      }
    }

    .body_container {
      margin-top: 12px;
      padding: 0 16px 16px;
      overscroll-behavior: contain;

      .notification-group-container {
        gap: 8px;

        &:not(:first-child) {
          margin-top: 6px;
        }
      }

      .notification-group-title {
        color: var(--Neutral-500, #c5c5c5);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .notification {
        display: flex;
        padding: 12px 16px 24px 16px;
        align-self: stretch;
        gap: 12px;

        border-bottom: 1px solid #e4e4e4;
        background: #ffffff;

        &.notification-unread {
          background: #fffbf2;
        }
      }

      .notification-status-dot {
        > span {
          display: flex;
          align-items: center;
          height: 24px;
        }
      }

      .notification_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
          color: #2b1f0a;

          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        &-description,
        &-description a {
          color: #3f3f42;

          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .notification_created {
        color: #aeaeae;

        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        white-space: nowrap;
        word-break: keep-all;
      }

      .pagination_container {
        margin: 0;
        padding-top: 20px;
      }
    }

    .black_bg_color {
      background-color: black !important;
    }

    .border_bottom {
      border-bottom: 3px solid black;
    }

    .unread {
      background: rgba(255, 171, 3, 0.1);
    }

    .empty_status_container__headings_container {
      .Text {
        font-family: sans-serif, 'Helvetica Neue', 'Lucida Grande', Arial;
        font-size: 24px !important;
      }
    }
  }
}
.notificationTitleIcon {
  display: flex;
  align-items: center;
  &__icon {
    margin: 0 5px;
    object-fit: contain !important;
  }
}
