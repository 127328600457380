@import 'src/tokens';

.Tabs {
  margin: 0;
  position: relative;
  border-bottom: $border-width-thin solid $color-grey-200;
  height: min-content;
  padding: 0 0.25rem;
  padding-bottom: 0;
  display: flex;
  &__phantom {
    transition: left 0.2s ease, width 0.2s ease;
    position: absolute;
    display: flex;
    border-radius: $border-radius-m;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: $border-width-thin solid red;
    border-bottom: none;
    height: 100%;
    .Tabs__bottom-cover {
      height: $border-width-thin;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
    &--is-focused {
      border-width: $border-width-default;
    }
    &--is-keyboard-focused {
      box-shadow: $box-shadow-200-focus;
    }
  }
  &:focus {
    outline: none;
  }
}
