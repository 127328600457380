.attach-creative-modal-content{
  width: 783px;
  padding:30px 46px;
  display:flex;
  flex-direction: column;
  gap:4px;
  align-items: center;
  justify-content: center;
  position: relative;
  .attach-creative-item{
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 36px 60px;
    .item-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 190px;
    img{
      width: 70px;
      height: 70px;
    }
.actions{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
.text-btn{
  background-color: transparent;
  border: none;
  color: #007b94;
  font-size: 16px;
  font-weight: 700;
  cursor:pointer;
  padding: 11px 16px;
  &:hover {
    color: #F3C000;
  }
  &:disabled {
    color: #8d989c;
    cursor: not-allowed;
  }
}
}
    }
  }
}