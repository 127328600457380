@import 'tokens';
.street_listing {
  font-family: 'Open Sans';
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  align-items: center;
  color: #2b1f0a !important;
}
.LeftContent_main_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ListingView {
  // background-color: $color-grey-100;
  background-color: #f8f8f8;
  &__page-header {
    border-bottom: 1px solid #c6cbcd;

    @media (max-width: 699px) {
      flex-direction: column !important;
    }
  }
  &__status-badge {
    width: 118px;
    height: 40px;
    padding: 0.25rem 0.5rem;
    background: #fff9ec;
    border: 1px solid #ffab03;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffab03;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
  }
  &__Oval {
    margin-right: 10px;
  }
  &__title_wrapper {
    display: flex;
    align-items: center;
  }

  .PageHeader {
    background-color: var(--color-white);
    border: none;
  }
  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-height: calc(100% - 5.2rem);
    padding-block: 1rem;

    @media (max-width: 1050px) {
      flex-direction: column;
    }

    .ListingDetails__container {
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 1050px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 699px) {
        grid-template-columns: 1fr;
      }
    }
  }
  &__left-column {
    // min-width: 35rem;
    width: 49%;
    // padding: 1rem;
    background: #f8f8f8;

    @media (max-width: 1050px) {
      width: 100%;

      .Section {
        @media (max-width: 699px) {
          padding: 0 !important;
        }
      }
    }

    @media (max-width: 1081px) {
      flex-grow: 1;
    }
  }
  &__right-column {
    width: 49%;
    display: flex;
    flex-grow: 1;
    margin-left: 1rem;
    // padding: 0 1rem;
    // background-color: white;

    @media (max-width: 1050px) {
      width: 100%;

      .Section {
        @media (max-width: 699px) {
          padding: 0;
        }
      }
    }
  }
  &__status {
    display: flex;
    align-items: center;
    background: $color-tertiary-500;
    border-radius: $border-radius-m;
    color: $text-on-tertiary-500;
    padding: 0.2rem 0.4rem;
    font-size: $text-size-s;
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
  &__go-back {
    background: $color-white;
    padding: 1rem 0 0 1rem;
  }
  &__actions-colors {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #2b1f0a !important;
  }
  &__actions-colors_value {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    color: #2b1f0a !important;
  }
  &__go-back-btn {
    width: max-content;
  }

  .city_and_zip_container {
    @media (max-width: 699px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .PageHeader__actions {
    width: 100%;
  }

  .icon-header-text {
    @media (max-width: 699px) {
      // padding: 0 1em;
      margin-top: 0;
    }
  }
}

.ListingView .Section__spacer {
  background-color: #c6cbcd !important;
  border: 1px solid #f0f0f0 !important;
}
.heading-color {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #2b1f0a !important;
}

.ListingView__sub-title {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px;
  color: #6d6d6d !important;
  padding-left: 45px !important;
  letter-spacing: 0.4px !important;
}

.listing-header-icon {
  height: 58px;
  padding-top: 15px;
  margin-top: -9px;
  margin-left: 6px;
}
