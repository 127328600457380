.loginHeader {
  width: 100%;
  padding-top: 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-block: 9px;

  @media screen and (min-width: 3200px) {
    padding-block: 15px;
  }
}
.loginHeader_logo {
  padding: 0 10px;
  height: 32px;

  @media screen and (min-width: 3200px) {
    height: 65px;
  }
}
.loginHeader_hr {
  margin: 0;
  background-color: #fcd917;
  border: 2px solid #fcd917;
}
