.profileTopHeading {
  margin: 0;
  background: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  // margin-top: 3.5rem;
  margin-top: 37px;

  @media (max-width: 1266px) {
    margin-top: 5.2rem;
  }

  @media (max-width: 1050px) {
    display: none;
  }
}

.profileTopHeading > h3 {
  margin: 0;
  padding-left: 25px;
  font-weight: 500;
  font-weight: bold;
  letter-spacing: 1px;
}
@media screen and (max-width: 1050px) {
  .profileTopHeading {
    height: 45px;
    margin-top: 1rem;
  }
}
