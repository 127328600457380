.forget_all_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 2rem 6rem;
  background: #ffffff;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 2;
  .Login__form {
    max-width: 350px;
  }

  @media screen and (min-width: 3200px) {
    padding: 5rem 22rem;
  }
}
.mainLogin {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 760px;
  min-width: 500px;
  padding: 2rem 0;

  @media screen and (min-width: 3200px) {
    width: 1300px;
  }

  @media screen and (max-height: 800px) {
    padding: 5rem 0;
  }

  @media screen and (max-width: 500px) {
    max-width: 85vw;
  }

  @media (max-width: 1050px) {
    .setPasswordInput {
      width: 100% !important;
    }
  }

  &__logo {
    margin-bottom: 1rem;
  }
  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
}
.loginContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y:scroll;
  background-color: white;
  background-image: url('../../assets/Images/honeycomb_left.svg'),
    url('../../assets/Images/honeycomb.svg');
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: left bottom, right top;

  .public-beewo-logo {
    height: 70px;

    @media screen and (min-width: 3200px) {
      height: 230px;
    }
  }
}
.email_has_been_sent {
  max-width: 280px;
  margin: 0 30px;
  display: flex;

  @media (max-width: 699px) {
    margin: 0;
  }
}

.crtAccOr {
  text-align: center;
  margin: 10px 0;

  span.Text {
    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
}

.resendMail {
  color: #ffab03;
}
.loginResendMail {
  display: flex;
  justify-content: center;
  // width: 140px;
  text-align: center;
  color: #ffab03;
}
.loginResendMail > p {
  margin-bottom: 3px;
}
.loginResendMail :hover {
  opacity: 0.8;
  cursor: pointer;
}
.onboardingBack {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  color: #ffab03;
}

.onboardingBack > svg {
  font-size: 17px;
  @media screen and (min-width: 3200px) {
    font-size: 27px;
  }
}

.onboardingBack > p {
  font-size: 17px;
  @media screen and (min-width: 3200px) {
    font-size: 27px;
  }
}
.onboardingBack :hover {
  opacity: 0.8;
  cursor: pointer;
}
.sendVerification {
  border-radius: 10px;
  padding: 26px 0;
}

.creat_acc_heading {
  @media screen and (min-width: 3200px) {
    font-size: 36px !important;
  }
}

.Create_account_description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.34;
  text-align: center;
  color: #6d6d6d;
  margin-block: 8px;
  @media screen and (min-width: 3200px) {
    font-size: 27px !important;
  }
}

.Create_account_password_contains {
  margin-block-start: 8px;
  color: #6d6d6d;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.34;

  ul {
    margin-inline-start: 20px;
  }
}
