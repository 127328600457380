.notificationContainer {
  &__container.Section {
    height: auto !important;
    width: 100%;
    border-radius: 27px;
    box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
    background-color: #fff;
    padding: 0;

    .MaxHeightContainer__header {
      border-radius: 20px 20px 0 0;
      background: #f7f7f7;
      padding: 23px 22px;

      .Section__header {
        margin: 0;
      }
    }
    .MaxHeightContainer__content {
      overflow: hidden;
    }
  }
  &__unread {
    background: rgba(255, 171, 3, 0.1);
  }
  &__listBox {
    width: 100%;

    .empty_status_container {
      margin-block-start: 50px;

      .Text {
        font-size: 18px !important;

        @media screen and (min-width: 3200px) {
          font-size: 28px !important;
        }
      }

      img {
        height: 174px;

        @media screen and (min-width: 3200px) {
          height: auto;
        }
      }
    }

    .notification {
      display: flex;
      padding: 16px 22px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 24px;

      border-bottom: 1px solid #e4e4e4;
      background: #ffffff;

      &.notification-unread {
        background: #fffbf2;
      }
    }

    .notification_content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-title {
        color: #2b1f0a;

        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      &-description,
      &-description a {
        color: #3f3f42;

        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
