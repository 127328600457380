.empty_status_container {
  //position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 103px;
  padding-block: 85px;
  img {
    height: 88px;
    width: 134px;
    @media screen and (min-width: 3200px) {
      height: 350px;
    }
  }

  &__nested_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &__headings_container {
    margin-top: 38px;
    max-width: 500px;
  }

  &__heading {
    @media screen and (min-width: 3200px) {
      font-size: 60px !important;
    }
  }

  &__subHeading {
    margin-top: 5px;
    display: inline-block;
    color: #9a9a9a !important;

    @media screen and (min-width: 3200px) {
      font-size: 40px !important;
    }
  }

  @media (max-width: 699px) {
    img {
      width: 50%;
    }

    &__headings_container {
      .Text {
        font-size: 18px !important;
      }
    }
  }
}
