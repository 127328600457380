.AssocietedCampaignModal {
  position: relative;
  min-height: 540px;

  .cross {
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
  }

  .associeted_modal_container {
    width: 641px;
    padding: 32px;

    .heading {
      margin-bottom: 25px;

      .title,
      .address {
        font-size: 16px;
      }

      .address {
        margin-top: 2.5px;
      }
    }

    .campaigns_list {
      overflow-y: auto;
      max-height: 400px;
      width: 100%;
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      list-style: none;
    }
  }

  .skeleton_loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.loading-spinner {
  padding-block: 24px;
}
