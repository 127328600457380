.campaignProgress {
  &__title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    color: #000000 !important;
  }

  &__right {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  &__title,
  &__linkAnchore {
    font-size: 25px !important;
    line-height: 1.25;
    letter-spacing: 0.3px;
    @media screen and (min-width: 3200px) {
      font-size: 35px !important;
    }
    @media screen and (max-width: 1750px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 1550px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1350px) {
      font-size: 18px !important;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    box-shadow: 0 3px 6px rgba(0, 0, 41, 0.25);
    background-color: #fff;
    padding: 20px 30px;
  }

  &__innerContainer {
    width: 150px;
    margin-top: 3rem;

    &__pie_className {
      font-size: 18px;
      font-weight: 700;
      fill: #263238;
    }

    @media screen and (min-width: 3200px) {
      width: 240px;
    }
    @media screen and (max-width: 1750px) {
      width: 140px;
    }
    @media screen and (max-width: 1550px) {
      width: 130px;
    }
    @media screen and (max-width: 1350px) {
      width: 115px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    &:last-child {
      margin: 0;
    }

    @media screen and (min-width: 3200px) {
      gap: 1.25rem;
    }
    @media screen and (max-width: 1750px) {
      gap: 0.75rem;
      margin-bottom: 0.75rem;
    }

    &__text {
      font-size: 22px;
      letter-spacing: 1px;
      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
      @media screen and (max-width: 1750px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1550px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
      }
    }

    &__color {
      width: 23px;
      height: 23px;
      border-radius: 20%;

      @media screen and (min-width: 3200px) {
        width: 30px;
        height: 30px;
      }
      @media screen and (max-width: 1750px) {
        width: 19px;
        height: 19px;
      }
      @media screen and (max-width: 1550px) {
        width: 15px;
        height: 15px;
      }
      @media screen and (max-width: 1350px) {
        width: 14px;
        height: 14px;
      }

      &.draft {
        background-color: #e3e0e0;
      }

      &.active {
        background-color: #ffab03;
      }

      &.complete {
        background-color: #fcea44;
      }
    }
  }

  &__link {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 999;
  }

  &__linkAnchore {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    color: #ffab03;
    z-index: 999;

    :hover {
      background-color: green;
      cursor: pointer;
    }
  }

  &__linkAnchore:hover {
    cursor: pointer;
  }
}
