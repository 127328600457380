@import 'src/tokens';

.HeaderNav {
  display: flex;
  &--right {
    margin-left: auto;
  }
}
.header-border {
  border-left: 1px solid #c6cbcd;
  margin-block: 9px;

  @media (max-width: 699px) {
    border-left: none;
  }
}
