.finished_campaign_section {
  padding: 1px !important;
  background: none !important;
}
.finished_campaign_content {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  @media screen and (max-width: 1500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8px;
    :nth-child(1) {
      order: 3;
    }
  }
}
.finished_campaigns_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__pie_className {
    fill: #ca9d00;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    width: 250px;
    line-height: 16px;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ca9d00 !important;

    @media screen and (min-width: 3200px) {
      width: 350px;
    }

    @media (max-width: 1600px) {
      width: 200px;
    }
    @media (max-width: 1400px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
    }
  }
}

.FinishedCampaigns__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #000000 !important;
  @media screen and (min-width: 3200px) {
    font-size: 28px !important;
  }
}
