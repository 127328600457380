.addCardHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 28px !important;
  line-height: 1.25 !important;
  text-align: center !important;
  color: #2b1f0a !important;
  margin-bottom: 3rem;

  @media screen and (min-width: 3200px) {
    font-size: 42px !important;
  }
}

.add_edit_card {
  width: 200px !important;
  height: 56px !important;
}

.field-ex1 {
  height: 45px;
  // border: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 10px;
}
.field-ex1:focus {
  border: 1px solid #ffab03 !important;
}
.Chargebee_label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */

  /* Light 400 */

  color: #6d6d6d;
}
.field-ex1:hover {
  border: 1px solid #ffab03;
}
.Fullname_chargebee {
  width: 100% !important;
  border: 1px solid #dadada !important;
  border-radius: 10px !important;
  height: 45px !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 17px !important;
  /* identical to box height, or 106% */

  /* Light 400 */

  color: #6d6d6d !important;
}
.Fullname_chargebee:hover {
  border: 1px solid #ffab03 !important;
}

.ex1.container {
  margin: auto;
  padding: 100px 0;
  min-height: 100vh;
}

.ex1-wrap {
  margin: auto;
  max-width: 400px;
  border-radius: 8px;
  background: #fff;
  padding: 32px;
}

.ex1-field {
  position: relative;
  margin-bottom: 32px;
}
.cvvSpace {
  width: 100px;
}
.ex1-fields {
  display: flex;
  margin-left: -16px;
}
.ex1-fields .ex1-field {
  flex: 1;
  margin-left: 16px;
}
.ex1-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #6d6d6d !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  width: 60% !important;
  background-color: white;
  padding-right: 40px;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;

  @media screen and (min-width: 3200px) {
    font-size: 30px !important;
  }
}
.ex1-input {
  width: 100%;
  display: block;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 19px 15px 16px 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
  color: #6d6d6d;
  cursor: text;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;

  @media screen and (min-width: 3200px) {
    padding: 35px 20px;
  }
}

.CardForm,
.CardForm .CardInput {
  font-size: 34px;
}

.ex1-input:hover {
  border: 1px solid #ffab03 !important;
}
.ex1-input:focus {
  border: 1px solid #ffab03 !important;
}

.ex1-input:placeholder-shown {
  border: 1px solid #ffab03 !important;
}
.ex1-input::placeholder {
  color: transparent;
}
.ex1-input:focus::placeholder {
  color: #ffab03 !important;
}

.ex1-input:focus ~ .ex1-label,
.ex1-input.focus ~ .ex1-label,
.ex1-input.val ~ .ex1-label,
.ex1-input.complete ~ .ex1-label,
.ex1-input.invalid ~ .ex1-label {
  font-size: 0.8rem;
  color: #7b808c;
  top: 0;
  width: auto !important;
  padding: 0 3px;
  background: white !important;

  @media screen and (min-width: 3200px) {
    font-size: 23px !important;
    padding-left: 10px;
    padding-right: 10px !important;
  }
}

.ex1-input.invalid ~ .ex1-label {
  color: #bd0000 !important;
}
.errShow {
  margin-top: 2px;
}

.ex1-input.invalid {
  left: 1rem;
  width: 100%;
  display: block;
  background: transparent;
  padding: 19px 15px 16px 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #6d6d6d;
  cursor: text;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
  border: 1px solid #bd0000 !important;

  @media screen and (min-width: 3200px) {
    padding: 25px 20px;
  }
}

.showError {
  display: none;
}

.ex1-bar {
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;
}
.ex1-bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #337ab7;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.focus {
  border: 1px solid #ffab03 !important;
}
.ex1-button {
  background: #0950cc;
  background: #0c0ebd;
  color: #fff;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  display: block;
  width: 100%;
  letter-spacing: 0.5px;
  transition: 0.2s;
  cursor: pointer;
}
.ex1-button:hover,
.ex1-button:focus {
  background: #0641a7;
  background: #0a0b9a;
}
.ex1-button.submit {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWxvYWRlciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTUiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCA4MCA4MCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQwIDcyQzIyLjQgNzIgOCA1Ny42IDggNDBTMjIuNCA4IDQwIDhzMzIgMTQuNCAzMiAzMmMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJjMC0xNS40LTEyLjYtMjgtMjgtMjhTMTIgMjQuNiAxMiA0MHMxMi42IDI4IDI4IDI4YzEuMSAwIDIgLjkgMiAycy0uOSAyLTIgMnoiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNDAgNDAiIHRvPSIzNjAgNDAgNDAiIGR1cj0iMC42cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48L3BhdGg+PC9zdmc+);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  color: transparent !important;
  transition-duration: 0s;
}

.ex1-wrap .token {
  color: #555;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.ex1-wrap .error {
  color: #bd0000;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  ~ .showErrorBar {
    display: none !important;
  }
}
