@import 'tokens.scss';
@import 'src/mixins';

.Uploader {
  @include focus-ring;
  cursor: pointer;
  background: #fff9ed;
  border: 2px dashed #ffab03;
  border-radius: 9px;

  &.creative-gen {
    border: none;
  }

  .BackgroundImageTab__temp-uploader > span {
    font-size: initial;
  }
}

.upload_your_video {
  padding: 35px 0;

  @media (max-width: 699px) {
    padding: 0;
  }
}

.thumbsContainer {
  display: flex;
  flex-direction: column;
  margin-block-end: 24px;
  gap: 24px;
}

.file-thumb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .file-thumb-info-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .thumb-type-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;

      img.thumb-img-icon {
        width: inherit;
        height: inherit;
      }
    }
    .file-thumb-info {
      display: flex;
      flex-direction: column;
      gap: 3px;

      &-name,
      &-size {
        color: #3a4861;
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'clig' off, 'liga' off;

        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.07px;
      }

      &-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
      }

      &-size {
        font-weight: 400;
      }
    }
  }
  .delete-file-btn {
    cursor: pointer;
    outline: none;
    border: 2px solid #f2f2f2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }
}
