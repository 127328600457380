.RecommendationTile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 10px;

  .title {
    font-size: 16px;
    margin: 0 !important;
  }

  .desc {
    font-size: 16px;
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.8);
  }
}
