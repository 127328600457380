.empty_state_container {
  min-height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 149px;
    height: 116px;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 20px !important;
    margin: 0 0 0.5rem 0 !important;
  }

  .desc {
    color: #757575;
    font-size: 16px !important;
  }
}
