.subscription_card_container {
  display: flex;
  justify-content: center;
  gap: 60px;

  @media (max-width: 1050px) {
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 699px) {
    margin: 0;
  }

  &.has-upcoming {
    flex-direction: column;
    gap: 12px;
  }
}

.current_subscription_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 22px;
  padding-block: 32px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);

  @media screen and (min-width: 3200px) {
    padding-inline: 42px;
    padding-block: 52px;
  }
}

.current_subscription-upcoming {
  display: flex;
  width: 100%;
  padding: 12px 22px;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
  border-radius: 10px;
  border: 1px solid #f6b433;
  background: #fff;

  &-info {
    display: flex;
    align-items: center;
    gap: 12px;

    &-details {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &-title,
      &-content {
        color: #ababab;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      &-title {
        color: #766c5b;
        font-weight: 700;
      }
    }
  }

  &-action {
    .stay-on-current {
      width: fit-content;
      height: fit-content;
      color: #ffb636;
      opacity: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      transition: all 0.3s;
      padding: 0;
      border: none;
      background: transparent;

      .Button__text {
        padding: 0;
      }

      &:hover {
        border: none;
        background: transparent;
        transition: all 0.3s;
        color: #ffb636;
        opacity: 0.8;
      }
    }
  }
}

.current_subscription-details {
  height: 62px;
  display: flex;
  align-items: center;
  gap: 32px;

  &-active {
    align-self: flex-start;
    display: flex;
    padding: 2px 12px;
    align-items: center;
    gap: 6px;
    border-radius: 11px;
    background: rgba(74, 222, 128, 0.12);
    color: #4ade80;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-name {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #2b1f0a;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;

      @media screen and (min-width: 3200px) {
        font-size: 30px;
      }
    }

    &-cost {
      color: var(--neutral-400, #9ca3af);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
    }
  }

  .divider {
    width: 1px;
    height: 100%;
    background: #eae9f7;
  }

  &-next_payment {
    display: flex;
    flex-direction: column;
    gap: 9px;
    &-title {
      color: #585858;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.21px;

      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
    }

    &-date {
      color: #9ca3af;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.21px;

      @media screen and (min-width: 3200px) {
        font-size: 28px;
      }
    }
  }
}

.subscription_card {
  transition: box-shadow 0.2s;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #d8d8d8;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  max-width: 337px;
  height: 100%;
  min-height: 640px;
  background: #ffffff;
  border-radius: 40px;

  @media screen and (min-width: 3200px) {
    max-width: 470px;
  }

  &:hover {
    box-shadow: 0 4px 17px rgba(40, 40, 40, 0.16);
  }

  &.subscription_card_selected {
    &.basic {
      border: 3px solid #ffab03;
    }
    &.premium {
      border: 3px solid #fcd917;
    }
  }

  @media (max-width: 1050px) {
    margin-right: 0;
  }

  @media (max-width: 699px) {
    margin: 0.5rem 0;
  }

  .hidePremium {
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }

  .dropDownPremium {
    max-height: 500px;
    transition: max-height 0.2s ease-in;
    margin-left: 20px;
  }
  .dropDownPremium > li {
    background-image: url('../../../../assets/Images/premium_tick.png');
    margin-left: 20px;
    list-style: none;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    padding-block: 13px;
  }

  .dropDownPremium ul {
    list-style: none;
    margin-left: 50px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }

  .dropDownPremium > li > span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #000000 !important;
  }
  .dropDownPremium > ul li::before {
    background-color: #fcd917;
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2.2px;
    height: 5px;
    width: 5px;
  }
  .title {
    position: absolute;
    margin-top: 13px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px !important;
    line-height: 1.5;
    color: #ffffff;
    margin-left: 9px;

    @media screen and (min-width: 3200px) {
      font-size: 27px !important;
    }
  }
  .price_value {
    text-align: center !important;
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 60px !important;
    line-height: 1.5;
    color: #2b1f0a !important;

    @media screen and (min-width: 3200px) {
      font-size: 70px !important;
    }
  }

  hr {
    width: 185px;
    border: 1px solid #e1e1e1;
  }

  ul {
    padding: 0;
    margin: 0;
    // min-height: 124px;
  }

  ul.basic {
    // padding-bottom: 106px;
    // padding-bottom: 113px;
  }

  ul.basic > li {
    background-image: url('../../../../assets/Images/basic_tick.png');
    margin-left: 10px;

    list-style: none;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    margin-block-start: 12px;

    &:first-child {
      margin-block-start: 0;
    }
  }

  ul.basic > li > span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    color: #000000 !important;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }

  .price_div {
    display: flex;
    justify-content: center;
  }
  .price_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ul.premium > li {
    background-image: url('../../../../assets/Images/premium_tick.png');
    margin-left: 10px;

    list-style: none;
    // background-image: url('./assets/img/control.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    margin-block-start: 12px;

    &:first-child {
      margin-block-start: 0;
    }
  }

  ul.premium > li > span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 1.3 !important;
    color: #000000 !important;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }
  ul > ul li::marker {
    content: '';
  }

  ul > ul {
    margin-left: 41px;
    margin-block-start: 12px;
  }
  ul > ul > li {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin-block-start: 4px;

    @media screen and (min-width: 3200px) {
      font-size: 23px;
    }

    &:first-child {
      margin-block-start: 0;
    }
  }
  ul.basic > ul li::before {
    background-color: #ffab03;
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2.2px;
    height: 5px;
    width: 5px;

    @media screen and (min-width: 3200px) {
      height: 10px;
      width: 10px;
    }
  }

  ul.premium > ul li::before {
    background-color: #fcd917;
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2.2px;
    height: 5px;
    width: 5px;

    @media screen and (min-width: 3200px) {
      height: 10px;
      width: 10px;
    }
  }

  .body_height {
    height: 108px;
  }

  .sub_logo {
    margin-left: -33px;
    width: 249px;

    @media screen and (min-width: 3200px) {
      width: 410px;
    }
  }
  .basic_btn {
    color: #ffab03;
    border: 1px solid #ffab03;
    background: transparent;
  }
  .premium_btn {
    color: #1f1f1f;
  }
  .decimal_value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-top: -9px;
    color: #2b1f0a;
    margin-bottom: -6px;
  }
  .sub_month {
    font-family: 'Open Sans';
    font-style: normal !important;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 14px;
    text-align: center;

    color: #000000 !important;
  }
  .upgrade_button {
    width: 205px;
    height: 40px;
    margin: 0 auto;
    padding: 23px 0;
    // font-size: 30px;
    // font-style: italic;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    // @media screen and (max-width: 1080px) {
    //   font-size: 26px;
    // }
    // @media screen and (max-width: 600px) {
    //   font-size: 21px;
    // }
  }

  .visibility_hidden {
    display: none;
  }
}

ul {
  padding: 0;
  margin: 0;
  // min-height: 124px;
}

ul.basic {
  // padding-bottom: 106px;
  // padding-bottom: 113px;
}

ul.basic > li {
  background-image: url('../../../../assets/Images/basic_tick.png');
  margin-left: 10px;

  list-style: none;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  margin-block-start: 12px;

  &:first-child {
    margin-block-start: 0;
  }
}

ul.basic > li > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
  color: #000000 !important;

  @media screen and (min-width: 3200px) {
    font-size: 24px !important;
  }
}

.price_div {
  display: flex;
  justify-content: center;
}
.price_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
ul.premium > li {
  background-image: url('../../../../assets/Images/premium_tick.png');
  margin-left: 10px;

  list-style: none;
  // background-image: url('./assets/img/control.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  margin-block-start: 12px;

  &:first-child {
    margin-block-start: 0;
  }
}

ul.premium > li > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 1.3 !important;
  color: #000000 !important;

  @media screen and (min-width: 3200px) {
    font-size: 24px !important;
  }
}
ul > ul li::marker {
  content: '';
}

ul > ul {
  margin-left: 41px;
  margin-block-start: 12px;
}
ul > ul > li {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-block-start: 4px;
  @media screen and (min-width: 3200px) {
    font-size: 23px;
  }
  &:first-child {
    margin-block-start: 0;
  }
}
ul.basic > ul li::before {
  background-color: #ffab03;
  border-radius: 50%;
  content: '';
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 2.2px;
  height: 5px;
  width: 5px;

  @media screen and (min-width: 3200px) {
    height: 10px;
    width: 10px;
  }
}

ul.premium > ul li::before {
  background-color: #fcd917;
  border-radius: 50%;
  content: '';
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 2.2px;
  height: 5px;
  width: 5px;

  @media screen and (min-width: 3200px) {
    height: 10px;
    width: 10px;
  }
}
