.creatives-library {
  &__PageHeader {
    height: 84px;
    background-color: var(--color-white);

    @media screen and (min-width: 3200px) {
      height: 120px;
    }

    .add-creatives-toggle-wrapper {
      position: relative;
    }
  }

  .empty_status_container {
    padding-block: 90px;
  }

  .Button.p-button.p-component.add-creatives-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-inline: 1rem;
  }
}

.add-creatives-menu.p-menu.p-component {
  top: calc(44px + 15px) !important;
  right: 0 !important;
  left: unset !important;
  width: max-content;
  .p-menu-list {
    .p-menuitem {
      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          > .p-menuitem-content {
            background: #ffffff;

            &:hover {
              background: #f3f4f6;
            }
          }
        }
      }

      .p-menuitem-link {
        gap: 0.5rem;
      }
    }
  }
}

.creatives-actions-menu.p-menu.p-component {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);

  .p-menu-list.p-reset {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .p-menuitem {
      &:not(.p-highlight):not(.p-disabled) {
        > .p-menuitem-content:hover {
          background: #fff9ed;
        }

        &.p-focus {
          > .p-menuitem-content {
            background: #ffffff;

            &:hover {
              background: #fff9ed;
            }
          }
        }
      }

      .p-menuitem-content {
        border-radius: 5px;

        .p-menuitem-link {
          border-radius: 5px;
          padding-inline: 8px;
          padding-block: 7px;
          gap: 10px;

          .p-menuitem-text {
            color: #222124;
            font-variant-numeric: lining-nums tabular-nums;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Open Sans', serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.07px;
          }
        }
      }

      &.delete {
        .p-menuitem-content {
          .p-menuitem-link {
            .p-menuitem-text {
              color: #ed6762;
            }
          }
        }
      }
    }
  }
}
