.listing_type_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  background: white;
  border-radius: 10px;

  .main_container {
    width: 100%;
  }

  .tile_main {
    width: 100%;
  }

  .tile_content {
    width: 100%;
  }

  .listing_container {
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 18px;

    .listing_desc {
      width: 70%;
    }

    .save_or_launch_btn {
      margin: 0;
    }
  }

  .ownership_container {
    width: 100%;
    padding-top: 16px;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }

    .edit_is_your_listing {
      padding: 0;
      color: #28a9ff;
      font-size: 14px;
      font-weight: 600;
    }

    .is_your_listing_btn {
      padding: 0;
      color: #979797;
      font-size: 14px;
      font-weight: 600;
    }

    .see_campaigns {
      color: #007b94;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .info_section {
    width: 100%;
  }
}
