.policy-container {
  padding: 12px 22px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);

  .policy-link {
    .policy-title {
      color: #2b1f0a;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 700;
      text-decoration: underline;
      text-decoration-color: #2b1f0a;
    }

    color: #f6b433;

    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
  }
}
