.terms_of_use_container {
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 0 3rem;

  @media (max-width: 699px) {
    max-width: 90%;
    padding: 1rem 0 2rem;
  }

  .heading {
    font-size: 26px !important;

    @media (max-width: 699px) {
      font-size: 22px !important;
    }
  }

  .Text {
    display: block !important;
  }

  .title {
    margin: 2.5rem 0 1.2rem;
    font-size: 20px !important;

    @media (max-width: 699px) {
      margin: 1.5rem 0 0.5rem;
      font-size: 16px !important;
    }
  }

  .description {
    line-height: 1.8rem;
    font-size: 20px !important;

    @media (max-width: 699px) {
      line-height: 1rem;
      font-size: 14px !important;
    }
  }

  .img_top {
    top: 0px;
    right: 2px;
    margin-top: 0 !important;
  }
  .img_bottom {
    bottom: 0px;
    left: 2px;
  }

  img {
    position: fixed;
    height: 420px;

    // @media (max-width: 699px) {
    //   display: none;
    // }
  }
}
