@import 'src/tokens';

.DropShadowSettings {
  &__number-input {
    max-width: 2.5rem;
    border: $border-width-default solid $color-grey-400;
    border-radius: $border-radius-s;
    font-size: $text-size-m;
    text-align: center;
  }
}
