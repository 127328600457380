@import 'tokens';

.ListingMap {
  height: 34rem;
  flex-grow: 1;
  // box-shadow: $box-shadow-200;
  margin: 0.5rem;
  &__map-loading {
    height: 20rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-grey-050;
  }
}

.leaflet-marker-icon {
  pointer-events: none;

  @media screen and (min-width: 3200px) {
    width: 90px !important;
    height: 90px !important;
  }
}

.marker-cluster div {
  @media screen and (min-width: 3200px) {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50% !important;
  }
}

.marker-cluster div span {
  @media screen and (min-width: 3200px) {
    font-size: 34px;
    line-height: 80px;
  }
}
