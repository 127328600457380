@import 'tokens';
.tableRow_mylisting {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #757575 !important;
}
.MyListings {
  &__header {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    @media screen and (max-width: 599px) {
      flex-direction: column;
      align-items: center;
    }
    > span {
      margin-top: 0.5rem !important;
    }
  }

  &__totalCounts {
    @media screen and (max-width: 600px) {
      // margin-top: 0.5rem;
      display: flex;
      // flex-direction: column;
      gap: 0.1rem;
      margin-top: 0.5rem;
    }
  }
  &__AdvertButton {
    width: 231px;
    height: 48px;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__pageHeader {
    @media screen and (max-width: 600px) {
      margin: 0 auto;
    }
  }
  &__status-badge {
    padding: 0.25rem 1rem;
    margin: -8px 0;
    border-radius: 5px;
    width: 130px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
  }
  &__status-badge_active {
    background: #f4fff2;
    border: 1px solid #2ad90e;
    color: #2ad90e;
  }
  &__status-badge_pending {
    background: #fff9ec;
    border: 1px solid #ffab03;
    color: #ffab03;
  }
  &__status-badge_closed {
    background: #fff2f2;
    border: 1px solid #ff5e5e;
    color: #ff5e5e;
  }
  &__status-badge_sold {
    background: #ffedfc;
    border: 1px solid #d90eb8;
    color: #d90eb8;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: $color-grey-200;
    padding: 6rem;
  }
}
