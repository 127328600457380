@import 'tokens.scss';
@import 'src/mixins';

.CreativeTypeBadge {
  @include focus-ring;
  border: none;
  cursor: pointer;
  padding: 3rem 0;
  background-color: $color-grey-100;

  &__btn {
    width: 187px;
    height: 50px;
    padding-inline: 30px;
  }

  &__icon {
    color: $text-on-grey-100;
    margin-bottom: 0.5rem;
  }
  &__button {
    background-color: white !important;
    margin-top: 10px;
    border: white !important;

    @media screen and (max-width: 600px) {
      max-width: 180px !important;
    }
  }
  &__boxTextButton {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1ba1c5 !important;
  }
}

.creative-action-popup-container {
  position: relative;
  width: fit-content;

  .select-creative-action-popup.p-menu {
    top: 50% !important;
    left: unset !important;
    right: -210px;
    transform: translateY(-50%);

    width: 193px;
    padding: 0;

    .p-menu-list {
      position: relative;
      padding-inline: 8px;
      padding-block: 10px;

      border-radius: 8px;
      background: #fff;
      box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-75%) rotate(-90deg);
      }

      &::before {
        background: #ffffff;
        content: '';
        left: 0;
        width: 16px;
        height: calc(16px / 2);
        clip-path: path(
          'M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z'
        );
      }

      .p-menuitem {
        &:not(.p-highlight):not(.p-disabled) {
          > .p-menuitem-content:hover {
            background: #fff9ed;
          }

          &.p-focus {
            > .p-menuitem-content {
              background: #ffffff;

              &:hover {
                background: #fff9ed;
              }
            }
          }
        }

        .p-menuitem-content {
          border-radius: 5px;

          .p-menuitem-link {
            gap: 10px;
            padding-inline: 8px;
            padding-block: 7px;

            color: #222124;
            font-variant-numeric: lining-nums tabular-nums;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.07px;
          }
        }
      }
    }
  }
}
