@import 'src/tokens';

.BillingSection {
  margin: 35px 0;
  &__card.Section {
    background: #ffffff;
    padding: 12px 22px;
    border-radius: 20px;
  }
  &__borderline {
    border: 1px solid #f0f0f0;
    width: 100%;
  }
  &__ctn {
    cursor: pointer;
  }
  &__cross {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  &__billing_topbar {
    display: flex;
    align-items: center;
    padding: 0 0.5rem 12px;
    justify-content: space-between;
  }
  &__headingButton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.75;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: white;
    border: none;
    color: #ffab03;

    @media screen and (min-width: 3200px) {
      font-size: 28px;
    }

    > img {
      @media screen and (min-width: 3200px) {
        height: 28px;
      }
    }
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 382px;
    gap: 1.3rem;
  }
  &__AddCardModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 620px;
    height: 554px;
    position: relative;

    @media screen and (min-width: 3200px) {
      width: 1300px;
      height: 950px;
    }
  }
  &__heading {
    color: #2b1f0a;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  &__allCardlist_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
  }
  &__heading_section {
    width: 49%;
  }
  &__heading_section_expiry {
    width: 52%;
  }
  &__heading_text {
    color: #515a5c !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }
  &__heading_text_detail {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    line-height: 1.5 !important;
    color: #515a5c !important ;
    padding: 1.5rem 2rem;

    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }
  &__card_detail_text {
    padding-left: 1rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    display: flex;
    align-items: center;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  &__edit_delete {
    display: flex;
    padding: 0 1rem;
    justify-content: flex-end;
    align-items: center;
    width: 33%;
    gap: 1rem;

    > img {
      height: 22px;

      @media screen and (min-width: 3200px) {
        height: 32px;
      }
    }
  }
  &__detailsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding-left: 1rem;
  }
  &__billing_card {
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: flex-start;

    > img {
      height: 25px;

      @media screen and (min-width: 3200px) {
        height: 35px;
      }
    }
  }
  &__primary {
  }
  &__billingIcon {
    width: 151.89px;
    height: 162.76px;
  }
  &__billingButton {
    width: 203px;
    height: 53px;
  }
  &__title {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #2b1f0a !important;
  }
  &__credit_card {
    width: 249px;
    height: 111px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
    // border: 1px solid #aab2b5;
    box-sizing: content-box;
    border-radius: 9px;
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 400;
    padding: 5px 15px 10px 15px;

    .card_title {
      font-weight: 700;
      font-size: 22px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    table {
      width: 100%;

      tbody {
        margin-top: 5px;

        tr {
          height: 20px;
        }
      }
    }
  }

  .edit_button {
    width: fit-content;
    font-weight: 700;
    margin-top: 8px;
  }
}
