@import 'tokens.scss';
@import 'mixins.scss';

.ListingSearch {
  &__button {
    @include focus-ring;
    width: 100%;
    border-radius: $border-radius-s;
    text-align: start;
    font-size: $text-size-l;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    border: 2px solid #000000;
    background-color: $color-grey-100;
    color: $text-on-grey-100;
    &:hover {
      background-color: $color-grey-200;
      color: $text-on-grey-200;
    }
  }

  .ListingSearch__button {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 10px;

    svg {
      font-size: 18px;
      color: #ffab03;
      @media screen and (min-width: 3200px) {
        font-size: 26px;
      }
    }

    .content {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;

      display: flex;
      align-items: flex-end;

      color: #b9b9b9;
      @media screen and (min-width: 3200px) {
        font-size: 26px;
      }
    }
  }

  &__model-content {
    max-height: calc(100vh - 12rem);
    width: 60rem;
    max-width: calc(100vw - 2rem);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    @media screen and (min-width: 3200px) {
      width: 120rem;
      padding: 2rem;
    }
  }

  &__search {
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: $border-radius-m;
    overflow: hidden;
    border: 1px solid #ffab03;
    align-items: center;
    gap: 0.75rem;
  }
  &__icon {
    margin-inline-start: 0.75rem;
    font-size: $text-size-l;
    color: $text-on-white--subdued;

    @media screen and (min-width: 3200px) {
      font-size: $text-size-xxl;
    }
  }
  &__input {
    width: 100%;
    border: none;
    outline: none;
    height: 2.5rem;
    font-family: 'Inter', sans-serif;
    font-size: $text-size-xl;
    font-weight: $text-weight-light;
    &::placeholder {
      color: $text-on-white--subdued;
    }
    @media screen and (min-width: 3200px) {
      height: 3.5rem;
      font-size: $text-size-xxxl;
    }
  }

  &__suggestion-add-into-listings {
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    background: transparent;
    color: #ffab03;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
  }

  &__suggestion-info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-name {
      color: #000000;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    &-id {
      color: #6d6d6d;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  &__suggestions {
    margin-block-start: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    overflow-y: overlay;
    flex-grow: 0;
    list-style: none;
    padding: 0;
  }

  &__suggestion-button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: start;
    padding: 12px 17px;
    border-radius: $border-radius-s;
    background-color: transparent;
    font-size: $text-size-m;
    font-family: Helvetica;

    &:hover {
      background: #fffbe8;
      color: #ffab03;
    }
    @media screen and (min-width: 3200px) {
      padding-block: 2rem;
      font-size: $text-size-xxl;
    }
  }
}

.Modal__content.LocationSearchModal {
  align-self: flex-start;
  margin-block-start: calc(64px + 4rem);

  @media screen and (min-width: 3200px) {
    margin-block-start: calc(140px + 8rem);
  }
}
