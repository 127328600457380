.orderline-status {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.review {
    background: #ffeadc;
    color: #8f3516;
  }

  &.active {
    background: #e6f7ec;
    color: #5abb82;
  }

  &.approved {
    background: #e3f3f9;
    color: #07548a;
  }

  &.completed {
    background: rgba(111, 107, 174, 0.1);
    color: #6f6bae;
  }

  &.rejected {
    background: #f7e6e6;
    color: #bb5a5a;
  }
}
