.lens-upgrade-modal {
  position: relative;
  width: 773px;
  //height: 300px;
  min-height: 300px;

  background-image: url('../../assets/Images/leftvoting.svg'),
    url('../../assets/Images/rightvoting.svg');
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-size: 30%;

  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: inherit;
  }

  &-content {
    background-image: url('../../assets/Images/likely-sellers-modal-keys.svg');
    background-repeat: no-repeat;
    background-position: 85% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: inherit;

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 250px;

      padding-inline: 60px;
      padding-block: 40px;

      > div {
        margin-block-end: 15px;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .title {
        color: #ffab03;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .description {
        color: #9a9a9a;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }
    }

    &-footer {
      padding-block-end: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
    }
  }
}
