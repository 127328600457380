@import 'tokens.scss';

.ProspectActivityPage {
  height: 100%;
  position: relative;
  display: grid;

  &__splash {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .theMap {
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: $box-shadow-500;
    position: relative;
    &__splash-screen {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
    #leaflet-map {
      z-index: 1;
      height: 100%;
    }
  }
  &__marker-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__marker-mark {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    border: $border-width-default solid $color-white;
    border-radius: 100rem;
    box-shadow: $box-shadow-300-dark;
  }
}
